import {
    AUTHORIZATION_FAIL,
    AD_ADDED,
    AD_FAIL,
    AD_EDITED,
    AD_DELETED,
    SET_AD_DATA,
    SET_AD_HAS_MORE,
    AD_COMMENT_ADDED,
    AD_COMMENT_EDITED,
    AD_COMMENT_DELETED,
    AD_COMMENT_FAIL,
    AD_LIKE_ADDED,
    AD_LIKE_FAIL,
    AD_LIKE_REMOVED,
    SET_AD_DETAILS,
    SET_FETCHING_AD_DETAILS
  } from "./ActionType";
  import axios from "axios";
  
  import config from "../config";
  var apiRoot = config.api.root;
  
  export const getAdData = (page, search, refresh) => {
    let skip = (page - 1) * 6;
    return dispatch => {
      let url = search
        ? apiRoot + "/api/ad" + search + "&limit=6&skip=" + skip
        : apiRoot + "/api/ad?limit=6&skip=" + skip;
      return axios.get(url).then(
        response => {
          if (response.data.length > 0) {
            dispatch(setAdData(response.data, refresh));
          } else {
            dispatch(setAdHasMore(false));
            console.log("No data");
          }
        },
        error => {
          console.log(error);
        }
      );
    };
  };
  
  export const getAdDetails = id => {
    return dispatch => {
      dispatch(setFetchingAdDetails(true));
      return axios.get(apiRoot + "/api/ad/" + id).then(
        response => {
          dispatch(setAdDetails(response.data[0]));
          dispatch(setFetchingAdDetails(false));
        },
        error => {
          console.log(error);
          dispatch(setFetchingAdDetails(false));
        }
      );
    };
  };
  
  export const addAd = data => {
    return dispatch => {
      return axios
        .post(apiRoot + "/api/ad", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + localStorage.getItem("authToken")
          }
        })
        .then(
          response => {
            if (response.data) {
              dispatch(adAdded(response.data));
            } else {
              dispatch(adFail());
            }
          },
          error => {
            dispatch(authorizationFail());
          }
        );
    };
  };
  
  export const editAd = (data, id) => {
    return dispatch => {
      return axios
        .put(apiRoot + "/api/ad/" + id, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + localStorage.getItem("authToken")
          }
        })
        .then(
          response => {
            if (response.data) {
              dispatch(adEdited(response.data));
            } else {
              dispatch(adFail());
            }
          },
          error => {
            dispatch(authorizationFail());
          }
        );
    };
  };
  
  export const deleteAd = id => {
    return dispatch => {
      return axios
        .delete(apiRoot + "/api/ad/" + id, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + localStorage.getItem("authToken")
          }
        })
        .then(
          response => {
            if (response.status == 202) {
              dispatch(adDeleted(id));
            } else {
              console.log("fail");
              dispatch(adFail());
            }
          },
          error => {
            dispatch(authorizationFail());
          }
        );
    };
  };
  
  export const addAdComment = data => {
    return dispatch => {
      return axios
        .post(
          apiRoot + "/api/ad/addComment/" + data.id,
          { comment: data.comment },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: data.authToken
            }
          }
        )
        .then(
          response => {
            if (response.data) {
              dispatch(commentAdded(response.data));
            } else {
              dispatch(commentFail());
            }
          },
          error => {
            dispatch(commentFail());
          }
        );
    };
  };
  
  export const editAdComment = data => {
    return dispatch => {
      return axios
        .put(
          apiRoot + "/api/ad/editComment/" + data.id + "/" + data.commentId,
          { comment: data.comment },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: data.authToken
            }
          }
        )
        .then(
          response => {
            if (response.data) {
              dispatch(commentEdited(response.data));
            } else {
              dispatch(commentFail());
            }
          },
          error => {
            dispatch(commentFail());
          }
        );
    };
  };
  
  export const deleteAdComment = data => {
    return dispatch => {
      return axios
        .delete(
          apiRoot + "/api/ad/deleteComment/" + data.id + "/" + data.commentId,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: data.authToken
            }
          }
        )
        .then(
          response => {
            if (response.data) {
              dispatch(commentDeleted(response.data));
            } else {
              dispatch(commentFail());
            }
          },
          error => {
            dispatch(commentFail());
          }
        );
    };
  };
  
  export const addAdLike = data => {
    return dispatch => {
      return axios
        .post(
          apiRoot + "/api/ad/addLike/" + data.id,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: data.authToken
            }
          }
        )
        .then(
          response => {
            if (response.data.like) {
              dispatch(likeAdded(response.data));
            } else {
              dispatch(likeRemoved(response.data));
            }
          },
          error => {
            dispatch(likeFail());
          }
        );
    };
  };
  
  export const setAdData = (data, refresh) => {
    return {
      type: SET_AD_DATA,
      data,
      refresh
    };
  };
  
  export const setAdHasMore = data => {
    return {
      type: SET_AD_HAS_MORE,
      data
    };
  };
  
  export const authorizationFail = () => {
    return {
      type: AUTHORIZATION_FAIL
    };
  };
  
  export const adAdded = data => {
    return {
      type: AD_ADDED,
      data
    };
  };
  
  export const adEdited = data => {
    return {
      type: AD_EDITED,
      data
    };
  };
  
  export const adDeleted = data => {
    return {
      type: AD_DELETED,
      data
    };
  };
  
  export const adFail = () => {
    return {
      type: AD_FAIL
    };
  };
  
  export const commentAdded = data => {
    return {
      type: AD_COMMENT_ADDED,
      data
    };
  };
  
  export const commentEdited = data => {
    return {
      type: AD_COMMENT_EDITED,
      data
    };
  };
  
  export const commentDeleted = data => {
    return {
      type: AD_COMMENT_DELETED,
      data
    };
  };
  
  export const commentFail = () => {
    return {
      type: AD_COMMENT_FAIL
    };
  };
  
  export const likeAdded = data => {
    return {
      type: AD_LIKE_ADDED,
      data
    };
  };
  
  export const likeRemoved = data => {
    return {
      type: AD_LIKE_REMOVED,
      data
    };
  };
  
  export const likeFail = () => {
    return {
      type: AD_LIKE_FAIL
    };
  };
  
  export const setFetchingAdDetails = data => {
    return {
      type: SET_FETCHING_AD_DETAILS,
      data
    };
  };
  
  export const setAdDetails = data => {
    return {
      type: SET_AD_DETAILS,
      data
    };
  };
  