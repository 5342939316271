import {
	AUTHORIZATION_FAIL,
	PROJECT_ADDED,
	PROJECT_FAIL,
	PROJECT_EDITED,
	PROJECT_DELETED,
	SET_PROJECT_DATA,
	SET_PROJECT_HAS_MORE,
	PROJECT_COMMENT_ADDED,
	PROJECT_COMMENT_EDITED,
	PROJECT_COMMENT_DELETED,
	PROJECT_COMMENT_FAIL,
	PROJECT_LIKE_ADDED,
	PROJECT_LIKE_FAIL,
	PROJECT_LIKE_REMOVED
} from './ActionType'
import axios from 'axios'
import { compressFile } from '../utils/Helper'

import config from '../config'
var apiRoot = config.api.root

export const getProjectData = (page, search, refresh) => {
	let skip = ((page - 1) * 6)
	return (dispatch) => {

		let url = search ? apiRoot + '/api/project' + search + '&limit=6&skip=' + skip : apiRoot + '/api/project?limit=6&skip=' + skip
		return axios.get(url).then(
			response => {
				if (response.data.length > 0) {
					dispatch(setProjectData(response.data, refresh))
				} else {
					dispatch(setProjectHasMore(false))
					console.log("No data")
				}
			},
			error => {
				console.log(error)
			}
		)
	}
}


export const addProject = (data) => {
	return (dispatch) => {
		let urls = []
		let images = [data.imageUrls.filter(imageUrl => {
			let isFile = imageUrl instanceof File
			if (!isFile) {
				urls.push(imageUrl)
			}
			return isFile
		})]
		data.imageUrls = urls
		if (data.steps) {
			images = images.concat(data.steps.map((step, index) => {
				urls = []
				let imageUrls = step.imageUrls.filter(imageUrl => {
					let isFile = imageUrl instanceof File
					if (!isFile) {
						urls.push(imageUrl)
					}
					return isFile
				})
				data.steps[index]['imageUrls'] = urls
				return imageUrls
			}))
		}
		console.log('images', images)
		return Promise.all(images.map((value, arrayIndex1) => {
			return Promise.all(compressFile(value)).then((files) => {
				return Promise.all(files.map((file, arrayIndex2) => {
					const formData = new FormData();
					formData.append('file', file);
					return axios.post(apiRoot + `/api/project/upload/${arrayIndex1}-${arrayIndex2}`, formData, { headers: { "Content-Type": "application/json", "Authorization": "Basic " + localStorage.getItem("authToken") } })
				}))
			})
		})).then(response => {
			console.log('response', response)
			// data.imageUrls = []
			// data.steps = data.steps.map(step => {
			// 	step.imageUrls = []
			// 	return step
			// })
			console.log('data', data)
			response.map(results => {
				results.map(result => {
					let ids = result.data.id.split('-')
					if (ids[0] == "0") {
						data.imageUrls = [...data.imageUrls, result.data.imageurl]
					} else {
						data.steps[ids[0] - 1].imageUrls = [...data.steps[ids[0] - 1].imageUrls, result.data.imageurl]
					}
				})
			})
			data.imageUrl = data.imageUrls.length > 0 ? data.imageUrls[0] : null
			data.videoUrl = data.videoUrls.length > 0 ? data.videoUrls[0] : null
			console.log('data', data)
			return postProject(data, dispatch)
		})
			.catch((error) => {
				console.log(error)
				// postProject(data, dispatch)
				// document.getElementById("outlined-button-file").value = ""
			})
	}
}

export const postProject = (data, dispatch) => {
	return axios[data.id?'put':'post'](apiRoot + `/api/project${data.id?'/'+data.id:''}`, data, { headers: { "Content-Type": "application/json", "Authorization": "Basic " + localStorage.getItem("authToken") } }).then(
		response => {
			if (response.data) {
				dispatch(data.id?projectEdited(response.data):projectAdded(response.data))
				data.history.push("/project")
			} else {
				dispatch(projectFail())
			}
		},
		error => {
			console.log('error', error.response)
			if (error.response.status == 500) {
				dispatch(projectFail())
			} else {
				dispatch(authorizationFail())
			}

		}
	)
}

export const editProject = (data, id) => {
	return (dispatch) => {
		return axios.put(apiRoot + '/api/project/' + id, data, { headers: { "Content-Type": "application/json", "Authorization": "Basic " + localStorage.getItem("authToken") } }).then(
			response => {
				if (response.data) {
					dispatch(projectEdited(response.data))
				} else {
					dispatch(projectFail())
				}
			},
			error => {
				dispatch(authorizationFail())
			}
		)
	}
}

export const deleteProject = (id) => {
	return (dispatch) => {
		return axios.delete(apiRoot + '/api/project/' + id, { headers: { "Content-Type": "application/json", "Authorization": "Basic " + localStorage.getItem("authToken") } }).then(
			response => {
				if (response.status == 202) {
					dispatch(projectDeleted(id))
				} else {
					console.log("fail")
					dispatch(projectFail())
				}
			},
			error => {
				dispatch(authorizationFail())
			}
		)
	}
}

export const addProjectComment = (data) => {
	return (dispatch) => {
		return axios.post(apiRoot + '/api/project/addComment/' + data.id, { comment: data.comment }, { headers: { "Content-Type": "application/json", "Authorization": data.authToken } }).then(
			response => {
				if (response.data) {
					dispatch(commentAdded(response.data))
				} else {
					dispatch(commentFail())
				}
			},
			error => {
				dispatch(commentFail())
			}
		)
	}
}

export const editProjectComment = (data) => {
	return (dispatch) => {
		return axios.put(apiRoot + '/api/project/editComment/' + data.id + '/' + data.commentId, { comment: data.comment }, { headers: { "Content-Type": "application/json", "Authorization": data.authToken } }).then(
			response => {
				if (response.data) {
					dispatch(commentEdited(response.data))
				} else {
					dispatch(commentFail())
				}
			},
			error => {
				dispatch(commentFail())
			}
		)
	}
}

export const deleteProjectComment = (data) => {
	return (dispatch) => {
		return axios.delete(apiRoot + '/api/project/deleteComment/' + data.id + '/' + data.commentId, { headers: { "Content-Type": "application/json", "Authorization": data.authToken } }).then(
			response => {
				if (response.data) {
					dispatch(commentDeleted(response.data))
				} else {
					dispatch(commentFail())
				}
			},
			error => {
				dispatch(commentFail())
			}
		)
	}
}

export const addProjectLike = (data) => {
	return (dispatch) => {
		return axios.post(apiRoot + '/api/project/addLike/' + data.id, {}, { headers: { "Content-Type": "application/json", "Authorization": data.authToken } }).then(
			response => {
				if (response.data.like) {
					dispatch(likeAdded(response.data))
				} else {
					dispatch(likeRemoved(response.data))
				}
			},
			error => {
				dispatch(likeFail())
			}
		)
	}
}

export const setProjectData = (data, refresh) => {
	return {
		type: SET_PROJECT_DATA, data, refresh
	}
}

export const setProjectHasMore = (data) => {
	return {
		type: SET_PROJECT_HAS_MORE, data
	}
}

export const authorizationFail = () => {
	return {
		type: AUTHORIZATION_FAIL
	}
}

export const projectAdded = (data) => {
	return {
		type: PROJECT_ADDED, data
	}
}

export const projectEdited = (data) => {
	return {
		type: PROJECT_EDITED, data
	}
}

export const projectDeleted = (data) => {
	return {
		type: PROJECT_DELETED, data
	}
}

export const projectFail = () => {
	return {
		type: PROJECT_FAIL
	}
}

export const commentAdded = (data) => {
	return {
		type: PROJECT_COMMENT_ADDED, data
	}
}

export const commentEdited = (data) => {
	return {
		type: PROJECT_COMMENT_EDITED, data
	}
}

export const commentDeleted = (data) => {
	return {
		type: PROJECT_COMMENT_DELETED, data
	}
}

export const commentFail = () => {
	return {
		type: PROJECT_COMMENT_FAIL
	}
}

export const likeAdded = (data) => {
	return {
		type: PROJECT_LIKE_ADDED, data
	}
}

export const likeRemoved = (data) => {
	return {
		type: PROJECT_LIKE_REMOVED, data
	}
}

export const likeFail = () => {
	return {
		type: PROJECT_LIKE_FAIL
	}
}
