import {
	SET_PROJECT_DETAILS,
	SET_FETCHING_PROJECT_DETAILS
} from "./ActionType";
import axios from "axios";

import config from "../config";
var apiRoot = config.api.root;

export const getProjectDetails = id => {
	return dispatch => {
		dispatch(setFetchingProjectDetails(true));
		return axios.get(apiRoot + "/api/project/" + id).then(
			response => {
				dispatch(setProjectDetails(response.data[0]));
				dispatch(setFetchingProjectDetails(false));
			},
			error => {
				console.log(error);
				dispatch(setFetchingProjectDetails(false));
			}
		);
	};
};

export const setProjectDetails = data => {
	return {
		type: SET_PROJECT_DETAILS,
		data
	};
};
export const setFetchingProjectDetails = data => {
	return {
		type: SET_FETCHING_PROJECT_DETAILS,
		data
	};
};
