import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import LightboxGallery from 'react-images';
import ContactUs from "../components/ContactUs";
import moment1 from "../images/moment1.jpg";
import moment2 from "../images/moment2.jpg";
import moment3 from "../images/moment3.jpg";
import moment4 from "../images/moment4.jpg";
import moment5 from "../images/moment5.jpg";
import moment6 from "../images/moment6.jpg";
import moment7 from "../images/moment7.jpg";
import moment8 from "../images/moment8.jpg";
import moment9 from "../images/moment9.jpg";
import moment10 from "../images/moment10.jpg";
// import moment11 from '../images/moment11.jpg'
// // import moment12 from '../images/moment12.jpg'
import moment13 from "../images/moment13.jpg";
import moment14 from "../images/moment14.jpg";
import moment15 from "../images/moment15.jpg";
import moment16 from "../images/moment16.jpg";
import moment17 from "../images/moment17.jpg";
import moment18 from "../images/moment18.jpg";
import moment19 from "../images/moment19.jpg";
import moment20 from "../images/moment20.jpg";
import moment21 from "../images/moment21.jpg";
import moment22 from "../images/moment22.jpg";
import moment23 from "../images/moment23.jpg";
import moment24 from "../images/moment24.jpg";
import moment25 from "../images/moment25.jpg";
import moment26 from "../images/moment26.jpg";

import min_moment1 from "../images/min/moment1.jpg";
import min_moment2 from "../images/min/moment2.jpg";
import min_moment3 from "../images/min/moment3.jpg";
import min_moment4 from "../images/min/moment4.jpg";
import min_moment5 from "../images/min/moment5.jpg";
import min_moment6 from "../images/min/moment6.jpg";
import min_moment7 from "../images/min/moment7.jpg";
import min_moment8 from "../images/min/moment8.jpg";
import min_moment9 from "../images/min/moment9.jpg";
import min_moment10 from "../images/min/moment10.jpg";
// import min_moment11 from '../images/min/moment11.jpg'
// // import min_moment12 from '../images/min/moment12.jpg'
import min_moment13 from "../images/min/moment13.jpg";
import min_moment14 from "../images/min/moment14.jpg";
import min_moment15 from "../images/min/moment15.jpg";
import min_moment16 from "../images/min/moment16.jpg";
import min_moment17 from "../images/min/moment17.jpg";
import min_moment18 from "../images/min/moment18.jpg";
import min_moment19 from "../images/min/moment19.jpg";
import min_moment20 from "../images/min/moment20.jpg";
import min_moment21 from "../images/min/moment21.jpg";
import min_moment22 from "../images/min/moment22.jpg";
import min_moment23 from "../images/min/moment23.jpg";
import min_moment24 from "../images/min/moment24.jpg";
import min_moment25 from "../images/min/moment25.jpg";
import min_moment26 from "../images/min/moment26.jpg";
import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx"

const styles = () => ({
  gridList: {
    width: 500,
    height: 450,
  },
  pageSlideAnimation: {
    visibility: "hidden",
    visibility: "visible9" /*For old IE browsers IE6-8 */,
    "&::after": {
      content: "",
      display: "table",
      clear: "both"
    }
  },
  pageSlide: {
    visibility: "visible",
    animation: "pageSlide 1s"
  },
  "@keyframes pageSlide": {
    "0%": {
      opacity: "0",
      transform: "translateY(150px)"
    },
    "100%": {
      opacity: "1",
      transform: "translateY(0px)"
    }
  }
})

class EventGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedImg: null,
      showContactUsModel: false
    }
  }

  showContactUsModel = () => {
    this.setState({ showContactUsModel: true });
  };

  closeModel = () => {
    this.setState({
      showContactUsModel: false
    });
  };

  render() {
    const { classes } = this.props;
    let momentImages = [
      { title: "Workshop In IIT", thumbnail: min_moment1, img: moment1 },
      { title: "Workshop In MIT", thumbnail: min_moment19, img: moment19 },
      { title: "Workshop In BIT", thumbnail: min_moment21, img: moment21 },
      { title: "Dev Expo KIIT", thumbnail: min_moment16, img: moment16 },
      { title: "RedBull Entrepreneurship Workshop", thumbnail: min_moment23, img: moment23 },
      { title: "Google I/O Extended", thumbnail: min_moment26, img: moment26 },
      { title: "Tech Conference", thumbnail: min_moment2, img: moment2 },
      { title: "Atlassian Workshop", thumbnail: min_moment14, img: moment14 },
      { title: "Python Workshop In BCET", thumbnail: min_moment20, img: moment20 },
      { title: "Web Development Workshop", thumbnail: min_moment22, img: moment22 },
      { title: "Robotics Workshop", thumbnail: min_moment24, img: moment24 },
      { title: "AI/ML Workshop", thumbnail: min_moment13, img: moment13 },
      { title: "Utkal Hackathon", thumbnail: min_moment15, img: moment15 },
      { title: "AI/ML Workshop", thumbnail: min_moment25, img: moment25 },
      { title: "Techno India Workshop", thumbnail: min_moment18, img: moment18 },
      { title: "Azure Bootcamp", thumbnail: min_moment17, img: moment17 },
      { title: "School Workshop", thumbnail: min_moment3, img: moment3 },
      { title: "JNTUK Workshop", thumbnail: min_moment5, img: moment5 },
      { title: "Summer Training", thumbnail: min_moment6, img: moment6 },
      { title: "Winter Training", thumbnail: min_moment7, img: moment7 },
      { title: "Workshop", thumbnail: min_moment8, img: moment8 },
      { title: "Research Work", thumbnail: min_moment9, img: moment9 },
      { title: "Quadcopter Workshop", thumbnail: min_moment10, img: moment10 },
      { title: "Scout Camp", thumbnail: min_moment4, img: moment4 }
    ];
    let momentImage = [];
    let images = []
    for (let i in momentImages) {
      momentImage.push(
        <Grid key={'momentImage' + i} item xs={6} sm={4} md={3}>
          <Card style={{ margin: '10px' }} onClick={e => { this.setState({ selectedImg: Number(i) + 1 }) }} id={i}>
            <img
              src={momentImages[i].thumbnail}
              width="100%"
              alt={momentImages[i].title}
            />
            <Typography
              style={{ padding: '15px', fontSize: '16px', fontWeight: 'bold' }}
              align="center"
              variant="h5"
              noWrap
            >
              {momentImages[i].title}
            </Typography>
          </Card>
        </Grid>
      );
      images.push({
        id: i,
        src: momentImages[i].img,
        thumbnail: momentImages[i].thumbnail,
        thumbnailWidth: 320,
        thumbnailHeight: 320,
      })
    }
    return (
      <div>
        <Typography align="center" color="inherit" variant="h5">
          SOME OF OUR PAST MOMENTS
        </Typography>
        <Grid
          className={clsx(classes.pageSlideAnimation, classes.pageSlide)}
          style={{ margin: 'auto', marginTop: '15px' }}
          justify="center"
          container
          spacing={0}
        >
          {momentImage}
        </Grid>
        <LightboxGallery
          onClickPrev={e => { this.setState({ selectedImg: this.state.selectedImg - 1 }) }}
          onClickNext={e => { this.setState({ selectedImg: this.state.selectedImg + 1 }) }}
          currentImage={this.state.selectedImg - 1}
          isOpen={this.state.selectedImg ? true : false}
          onClose={e => { this.setState({ selectedImg: null }) }}
          images={images}
          showThumbnails={true}
          onClickThumbnail={selectedImg => { this.setState({ selectedImg: selectedImg + 1 }) }}
        />
        <center style={{ marginBottom: '30px', marginTop: '20px' }}
          onClick={this.showContactUsModel}>
          <Button variant="contained" size="large" color="primary" style={{ paddingTop: 11, paddingBottom: 11 }} >
            Conduct Events
          </Button>
        </center>
        <ContactUs
          open={this.state.showContactUsModel}
          onClose={this.closeModel}
        />
      </div>
    );
  }
}

export default (withStyles(styles)(EventGallery));
