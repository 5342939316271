import {
	SET_BLOG_DETAILS,
	SET_FETCHING_BLOG_DETAILS
} from "./ActionType";
import axios from "axios";

import config from "../config";
var apiRoot = config.api.root;

export const getBlogDetails = id => {
	return dispatch => {
		dispatch(setFetchingBlogDetails(true));
		return axios.get(apiRoot + "/api/blog/" + id).then(
			response => {
				dispatch(setBlogDetails(response.data[0]));
				dispatch(setFetchingBlogDetails(false));
			},
			error => {
				console.log(error);
				dispatch(setFetchingBlogDetails(false));
			}
		);
	};
};

export const setBlogDetails = data => {
	return {
		type: SET_BLOG_DETAILS,
		data
	};
};
export const setFetchingBlogDetails = data => {
	return {
		type: SET_FETCHING_BLOG_DETAILS,
		data
	};
};
