import {
	AUTHORIZATION_FAIL,
	BLOG_ADDED,
	BLOG_FAIL,
	BLOG_EDITED,
	BLOG_DELETED,
	SET_BLOG_DATA,
	SET_BLOG_HAS_MORE,
	BLOG_COMMENT_ADDED,
	BLOG_COMMENT_EDITED,
	BLOG_COMMENT_DELETED,
	BLOG_COMMENT_FAIL,
	BLOG_LIKE_ADDED,
	BLOG_LIKE_FAIL,
	BLOG_LIKE_REMOVED
} from './ActionType'
import axios from 'axios'
import { compressFile } from '../utils/Helper'

import config from '../config'
var apiRoot = config.api.root

export const getBlogData = (page, search, refresh) => {
	let skip = ((page - 1) * 6)
	return (dispatch) => {

		let url = search ? apiRoot + '/api/blog' + search + '&limit=6&skip=' + skip : apiRoot + '/api/blog?limit=6&skip=' + skip
		return axios.get(url).then(
			response => {
				if (response.data.length > 0) {
					dispatch(setBlogData(response.data, refresh))
				} else {
					dispatch(setBlogHasMore(false))
					console.log("No data")
				}
			},
			error => {
				console.log(error)
			}
		)
	}
}


export const addBlog = (data) => {
	return (dispatch) => {
		let urls = []
		let images = [data.imageUrls.filter(imageUrl => {
			let isFile = imageUrl instanceof File
			if (!isFile) {
				urls.push(imageUrl)
			}
			return isFile
		})]
		data.imageUrls = urls
		if (data.steps) {
			images = images.concat(data.steps.map((step, index) => {
				urls = []
				let imageUrls = step.imageUrls.filter(imageUrl => {
					let isFile = imageUrl instanceof File
					if (!isFile) {
						urls.push(imageUrl)
					}
					return isFile
				})
				data.steps[index]['imageUrls'] = urls
				return imageUrls
			}))
		}
		console.log('images', images)
		return Promise.all(images.map((value, arrayIndex1) => {
			return Promise.all(compressFile(value)).then((files) => {
				return Promise.all(files.map((file, arrayIndex2) => {
					const formData = new FormData();
					formData.append('file', file);
					return axios.post(apiRoot + `/api/blog/upload/${arrayIndex1}-${arrayIndex2}`, formData, { headers: { "Content-Type": "application/json", "Authorization": "Basic " + localStorage.getItem("authToken") } })
				}))
			})
		})).then(response => {
			console.log('response', response)
			// data.imageUrls = []
			// data.steps = data.steps.map(step => {
			// 	step.imageUrls = []
			// 	return step
			// })
			console.log('data', data)
			response.map(results => {
				results.map(result => {
					let ids = result.data.id.split('-')
					if (ids[0] == "0") {
						data.imageUrls = [...data.imageUrls, result.data.imageurl]
					} else {
						data.steps[ids[0] - 1].imageUrls = [...data.steps[ids[0] - 1].imageUrls, result.data.imageurl]
					}
				})
			})
			data.imageUrl = data.imageUrls.length > 0 ? data.imageUrls[0] : null
			data.videoUrl = data.videoUrls.length > 0 ? data.videoUrls[0] : null
			console.log('data', data)
			return postBlog(data, dispatch)
		})
			.catch((error) => {
				console.log(error)
				// postBlog(data, dispatch)
				// document.getElementById("outlined-button-file").value = ""
			})
	}
}

export const postBlog = (data, dispatch) => {
	return axios[data.id?'put':'post'](apiRoot + `/api/blog${data.id?'/'+data.id:''}`, data, { headers: { "Content-Type": "application/json", "Authorization": "Basic " + localStorage.getItem("authToken") } }).then(
		response => {
			if (response.data) {
				dispatch(data.id?blogEdited(response.data):blogAdded(response.data))
				data.history.push("/blog")
			} else {
				dispatch(blogFail())
			}
		},
		error => {
			console.log('error', error.response)
			if (error.response.status == 500) {
				dispatch(blogFail())
			} else {
				dispatch(authorizationFail())
			}

		}
	)
}

export const editBlog = (data, id) => {
	return (dispatch) => {
		return axios.put(apiRoot + '/api/blog/' + id, data, { headers: { "Content-Type": "application/json", "Authorization": "Basic " + localStorage.getItem("authToken") } }).then(
			response => {
				if (response.data) {
					dispatch(blogEdited(response.data))
				} else {
					dispatch(blogFail())
				}
			},
			error => {
				dispatch(authorizationFail())
			}
		)
	}
}

export const deleteBlog = (id) => {
	return (dispatch) => {
		return axios.delete(apiRoot + '/api/blog/' + id, { headers: { "Content-Type": "application/json", "Authorization": "Basic " + localStorage.getItem("authToken") } }).then(
			response => {
				if (response.status == 202) {
					dispatch(blogDeleted(id))
				} else {
					console.log("fail")
					dispatch(blogFail())
				}
			},
			error => {
				dispatch(authorizationFail())
			}
		)
	}
}

export const addBlogComment = (data) => {
	return (dispatch) => {
		return axios.post(apiRoot + '/api/blog/addComment/' + data.id, { comment: data.comment }, { headers: { "Content-Type": "application/json", "Authorization": data.authToken } }).then(
			response => {
				if (response.data) {
					dispatch(commentAdded(response.data))
				} else {
					dispatch(commentFail())
				}
			},
			error => {
				dispatch(commentFail())
			}
		)
	}
}

export const editBlogComment = (data) => {
	return (dispatch) => {
		return axios.put(apiRoot + '/api/blog/editComment/' + data.id + '/' + data.commentId, { comment: data.comment }, { headers: { "Content-Type": "application/json", "Authorization": data.authToken } }).then(
			response => {
				if (response.data) {
					dispatch(commentEdited(response.data))
				} else {
					dispatch(commentFail())
				}
			},
			error => {
				dispatch(commentFail())
			}
		)
	}
}

export const deleteBlogComment = (data) => {
	return (dispatch) => {
		return axios.delete(apiRoot + '/api/blog/deleteComment/' + data.id + '/' + data.commentId, { headers: { "Content-Type": "application/json", "Authorization": data.authToken } }).then(
			response => {
				if (response.data) {
					dispatch(commentDeleted(response.data))
				} else {
					dispatch(commentFail())
				}
			},
			error => {
				dispatch(commentFail())
			}
		)
	}
}

export const addBlogLike = (data) => {
	return (dispatch) => {
		return axios.post(apiRoot + '/api/blog/addLike/' + data.id, {}, { headers: { "Content-Type": "application/json", "Authorization": data.authToken } }).then(
			response => {
				if (response.data.like) {
					dispatch(likeAdded(response.data))
				} else {
					dispatch(likeRemoved(response.data))
				}
			},
			error => {
				dispatch(likeFail())
			}
		)
	}
}

export const setBlogData = (data, refresh) => {
	return {
		type: SET_BLOG_DATA, data, refresh
	}
}

export const setBlogHasMore = (data) => {
	return {
		type: SET_BLOG_HAS_MORE, data
	}
}

export const authorizationFail = () => {
	return {
		type: AUTHORIZATION_FAIL
	}
}

export const blogAdded = (data) => {
	return {
		type: BLOG_ADDED, data
	}
}

export const blogEdited = (data) => {
	return {
		type: BLOG_EDITED, data
	}
}

export const blogDeleted = (data) => {
	return {
		type: BLOG_DELETED, data
	}
}

export const blogFail = () => {
	return {
		type: BLOG_FAIL
	}
}

export const commentAdded = (data) => {
	return {
		type: BLOG_COMMENT_ADDED, data
	}
}

export const commentEdited = (data) => {
	return {
		type: BLOG_COMMENT_EDITED, data
	}
}

export const commentDeleted = (data) => {
	return {
		type: BLOG_COMMENT_DELETED, data
	}
}

export const commentFail = () => {
	return {
		type: BLOG_COMMENT_FAIL
	}
}

export const likeAdded = (data) => {
	return {
		type: BLOG_LIKE_ADDED, data
	}
}

export const likeRemoved = (data) => {
	return {
		type: BLOG_LIKE_REMOVED, data
	}
}

export const likeFail = () => {
	return {
		type: BLOG_LIKE_FAIL
	}
}
