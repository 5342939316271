import {
  SET_AFFILIATE_DATA,
  SET_AFFILIATE_HAS_MORE,
  AFFILIATE_ADDED,
  AFFILIATE_FAIL,
  AFFILIATE_EDITED,
  AFFILIATE_DELETED,
  AFFILIATE_COMMENT_ADDED,
  AFFILIATE_COMMENT_EDITED,
  AFFILIATE_COMMENT_DELETED,
  AFFILIATE_COMMENT_FAIL,
  AFFILIATE_LIKE_ADDED,
  AFFILIATE_LIKE_FAIL,
  AFFILIATE_LIKE_REMOVED,
  SET_AFFILIATE_DETAILS,
  SET_FETCHING_AFFILIATE_DETAILS
} from "./ActionType";
import axios from "axios";

import config from "../config";
var apiRoot = config.api.root;

export const getAffiliateData = (page, search, refresh) => {
  let skip = (page - 1) * 6;
  return dispatch => {
    let url = search
      ? apiRoot + "/api/affiliate" + search + "&limit=6&skip=" + skip
      : apiRoot + "/api/affiliate?limit=6&skip=" + skip;
    return axios.get(url).then(
      response => {
        if (response.data.length > 0) {
          dispatch(setAffiliateData(response.data, refresh));
        } else {
          dispatch(setAffiliateHasMore(false));
          console.log("No data");
        }
      },
      error => {
        console.log(error);
      }
    );
  };
};

export const getAffiliateDetails = id => {
  return dispatch => {
    dispatch(setFetchingAffiliateDetails(true));
    return axios.get(apiRoot + "/api/Affiliate/" + id).then(
      response => {
        dispatch(setAffiliateDetails(response.data[0]));
        dispatch(setFetchingAffiliateDetails(false));
      },
      error => {
        console.log(error);
        dispatch(setFetchingAffiliateDetails(false));
      }
    );
  };
};

export const addAffiliate = data => {
  return dispatch => {
    return axios
      .post(apiRoot + "/api/affiliate", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + localStorage.getItem("authToken")
        }
      })
      .then(
        response => {
          if (response.data) {
            dispatch(affiliateAdded(response.data));
          } else {
            dispatch(affiliateFail());
          }
        },
        error => {
          dispatch(affiliateFail());
        }
      );
  };
};

export const editAffiliate = (data, id) => {
  return dispatch => {
    return axios
      .put(apiRoot + "/api/affiliate/" + id, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + localStorage.getItem("authToken")
        }
      })
      .then(
        response => {
          if (response.data) {
            dispatch(affiliateEdited(response.data));
          } else {
            dispatch(affiliateFail());
          }
        },
        error => {
          dispatch(affiliateFail());
        }
      );
  };
};

export const deleteAffiliate = id => {
  return dispatch => {
    return axios
      .delete(apiRoot + "/api/affiliate/" + id, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + localStorage.getItem("authToken")
        }
      })
      .then(
        response => {
          console.log("response", response);
          if (response.status == 202) {
            dispatch(affiliateDeleted(id));
          } else {
            dispatch(affiliateFail());
          }
        },
        error => {
          dispatch(affiliateFail());
        }
      );
  };
};

export const addAffiliateComment = data => {
  return dispatch => {
    return axios
      .post(
        apiRoot + "/api/affiliate/addComment/" + data.id,
        { comment: data.comment },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: data.authToken
          }
        }
      )
      .then(
        response => {
          if (response.data) {
            dispatch(commentAdded(response.data));
          } else {
            dispatch(commentFail());
          }
        },
        error => {
          dispatch(commentFail());
        }
      );
  };
};

export const editAffiliateComment = data => {
  return dispatch => {
    return axios
      .put(
        apiRoot +
          "/api/affiliate/editComment/" +
          data.id +
          "/" +
          data.commentId,
        { comment: data.comment },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: data.authToken
          }
        }
      )
      .then(
        response => {
          if (response.data) {
            dispatch(commentEdited(response.data));
          } else {
            dispatch(commentFail());
          }
        },
        error => {
          dispatch(commentFail());
        }
      );
  };
};

export const deleteAffiliateComment = data => {
  return dispatch => {
    return axios
      .delete(
        apiRoot +
          "/api/affiliate/deleteComment/" +
          data.id +
          "/" +
          data.commentId,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: data.authToken
          }
        }
      )
      .then(
        response => {
          if (response.data) {
            dispatch(commentDeleted(response.data));
          } else {
            dispatch(commentFail());
          }
        },
        error => {
          dispatch(commentFail());
        }
      );
  };
};

export const addAffiliateLike = data => {
  return dispatch => {
    return axios
      .post(
        apiRoot + "/api/affiliate/addLike/" + data.id,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: data.authToken
          }
        }
      )
      .then(
        response => {
          // console.log(response.data)
          if (response.data.like) {
            dispatch(likeAdded(response.data));
          } else {
            dispatch(likeRemoved(response.data));
          }
        },
        error => {
          dispatch(likeFail());
        }
      );
  };
};

export const setAffiliateData = (data, refresh) => {
  return {
    type: SET_AFFILIATE_DATA,
    data,
    refresh
  };
};

export const setAffiliateHasMore = data => {
  return {
    type: SET_AFFILIATE_HAS_MORE,
    data
  };
};

export const affiliateAdded = data => {
  return {
    type: AFFILIATE_ADDED,
    data
  };
};

export const affiliateFail = () => {
  return {
    type: AFFILIATE_FAIL
  };
};

export const affiliateEdited = data => {
  return {
    type: AFFILIATE_EDITED,
    data
  };
};

export const affiliateDeleted = data => {
  return {
    type: AFFILIATE_DELETED,
    data
  };
};

export const commentAdded = data => {
  return {
    type: AFFILIATE_COMMENT_ADDED,
    data
  };
};

export const commentEdited = data => {
  return {
    type: AFFILIATE_COMMENT_EDITED,
    data
  };
};

export const commentDeleted = data => {
  return {
    type: AFFILIATE_COMMENT_DELETED,
    data
  };
};

export const commentFail = () => {
  return {
    type: AFFILIATE_COMMENT_FAIL
  };
};

export const likeAdded = data => {
  return {
    type: AFFILIATE_LIKE_ADDED,
    data
  };
};

export const likeRemoved = data => {
  return {
    type: AFFILIATE_LIKE_REMOVED,
    data
  };
};

export const likeFail = () => {
  return {
    type: AFFILIATE_LIKE_FAIL
  };
};

export const setFetchingAffiliateDetails = data => {
  return {
    type: SET_FETCHING_AFFILIATE_DETAILS,
    data
  };
};

export const setAffiliateDetails = data => {
  return {
    type: SET_AFFILIATE_DETAILS,
    data
  };
};
