import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import StackGrid from "react-stack-grid";
import { connect } from "react-redux";
import { getInternshipData } from "../actions/Internship";
import { getParameterByName } from "../utils/Helper";
import InfiniteScroll from "../components/InfiniteScroll";
import Login from "../components/Login";
import InternshipCardView from "../components/InternshipCardView";
var pageNo = 1;

class Internship extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLogin: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.location !== this.props.location &&
      nextProps.location.search
    ) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      this.props.getInternshipData(1, nextProps.location.search, true);
    }
  }

  componentDidMount = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    if (!this.props.match.params.id) {
      document.title = "Internship | Trybotics";
    }
    if (this.props.internships.length == 0) {
      this.props.getInternshipData(1, this.props.location.search, false);
    } else if (this.props.location.search && getParameterByName('search', this.props.location.search)) {
      this.props.getInternshipData(1, this.props.location.search, true);
    }
  };

  gridSet = () => {
    if (this.grid) {
      this.grid.updateLayout();
    }
  };

  handleLoadMore = page => {
    pageNo = pageNo + 1;
    this.props.getInternshipData(pageNo, this.props.location.search, false);
  };

  closeModel = () => {
    this.setState({ showLogin: false });
  };

  render() {
    return (
      <InfiniteScroll
        initialLoad={false}
        pageStart={1}
        loadMore={page => {
          this.handleLoadMore(page);
        }}
        hasMore={this.props.internshipHasMore}
        loader={
          <center>
            <CircularProgress size={50} />
          </center>
        }
      >
        <Login closeModel={this.closeModel} open={this.state.showLogin} />
        <StackGrid
          className="stackGrid"
          monitorImagesLoaded={false}
          gridRef={grid => (this.grid = grid)}
          columnWidth={350}
          gutterWidth={15}
          gutterHeight={15}
          enableSSR={true}
          duration={0}
        >
          {this.props.internships.map((internship, index) => {
            return (
              <InternshipCardView
                location={this.props.location}
                key={index + 1}
                gridSet={this.gridSet}
                internship={internship}
              />
            );
          })}
        </StackGrid>
      </InfiniteScroll>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.Home.login.userDetails,
    internships: state.internshipState.internships,
    internshipHasMore: state.internshipState.internshipHasMore
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getInternshipData: (skip, search, refresh) => {
      return dispatch(getInternshipData(skip, search, refresh));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Internship);
