import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Divider from "@material-ui/core/Divider";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import config from "../config";
var apiRoot = config.api.root;

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
	formHeader: {
		textAlign: "center",
		fontSize: "20px",
		backgroundColor: "#2196f3",
		color: "#fff",
		padding: "15px 12px",
		textTransform: "uppercase"
	},
	closeIcon: {
		cursor: "pointer",
		color: "#fff",
		fontSize: "18px",
		fontWeight: "bold",
		float: "right"
	}
});

class ContactUs extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name: "",
			text: "",
			email: "",
			originalURL: "",
			showSnackbar: false,
			msgSnackbar: "",
			error: {}
		};
	}

	handleChange = name => event => {
		this.setState({
			[name]: event.target.value
		});
	};

	handleSend = () => {
		this.state.error = {};
		if (!this.state.name) {
			this.state.error["name"] = "Required";
		}
		if (!this.state.email) {
			this.state.error["email"] = "Required";
		}
		if (!this.state.originalURL) {
			this.state.error["originalURL"] = "Required";
		}
		// if (!this.state.text) {
		//   this.state.error["text"] = "Required";
		// }
		this.forceUpdate();
		console.log('this.state.error',this.state.error)
		if (Object.keys(this.state.error).length == 0) {
			this.sendEmail();
		}
	};

	handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		this.setState({ showSnackbar: false, msgSnackbar: "" });
	};

	sendEmail = () => {
		var data = {
			name: this.state.name,
			email: this.state.email,
			originalURL: this.state.originalURL,
			text: this.state.text,
			sourceURL: window.location.href
		};
		console.log('data',data)
		axios({
			method: "post",
			url: apiRoot + "/removeContent",
			data: data
		})
			.then(response => {
				this.setState({
					name: "",
					text: "",
					email: "",
					originalURL: "",
					showSnackbar: true,
					msgSnackbar: "After verification, it will be removed in 2 working days."
				});
				this.props.onClose && this.props.onClose();
			})
			.catch(error => {
				this.setState({
					name: "",
					text: "",
					email: "",
					originalURL: "",
					showSnackbar: true,
					msgSnackbar: "Please Resend the deatils."
				});
				this.props.onClose && this.props.onClose();
			});
	};

	render() {
		const { fullScreen } = this.props;
		const { classes } = this.props;
		let formHeader = (
			<div className={classes.formHeader}>
				Remove Content{" "}
				{this.props.onClose &&
					(1440 <= 380 ? (
						<KeyboardBackspaceIcon
							className={classes.closeIcon}
							style={{ float: "left" }}
							onClick={this.props.onClose}
						/>
					) : (
							<CloseIcon
								className={classes.closeIcon}
								style={{ float: "right" }}
								onClick={this.props.onClose}
							/>
						))}
			</div>
		);
		let formContent = (
			<DialogContent style={{ marginBottom: 20 }}>
				<DialogContentText style={{ marginTop: 20, textAlign: "justify" }} id="alert-dialog-description">
					Our team will verify the author and remove the content in 2 working days.
        </DialogContentText>
				<TextField
					id="name"
					label="Author Name *"
					error={this.state.error["name"]}
					helperText={this.state.error["name"]}
					name="name"
					value={this.state.name}
					onChange={this.handleChange("name")}
					type="text"
					margin="normal"
					fullWidth
					autoFocus={this.props.onClose}
				/>
				<TextField
					id="email"
					label="Author Email Id *"
					error={this.state.error["email"]}
					helperText={this.state.error["email"]}
					name="email"
					value={this.state.email}
					onChange={this.handleChange("email")}
					type="email"
					margin="normal"
					fullWidth
				/>
				<TextField
					id="originalURL"
					label="Author Original Content URL *"
					error={this.state.error["originalURL"]}
					helperText={this.state.error["originalURL"]}
					name="originalURL"
					value={this.state.originalURL}
					onChange={this.handleChange("originalURL")}
					type="tel"
					margin="normal"
					fullWidth
				/>
				<TextField
					id="text"
					label="Why You Want To Remove It ?"
					error={this.state.error["text"]}
					helperText={this.state.error["text"]}
					name="text"
					value={this.state.text}
					onChange={this.handleChange("text")}
					type="text"
					margin="normal"
					fullWidth
					multiline
					rowsMax="10"
					type="text"
				/>
			</DialogContent>
		);
		let formAction = (
			<DialogActions>
				<Button
					onClick={() => {
						this.setState({
							name: "",
							text: "",
							email: "",
							originalURL: "",
							error: [],
							showSnackbar: false,
							msgSnackbar: ""
						});
					}}
					color="primary"
				>
					Clear
        </Button>
				<Button onClick={this.handleSend} color="primary">
					Send
        </Button>
			</DialogActions>
		);

		return (
			<div>
				<Snackbar
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
					open={this.state.showSnackbar}
					autoHideDuration={5000}
					onClose={this.handleClose}
					ContentProps={{
						"aria-describedby": "message-id"
					}}
					message={<span id="message-id">{this.state.msgSnackbar}</span>}
					action={[
						<IconButton
							key="close"
							aria-label="Close"
							color="inherit"
							onClick={this.handleClose}
						>
							<CloseIcon />{" "}
						</IconButton>
					]}
				/>
				{this.props.onClose ? (
					<Dialog
						scroll="body"
						maxWidth="xs"
						open={this.props.open ? true : false}
						onClose={this.props.onClose}
						TransitionComponent={Transition}
						fullScreen={fullScreen}
					>
						{formHeader}
						{formContent}
						<Divider light />
						{formAction}
					</Dialog>
				) : (
						<div>
							{formHeader}
							{formContent}
							<Divider light />
							{formAction}
						</div>
					)}
			</div>
		);
	}
}

ContactUs.propTypes = {
	fullScreen: PropTypes.bool.isRequired
};

export default withStyles(styles)(withMobileDialog()(ContactUs));
