import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getInternshipDetails } from "../actions/Internship";
import Container from "@material-ui/core/Container";
import clsx from "clsx";
import dynamic from "next/dynamic";
const Internship = dynamic(() => import("../containers/Internship"), {
  ssr: false,
});
const Login = dynamic(() => import("../components/Login"), { ssr: false });

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
  pageSlideAnimation: {
    visibility: "hidden",
    visibility: "visible9" /*For old IE browsers IE6-8 */,
    "&::after": {
      content: "",
      display: "table",
      clear: "both",
    },
  },
  pageSlide: {
    visibility: "visible",
    animation: "pageSlide 1s",
  },

  "@keyframes pageSlide": {
    "0%": {
      opacity: "0",
      transform: "translateY(150px)",
    },
    "100%": {
      opacity: "1",
      transform: "translateY(0px)",
    },
  },
  imgResponsive: {
    display: "block",
    maxHeight: 50,
  },
  description: {
    "& a": {
      color: "#2196F3",
      fontWeight: "lighter",
    },
    "& button": {
      width: "100%",
      color: "#fff",
      backgroundColor: "#2196f3",
      boxShadow:
        "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",
      padding: "9px 16px",
      transition:
        "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      lineGeight: "1.75",
      fontWeight: "500",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      borderRadius: "4px",
      border: "0",
      margin: "0",
      cursor: "pointer",
      outline: "none",
      fontSize: "12px",
      "&:hover": {
        backgroundColor: "#1461a0",
      },
    },
  },
});

class InternshipDetailView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLogin: false,
    };
  }

  componentDidMount = () => {
    let internshipId = this.props.location.pathname.split("/")[2];
    let internship = this.props.internships.find((internship) => {
      return internship.id == internshipId;
    });
    if (internship && internship.descriptionInTag) {
      document.title = internship.title + " | Trybotics";
    } else {
      if (!this.props.fetchingInternshipDetails) {
        this.props.getInternshipDetails(internshipId);
      }
    }
  };

  componentWillReceiveProps(nextProps) {
    let internshipId = nextProps.location.pathname.split("/")[2];
    let internship = this.props.internships.find((internship) => {
      return internship.id == internshipId;
    });
    if (internship && internship.descriptionInTag) {
      document.title = internship.title + " | Trybotics";
    } else {
      if (!this.props.fetchingInternshipDetails) {
        this.props.getInternshipDetails(internshipId);
      }
    }
  }

  closeModel = () => {
    this.setState({ showLogin: false });
  };

  render() {
    const { classes } = this.props;
    let internship = this.props.internships.find((internship) => {
      return internship.id == this.props.location.pathname.split("/")[2];
    });
    return (
      <Container
        disableGutters={true}
        maxWidth="lg"
        className={clsx(classes.pageSlideAnimation, classes.pageSlide)}
      >
        {internship && internship.descriptionInTag ? (
          <div>
            <Typography
              style={{ paddingTop: "5px" }}
              align="center"
              color="inherit"
              variant="h5"
              component="h1"
            >
              {internship.title}
            </Typography>
            <Card style={{ padding: "20px", marginTop: "25px" }}>
              <Grid container spacing={0}>
                <Grid container item md={10} xs={12} direction="column">
                  <Typography
                    style={{ paddingTop: "5px", paddingBottom: "5px" }}
                    color="inherit"
                    variant="h5"
                  >
                    {internship.role}
                  </Typography>
                  <Typography
                    style={{ paddingTop: "5px", paddingBottom: "5px" }}
                    color="inherit"
                    variant="p"
                  >
                    {internship.company}
                  </Typography>
                  <Typography
                    style={{ paddingTop: "5px", paddingBottom: "5px" }}
                    color="inherit"
                    variant="p"
                  >
                    Location(s): {internship.location}
                  </Typography>
                </Grid>
                <Grid
                  style={{ height: internship.subMenu[3] ? "auto" : "200px" }}
                  container
                  item
                  md={2}
                  xs={12}
                >
                  {internship.logo && (
                    <img
                      className={classes.imgResponsive}
                      src={internship.logo}
                      alt={internship.company}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={0} style={{ marginTop: "15px" }}>
                <Grid container item md={3} xs={6} direction="column">
                  <Typography
                    style={{ color: "rgba(0, 0, 0, 0.54)" }}
                    disabled
                    variant="p"
                  >
                    Start Date
                  </Typography>
                  <Typography variant="p" color="primary">
                    {internship.subMenu[0].replace(/\s+/g, " ").trim()}
                  </Typography>
                </Grid>
                <Grid container item md={3} xs={6} direction="column">
                  <Typography
                    style={{ color: "rgba(0, 0, 0, 0.54)" }}
                    disabled
                    variant="p"
                  >
                    Duration
                  </Typography>
                  <Typography variant="p" color="primary">
                    {internship.subMenu[1].replace(/\s+/g, " ").trim()}
                  </Typography>
                </Grid>
                <Grid container item md={2} xs={6} direction="column">
                  <Typography
                    style={{ color: "rgba(0, 0, 0, 0.54)" }}
                    disabled
                    variant="p"
                  >
                    Stipend
                  </Typography>
                  <Typography variant="p" color="primary">
                    {internship.subMenu[2].replace(/\s+/g, " ").trim()}
                  </Typography>
                </Grid>
                {internship.subMenu[3] && (
                  <Grid container item md={2} xs={6} direction="column">
                    <Typography
                      style={{ color: "rgba(0, 0, 0, 0.54)" }}
                      disabled
                      variant="p"
                    >
                      Posted On
                    </Typography>
                    <Typography variant="p" color="primary">
                      {internship.subMenu[3].replace(/\s+/g, " ").trim()}
                    </Typography>
                  </Grid>
                )}
                {internship.subMenu[4] && (
                  <Grid container item md={2} xs={6} direction="column">
                    <Typography
                      style={{ color: "rgba(0, 0, 0, 0.54)" }}
                      disabled
                      variant="p"
                    >
                      Apply By
                    </Typography>
                    <Typography variant="p" color="primary">
                      {internship.subMenu[4].replace(/\s+/g, " ").trim()}
                    </Typography>
                  </Grid>
                )}
                {internship.subMenu[5] && (
                  <Grid container item md={2} xs={6} direction="column">
                    <Typography
                      style={{ color: "rgba(0, 0, 0, 0.54)" }}
                      disabled
                      variant="p"
                    >
                      Openings:
                    </Typography>
                    <Typography variant="p" color="primary">
                      {internship.subMenu[5].replace(/\s+/g, " ").trim()}
                    </Typography>
                  </Grid>
                )}
                {internship.subMenu[6] && (
                  <Grid container item md={2} xs={6} direction="column">
                    <Typography
                      style={{ color: "rgba(0, 0, 0, 0.54)" }}
                      disabled
                      variant="p"
                    >
                      Eligibility:
                    </Typography>
                    <Typography variant="p" color="primary">
                      {internship.subMenu[6].replace(/\s+/g, " ").trim()}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Card>
            <a
              style={{ textDecoration: "none" }}
              onClick={() => {
                this.props.user.isLogin
                  ? internship.isInternshipClosed
                    ? this.props.history.push("/internship")
                    : window.open(internship.author.siteLink, "_blank")
                  : this.setState({ showLogin: true });
              }}
            >
              <Button
                style={{ marginTop: 30 }}
                fullWidth
                variant="contained"
                color="primary"
                size="large"
              >
                {internship.isInternshipClosed
                  ? "Applications are closed for this internship! Click here to browse more internships"
                  : "Apply Now"}
              </Button>
            </a>
            <Card style={{ marginTop: "25px" }}>
              <Grid
                style={{ margin: "auto", clear: "both", fontSize: "14px" }}
                alignItems="center"
                container
                spacing={0}
              >
                <Grid style={{ padding: "10px" }} item xs={12} sm={12}>
                  <div style={{ padding: "10px" }}>
                    <Typography
                      style={{ marginBottom: "10px", fontWeight: "bold" }}
                      align="center"
                      // color="h5"
                      variant="h5"
                    >
                      DESCRIPTION
                    </Typography>
                    <Divider />
                    <div
                      className={classes.description}
                      dangerouslySetInnerHTML={{
                        __html: internship.descriptionInTag,
                      }}
                      style={{
                        padding: "5px",
                        paddingTop: "10px",
                        textAlign: "justify",
                      }}
                    ></div>
                  </div>
                </Grid>
              </Grid>
            </Card>
            <a
              style={{ textDecoration: "none" }}
              onClick={(e) => {
                this.props.user.isLogin
                  ? internship.isInternshipClosed
                    ? this.props.history.push("/internship")
                    : window.open(internship.author.siteLink, "_blank")
                  : this.setState({ showLogin: true });
              }}
            >
              <Button
                style={{ marginTop: 30 }}
                fullWidth
                variant="contained"
                color="primary"
                size="large"
              >
                {internship.isInternshipClosed
                  ? "Applications are closed for this internship! Click here to browse more internships"
                  : "Apply Now"}
              </Button>
            </a>
            <Divider
              style={{ marginTop: "30px", marginBottom: "25px" }}
              light
            />
            <Typography
              style={{ marginBottom: "30px" }}
              align="center"
              color="inherit"
              variant="h5"
            >
              YOU MIGHT ALSO LIKE
            </Typography>
            <Internship
              match={this.props.match}
              location={this.props.location}
            />
            <Login closeModel={this.closeModel} open={this.state.showLogin} />
          </div>
        ) : (
          <center style={{ marginTop: "10px" }}>
            <CircularProgress size={50} />
          </center>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.Home.login.userDetails,
    internships: state.internshipState.internships,
    fetchingInternshipDetails: state.internshipState.fetchingInternshipDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getInternshipDetails: (id) => {
      return dispatch(getInternshipDetails(id));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(InternshipDetailView));
