import {
  SET_YOUTUBE_DATA,
  SET_YOUTUBE_HAS_MORE,
  YOUTUBE_ADDED,
  YOUTUBE_FAIL,
  YOUTUBE_EDITED,
  YOUTUBE_DELETED,
  YOUTUBE_COMMENT_ADDED,
  YOUTUBE_COMMENT_EDITED,
  YOUTUBE_COMMENT_DELETED,
  YOUTUBE_COMMENT_FAIL,
  YOUTUBE_LIKE_ADDED,
  YOUTUBE_LIKE_FAIL,
  YOUTUBE_LIKE_REMOVED,
  SET_YOUTUBE_DETAILS,
  SET_FETCHING_YOUTUBE_DETAILS,
  CLOSE_SNACKBAR
} from "../actions/ActionType";

const initialState = {
  snackbar: {
    msg: null,
    show: false
  },
  youtubes: [],
  youtubeHasMore: true,
  fetchingYoutubeDetails: false
};

const youtubeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_YOUTUBE_DATA:
      let youtubeObjects = {};
      state.youtubes.map(youtube => { youtubeObjects[youtube.title + youtube.id] = youtube; });
      action.data.map(youtube => {
        youtubeObjects[youtube.title + youtube.id] = youtubeObjects[youtube.title + youtube.id] ? { ...youtubeObjects[youtube.title + youtube.id], ...youtube } : youtube;
      });
      return Object.assign({}, state, {
        youtubes: action.refresh ? action.data : Object.values(youtubeObjects)
      });

    case SET_YOUTUBE_HAS_MORE:
      return Object.assign({}, state, { youtubeHasMore: action.data });

    case SET_FETCHING_YOUTUBE_DETAILS:
      return Object.assign({}, state, {
        fetchingYoutubeDetails: action.data
      });

    case SET_YOUTUBE_DETAILS:
      let youtubes = [];
      youtubes = Array.from(state.youtubes);
      let index = youtubes.findIndex(youtube => {
        return youtube.id == action.data.id;
      });
      if (index > -1) {
        youtubes[index] = action.data;
      } else {
        youtubes.push(action.data);
      }
      return Object.assign({}, state, { youtubes: youtubes });

    case YOUTUBE_ADDED:
      return Object.assign({}, state, {
        youtubes: [action.data].concat(state.youtubes),
        snackbar: { msg: "Your youtube data is added", show: true }
      });

    case YOUTUBE_FAIL:
      return Object.assign({}, state, {
        snackbar: { msg: "Error, Please try again", show: true }
      });

    case YOUTUBE_EDITED:
      for (var i = 0; i < state.youtubes.length; i++) {
        if (state.youtubes[i].id == action.data.id) {
          state.youtubes[i].title = action.data.title;
          state.youtubes[i].description = action.data.description;
          state.youtubes[i].videoId = action.data.videoId;
        }
      }
      return Object.assign({}, state, {
        snackbar: { msg: "Your Youtube is Edited", show: true }
      });

    case YOUTUBE_DELETED:
      return Object.assign({}, state, {
        youtubes: state.youtubes.filter(youtube => youtube.id != action.data),
        snackbar: { msg: "Your youtube is deleted", show: true }
      });

    case YOUTUBE_COMMENT_ADDED:
      for (var i = 0; i < state.youtubes.length; i++) {
        if (state.youtubes[i].id == action.data.id) {
          state.youtubes[i].comments.push(action.data.comments);
        }
      }
      return Object.assign({}, state, {
        youtubes: state.youtubes,
        snackbar: { msg: "Your Comment Added", show: true }
      });

    case YOUTUBE_COMMENT_EDITED:
      for (var i = 0; i < state.youtubes.length; i++) {
        if (state.youtubes[i].id == action.data.id) {
          for (var j = 0; j < state.youtubes[i].comments.length; j++) {
            if (state.youtubes[i].comments[j]._id == action.data.commentId) {
              state.youtubes[i].comments[j].comment = action.data.comment;
            }
          }
        }
      }
      return Object.assign({}, state, {
        youtubes: state.youtubes,
        snackbar: { msg: "Your comment is edited", show: true }
      });

    case YOUTUBE_COMMENT_DELETED:
      for (var i = 0; i < state.youtubes.length; i++) {
        if (state.youtubes[i].id == action.data.id) {
          for (var j = 0; j < state.youtubes[i].comments.length; j++) {
            if (state.youtubes[i].comments[j]._id == action.data.commentId) {
              state.youtubes[i].comments.splice(j, 1);
            }
          }
        }
      }
      return Object.assign({}, state, {
        youtubes: state.youtubes,
        snackbar: { msg: "Your comment is deleted", show: true }
      });

    case YOUTUBE_COMMENT_FAIL:
      return Object.assign({}, state, {
        snackbar: { msg: "Error, Please Login", show: true }
      });

    case YOUTUBE_LIKE_ADDED:
      for (var i = 0; i < state.youtubes.length; i++) {
        if (state.youtubes[i].id == action.data.id) {
          state.youtubes[i].likes = action.data.likes;
        }
      }
      return Object.assign({}, state, {
        youtubes: state.youtubes,
        snackbar: { msg: "Your Like Added", show: true }
      });

    case YOUTUBE_LIKE_FAIL:
      return Object.assign({}, state, {
        snackbar: { msg: "Error, Please Login", show: true }
      });

    case YOUTUBE_LIKE_REMOVED:
      for (var i = 0; i < state.youtubes.length; i++) {
        if (state.youtubes[i].id == action.data.id) {
          state.youtubes[i].likes = action.data.likes;
        }
      }
      return Object.assign({}, state, {
        youtubes: state.youtubes,
        snackbar: { msg: "You have Disliked", show: true }
      });

    case CLOSE_SNACKBAR:
      return Object.assign({}, state, {
        snackbar: { msg: state.snackbar.msg, show: false }
      });

    default:
      return state;
  }
};

export default youtubeReducer;
