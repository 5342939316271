import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import SwipeableViews from "react-swipeable-views";
import KaranPic from "../images/karan.jpg";
import KhushbooPic from "../images/khushboo.jpg";
import motiPic from "../images/moti.jpg";
import tarunPic from "../images/tarun.jpg";
import shiluPic from "../images/shilu.jpg";
import shilpaPic from "../images/shilpa.jpg";
import komalPic from "../images/komal.jpg";
import kishanPic from "../images/kishan.jpg";
import poonamPic from "../images/poonam.jpg";
import shahnawazPic from "../images/shahnawaz.jpg";
import satyajitPic from "../images/satyajit.jpg";
import dipeshPic from "../images/dipesh.jpg";
import soumitPic from "../images/soumit.JPG";
import udayPic from "../images/uday.jpg";
import EventGallery from "../components/EventGallery";
import ContactUs from "../components/ContactUs";
import AssociateGallery from "../components/AssociateGallery";
import Container from "@material-ui/core/Container";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import clsx from "clsx";

function TabContainer({ children, dir }) {
  return (
    <Typography
      component="div"
      dir={dir}
      style={{ textAlign: 'justify', padding: 8 * 3, fontSize: '13px' }}
    >
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired
};

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500
  },
  pageSlideAnimation: {
    visibility: "hidden",
    visibility: "visible9" /*For old IE browsers IE6-8 */,
    "&::after": {
      content: "",
      display: "table",
      clear: "both"
    }
  },
  pageSlide: {
    visibility: "visible",
    animation: "pageSlide 1s"
  },
  "@keyframes pageSlide": {
    "0%": {
      opacity: "0",
      transform: "translateY(150px)"
    },
    "100%": {
      opacity: "1",
      transform: "translateY(0px)"
    }
  },
  profileView: {
    textAlign: "center"
  },
  pvHeader: {
    position: "relative",
    height: "145px",
    width: "100%",
    backgroundImage:
      'url("https://d2v9y0dukr6mq2.cloudfront.net/video/thumbnail/GfRLKaE/videoblocks-blue-digital-technology-background-logo_bjkpphjif_thumbnail-small01.jpg")',
    backgroundRepeat: "no-repeat",
    webkitBackgroundSize: "cover",
    mozbackgroundSize: "cover",
    oBackgroundSize: "cover",
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  pvMain: {
    borderRadius: "50%",
    width: "130px",
    position: "absolute",
    height: "130px",
    bottom: "-50px",
    left: "50%",
    marginLeft: "-65px",
    webkitTransition: "all",
    oTransition: "all",
    transition: "all",
    webkitTransitionDuration: "300ms",
    transitionDuration: "300ms"
  },
  pvBody: {
    marginTop: "70px",
    padding: "0 20px 10px"
  },
  pvFollowBtn: {
    padding: '7px 20px',
    background: '#2196f3',
    color: '#FFF',
    borderRadius: '3px',
    textTransform: 'uppercase',
    display: 'block',
    webkitTransition: 'all',
    oTransition: 'all',
    transition: 'all',
    webkitTransitionDuration: '300ms',
    transitionDuration: '300ms',
    cursor: 'pointer',
    '&:hover': {
      background: '#00a5b'
    }
  }
});

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      showContactUs: false
    };
  }

  componentDidMount = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    document.title = "About Us | Trybotics";
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  closeModel = () => {
    this.setState({ showContactUs: false });
  };

  render() {
    const { classes, theme } = this.props;
    let teams = [
      {
        name: "Karan Shaw",
        role: "Founder",
        img: KaranPic,
        url: "https://www.linkedin.com/in/karan-shaw/"
      },
      {
        name: "Khushboo Kumari",
        role: "Co-Founder",
        img: KhushbooPic,
        url: "https://www.linkedin.com/in/khushboo-kumari/"
      },
      {
        name: 'Motilal Poddar',
        role: 'Adviser',
        img: motiPic
      }, {
        name: 'Tarun Shaw',
        role: 'Director',
        img: tarunPic
      }, {
        name: 'Shilu Jaiswal',
        role: 'Director',
        img: shiluPic
      }, {
        name: 'Satyajit Chaterjee',
        role: 'Lead Designer',
        img: satyajitPic
      }, {
        name: 'Dipesh Baheti',
        role: 'Lead Engineer',
        img: dipeshPic
      }, {
        name: 'Shilpa Shaw',
        role: 'Lead Human Resources',
        img: shilpaPic
      }, {
        name: 'Md Shshnawaz Alam',
        role: 'R&D Head',
        img: shahnawazPic
      }, {
        name: 'Kishan Shaw',
        role: 'Web Developer & Designer',
        img: kishanPic
      }, {
        name: 'Komal Shaw',
        role: 'Marketing Head',
        img: komalPic
      }, {
        name: 'Poonam Kumari',
        role: 'Public Relation Head',
        img: poonamPic
      }, {
        name: 'Soumit Acharjee',
        role: 'Software Engineer',
        img: soumitPic
      }, {
        name: 'Your Name',
        role: 'You Can Be Next',
        img: udayPic
      }
    ];
    return (
      <Container disableGutters={true} maxWidth="lg" className={clsx(classes.pageSlideAnimation, classes.pageSlide)}>
        <Typography
          style={{ margin: 10 }}
          align="center"
          color="inherit"
          variant="h5"
          component="h1"
        >
          ABOUT US / OUR MISSION & VISION
        </Typography>
        <Grid style={{ margin: 'auto', marginTop: '15px' }} justify="center" container spacing={0}>
          <Grid
            style={{ padding: '5px' }}
            justify="center"
            alignItems="center"
            item
            xs={12}
            sm={12}
            md={6}
          >
            <div>
              <AppBar position="static" color="default">
                <Tabs
                  value={this.state.value}
                  onChange={this.handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  fullWidth
                >
                  <Tab label="About Us" />
                  <Tab label="Our Vision & Mission" />
                </Tabs>
              </AppBar>
              <Card>
                <SwipeableViews
                  axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                  index={this.state.value}
                  onChangeIndex={this.handleChangeIndex}
                >
                  <TabContainer dir={theme.direction}>
                    Trybotics is Worlds's Largest online Tech Community, where you can learn, update your skills, compete with fellow techies and get your dream job. Learn how to make anything with Trybotics. Easy to follow step-by-step instructions, online classes, and a vibrant maker community. Find out how to do almost anything on Trybotics, the world's biggest Show and Tell. Here people can learn, explore, make and
                    share their technical skills by reading or writing
                    material of their project or research work, which best
                    portrays their talent and we provide the opportunity to
                    get recognition and fame. In this world, where there are more
                    than a billion population and every single person have
                    some of the other talents in them, there are only handful
                    options available where people can show their talent to
                    the world and get the desired recognition for their
                      skills. <br />
                    <br />
                    Considering the above mentioned limitations and
                    understanding the scope of reaching to millions of people
                    by providing them an opportunity to showcase their talent
                    to the world and in the meantime to get popularity for
                    their talent, we have come up with this platform where
                    anyone can showcase their talent in their field. The foremost
                    vision of Trybotics is to provide a unique opportunity to
                    connect with the people who really need the employee like
                    you.
                    </TabContainer>
                  <TabContainer dir={theme.direction}>
                    Our only mission through this platform is to reach as many
                    people as possible and help them in identifying their
                    skills. Trybotics is the best platform for all students.
                    Our main mission to help all the students who really have
                    some goal in their life, they can tell us their problems
                    and we definitely help you. We envision the engineering
                    students of our country as "Engineers". Our vision is to
                    concentrate on the skillset development of an engineering
                    student his overall evolution as a student. Our focus is
                    on "Learning by doing" and we think we can put this
                    approach in every engineering student.
                      <br />
                    <br />
                    The mission is to touch the lives of over 5 lakhs
                    engineering students through our website within 2-3 years.
                    We are also targeting the placement scenario and the plan
                    is to bring an increment of 2-3 % in the overall placement
                    numbers. This website is based on Open Source technology
                    and provides free project resources to the student who is
                    interested in upgrading themselves with new technology.
                    This website also helps the student who has some
                    innovative ideas by providing support in upbringing their
                    ideas. Our main mission to help all the students who
                    really have some goal in their life, they can tell us
                    their problems and we definitely help you.
                    </TabContainer>
                </SwipeableViews>
              </Card>
            </div>
          </Grid>
          {teams.map(team => {
            return (
              <Grid
                style={{ padding: '5px' }}
                justify="center"
                alignItems="center"
                item
                xs={12}
                sm={6}
                md={3}
              >
                <Card className={classes.profileView} id="profileView">
                  <div className={classes.pvHeader}>
                    <img src={team.img} className={classes.pvMain} id="pvMain" alt={team.name} />
                  </div>
                  <div className={classes.pvBody} style={{ paddingTop: '10px', marginBottom: '10px' }}>
                    <h2 style={{ margin: 0, marginTop: 5, lineHeight: '100%', fontSize: '20px', fontWeight: '400' }}>{team.name}</h2>
                    <h2 style={{ margin: 0, marginTop: 5, lineHeight: '100%', fontSize: '20px', fontWeight: '400' }}>{team.role}</h2>
                    {team.url &&
                      <div style={{ marginTop: 8 }}>
                        <a target="blank" href={team.url} style={{ color: "#039be5", textDecoration: 'none' }}>
                          <Button color="primary">Connect On &nbsp; <LinkedInIcon style={{ width: 20, height: 20 }} /></Button>
                        </a>
                        <a style={{ marginTop: 10 }}
                          onClick={() => {
                            this.setState({ showContactUs: true });
                          }}
                          className={classes.pvFollowBtn}
                        >
                          Contact
                      </a>
                      </div>
                    }
                  </div>
                </Card>
              </Grid>
            );
          })}
        </Grid>
        <Divider style={{ marginTop: '20px', marginBottom: '20px' }} light />
        <EventGallery />
        <Divider style={{ marginTop: '20px', marginBottom: '20px' }} light />
        <AssociateGallery />
        <ContactUs open={this.state.showContactUs} onClose={this.closeModel} />
      </Container>
    );
  }
}

export default withStyles(styles, { withTheme: true })(About);
