import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Zoom from "@material-ui/core/Zoom";
import StackGrid from "react-stack-grid";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import InfiniteScroll from "../components/InfiniteScroll";
import AdCardView from "../components/BlogCardView";
import AdPost from "../components/AdPost";
import Login from "../components/Login";
import ImageIcon from "@material-ui/icons/Image";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import LinkIcon from "@material-ui/icons/Link";
import { withStyles } from "@material-ui/core/styles";

import { 
    getAdData, 
    getAdDetails 
} from "../actions/Ad";

var pageNo = 1;

function Transition(props) {
  return <Zoom direction="up" {...props} />;
}
const styles = theme => ({
  formHeader: {
    textAlign: "center",
    fontSize: "20px",
    backgroundColor: "#2196f3",
    color: "#fff",
    padding: "15px 12px",
    textTransform: "uppercase"
  },
  buttonOutlined: {
    borderRadius: "unset !important",
    border: "1px solid rgb(153, 153, 153) !important"
  }
});

class Ad extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ad: null,
      openModel: true,
      showAdPost: false,
      showLogin: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.location !== this.props.location &&
      nextProps.location.search
    ) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      this.props.getAdData(1, nextProps.location.search, true);
    }
  }

  componentDidMount = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    if (!this.props.match.params.id) {
      document.title = "Ad | Trybotics";
    }
    if (this.props.ads.length == 0) {
      this.props.getAdData(1, this.props.location.search, false);
    } else if (this.props.location.search) {
      this.props.getAdData(1, this.props.location.search, true);
    }
  };

  gridSet = () => {
    if (this.grid) {
      this.grid.updateLayout();
    }
  };

  handleLoadMore = page => {
    pageNo = pageNo + 1;
    this.props.getAdData(pageNo, this.props.location.search, false);
  };

  closeAdPost = () => {
    this.setState({ showAdPost: false });
  };

  closeModel = () => {
    this.setState({ openModel: false, ad: null, showLogin: false });
  };

  getAd = id => {
    let ad = this.props.ads.find(ad => {
      return ad.id == id;
    })
    if (ad) {
      this.setState({ ad: ad })
    } else {
      this.props.getAdDetails(id)
    }
  };

  render() {
    const { classes } = this.props;
    if (this.props.match.params.id && !this.state.openModel) {
      this.state.openModel = true
      return <Redirect to={"/ad"} />;
    }
    let ads = [];
    let counter = 0;
    ads.push(
      <Card
        key={"ad" + counter}
        onClick={() => {
          this.props.user.isLogin
            ? this.setState({ showAdPost: true })
            : this.setState({ showLogin: true });
        }}
      >
        <div className={classes.formHeader}>Post Your Advertisement </div>
        <div
          style={{
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingBottom: "20px"
          }}
        >
          <TextField
            id="adTitle"
            label="Title of your ad"
            name="adTitle"
            type="text"
            margin="normal"
            fullWidth
            disabled
          />
          <TextField
            id="adDescription"
            label="Description of your ad"
            name="adDescription"
            type="text"
            margin="normal"
            fullWidth
            disabled
          />
          <center style={{ marginTop: "20px" }}>
            <Button
              className={classes.buttonOutlined}
              variant="outlined"
              style={{ color: "#777", fontWeight: "600" }}
            >
              Image &nbsp;&nbsp;
              <ImageIcon />
            </Button>
            <Button
              className={classes.buttonOutlined}
              variant="outlined"
              style={{ color: "#777", fontWeight: "600" }}
            >
              Video &nbsp;&nbsp;
              <PlayCircleFilledIcon />
            </Button>
            <Button
              className={classes.buttonOutlined}
              variant="outlined"
              style={{ color: "#777", fontWeight: "600" }}
            >
              Link &nbsp;&nbsp;
              <LinkIcon />
            </Button>
          </center>
        </div>
        <Divider light />
        <div style={{ paddingBottom: "40px", paddingTop: "5px" }}>
          <div style={{ paddingRight: "15px" }}>
            <Button
              style={{ float: "right", fontWeight: "600" }}
              color="primary"
            >
              Post
            </Button>
            <Button style={{ float: "right", fontWeight: "600" }}>
              Cancel
            </Button>
          </div>
        </div>
      </Card>
    );
    for (var i in this.props.ads) {
      counter = counter + 1;
      ads.push(
        <AdCardView
          location={this.props.location}
          key={"ad" + counter}
          gridSet={this.gridSet}
          data={this.props.ads[i]}
          type="ad"
          link
        />
      );
    }

    let ad = null;
    if (this.props.match.params.id) {
      let adBody = null;
      ad = this.props.ads.find(
        ad => ad.id == this.props.match.params.id
      );
      if (ad) {
        adBody = (
          <AdCardView
            location={this.props.location}
            gridSet={this.gridSet}
            data={ad}
            type="ad"
          />
        );
      } else {
        if (this.state.ad) {
          adBody = (
            <AdCardView
              location={this.props.location}
              gridSet={this.gridSet}
              data={this.state.ad}
              type="ad"
            />
          );
        } else {
          this.getAd(this.props.match.params.id);
          adBody = (
            <center>
              <center style={{ padding: "10px" }}>
                <CircularProgress size={50} />
              </center>
            </center>
          );
        }
      }
      ad = (
        <Dialog
          scroll="body"
          maxWidth="xs"
          open={this.state.openModel}
          onClose={this.closeModel}
          TransitionComponent={Transition}
        >
          {adBody}
        </Dialog>
      );
    }
    return (
      <InfiniteScroll
        initialLoad={false}
        pageStart={1}
        loadMore={page => {
          this.handleLoadMore(page);
        }}
        hasMore={this.props.adHasMore}
        loader={
          <center>
            <CircularProgress size={50} />
          </center>
        }
      >
        <AdPost 
          open={this.state.showAdPost} 
          onClose={this.closeAdPost}
        />
        <Login closeModel={this.closeModel} open={this.state.showLogin} />
        {ad}
        <StackGrid
          className="stackGrid"
          monitorImagesLoaded={false}
          gridRef={grid => (this.grid = grid)}
          columnWidth={350}
          gutterWidth={15}
          gutterHeight={15}
          enableSSR={true}
          duration={0}
        >
          {ads}
        </StackGrid>
      </InfiniteScroll>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.Home.login.userDetails,
    ads: state.adState.ads,
    adHasMore: state.adState.adHasMore
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAdData: (page, search, refresh) => {
      return dispatch(getAdData(page, search, refresh));
    },
    getAdDetails: (id) => {
      return dispatch(getAdDetails(id));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Ad));
