import React from "react";
import Hammer from "react-hammerjs"
import { Route, Switch, Redirect } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import Home from "./containers/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import FAQ from "./containers/FAQ";
import About from "./containers/AboutUs";
import Terms from "./containers/Terms";
import Privacy from "./containers/Privacy";
import PastEvents from "./containers/PastEvents";
import MiniQuadcopter from "./containers/MiniQuadcopter";
import Affiliate from "./containers/Affiliate";
import Ad from "./containers/Ad";
import Blog from "./containers/Blog";
import BlogDetailView from "./containers/BlogDetailView";
import Project from "./containers/Project";
import ProjectDetailView from "./containers/ProjectDetailView";
import Internship from "./containers/Internship";
import InternshipDetailView from "./containers/InternshipDetailView";
import Login from "./components/Login";
import Youtube from "./containers/Youtube";
import Event from "./containers/Event";
import AddProject from "./containers/AddProject";
import NotFound from "./containers/NotFound";
import theme from "./Theme";

export default () => {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <div>
          <Hammer direction={'DIRECTION_DOWN'} onSwipe={(result) => { window.location.reload(); console.log('result', result); }}>
            <div>
              <Route style={{ clear: "both" }} exact path="*" component={Header} />
            </div>
          </Hammer>
          <div style={{ margin: 5, marginTop: 80, clear: "both", minHeight: '80vh' }}>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/home" component={Home} />
              <Route exact path="/workshop/:id" component={Event} />
              <Route exact path="/past-events" component={PastEvents} />
              <Route exact path="/mini-quadcopter" component={MiniQuadcopter} />
              <Route exact path="/past_events" render={() => (<Redirect to="/past-events" />)} />
              <Route exact path="/mini_quadcopter" render={() => (<Redirect to="/mini-quadcopter" />)} />
              <Route exact path="/about" component={About} />
              <Route exact path="/faq" component={FAQ} />
              <Route exact path="/privacy" component={Privacy} />
              <Route exact path="/terms" component={Terms} />
              <Route exact path="/internship" component={Internship} />
              <Route exact path="/buy" component={Affiliate} />
              <Route exact path="/youtube" component={Youtube} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/ad" component={Ad} />
              <Route exact path="/ad/:id" component={Ad} />
              <Route exact path="/blog" component={Blog} />
              <Route exact path="/blog/:id" component={BlogDetailView} />
              <Route exact path="/project" component={Project} />
              <Route exact path="/project/:id" component={ProjectDetailView} />
              <Route exact path="/buy/:id" component={Affiliate} />
              <Route exact path="/internship/:id" component={InternshipDetailView} />
              <Route exact path="/youtube/:id" component={Youtube} />
              <Route exact path="/add-project" component={AddProject} />
              <Route exact path="/edit-project/:id" component={AddProject} />
              <Route render={({ staticContext }) => {
                if (staticContext) {
                  staticContext.statusCode = 404
                }
                return <NotFound />
              }} />
            </Switch>
          </div>
          <Route exact path="*" component={Footer} />
        </div>
      </ThemeProvider>
    </div>
  );
};
