import React from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
import ShareIcon from "@material-ui/icons/Share";
import FacebookIcon from "@material-ui/icons/Facebook";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";

const styles = (theme) => ({
  logo: {
    marginTop: "10px ",
    maxWidth: "100px ",
    maxHeight: "45px ",
  },
  whatsappShare: {
    color: "#25d366 !important",
  },
  googleShare: {
    color: "#db4437 !important",
  },
  facebookShare: {
    color: "#4267b2 !important",
  },
  linkedInShare: {
    color: "#0077b5 !important",
  },
  twitterShare: {
    color: "#1da1f2 !important",
  },
});

class InternshipCardView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorElShareMenu: null,
    };
  }

  handleRequestClose = (e, url) => {
    this.setState({
      anchorElProfileMenu: null,
      anchorElCardMenu: null,
      anchorElShareMenu: null,
    });
    if (url) {
      window.open(url);
    }
  };

  handleShareMenu = (event) => {
    this.setState({
      anchorElShareMenu: event.currentTarget,
    });
  };

  render() {
    const { classes } = this.props;
    let path = "";
    if (this.props.location.pathname.split("/").length < 3) {
      path = "internship/";
    }
    let shareLinkURL =
      "https://trybotics.com/internship/" + this.props.internship.id;
    let shareMenu = (
      <Menu
        className="menu"
        anchorEl={this.state.anchorElShareMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(this.state.anchorElShareMenu)}
        onClose={(e) => {
          this.handleRequestClose(e);
        }}
      >
        <MenuItem
          onClick={(e) => {
            this.handleRequestClose(
              e,
              "https://api.whatsapp.com/send?text=" + shareLinkURL
            );
          }}
        >
          <ListItemIcon>
            <WhatsAppIcon
              className={classes.whatsappShare}
              aria-hidden="true"
              style={{ fontSize: "20px" }}
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <b className={classes.whatsappShare} style={{ fontSize: "14px" }}>
                WhatsApp
              </b>
            }
          />
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            this.handleRequestClose(
              e,
              "https://www.facebook.com/sharer.php?u=" + shareLinkURL
            );
          }}
        >
          <ListItemIcon>
            <FacebookIcon
              className={classes.facebookShare}
              aria-hidden="true"
              style={{ fontSize: "20px" }}
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <b className={classes.facebookShare} style={{ fontSize: "14px" }}>
                Facebook
              </b>
            }
          />
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            this.handleRequestClose(
              e,
              "https://www.linkedin.com/shareArticle?mini=true&url=" +
                shareLinkURL
            );
          }}
        >
          <ListItemIcon>
            <LinkedInIcon
              className={classes.linkedInShare}
              aria-hidden="true"
              style={{ fontSize: "20px" }}
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <b className={classes.linkedInShare} style={{ fontSize: "14px" }}>
                Linkedin
              </b>
            }
          />
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            this.handleRequestClose(
              e,
              "https://twitter.com/share?url=" + shareLinkURL
            );
          }}
        >
          <ListItemIcon>
            <TwitterIcon
              className={classes.twitterShare}
              aria-hidden="true"
              style={{ fontSize: "20px" }}
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <b className={classes.twitterShare} style={{ fontSize: "14px" }}>
                Twitter
              </b>
            }
          />
        </MenuItem>
      </Menu>
    );
    return (
      <div>
        {shareMenu}
        <Card>
          <Link style={{ all: "initial" }} to={path + this.props.internship.id}>
            <Grid
              style={{
                paddingTop: "15px",
                paddingLeft: "15px",
                paddingRight: "15px",
                color: "rgba(0, 0, 0, 0.54)",
              }}
              container
              spacing={0}
            >
              <Grid container item xs={8} direction="column">
                <Typography style={{ color: "black" }} variant="h6">
                  {this.props.internship.role}
                </Typography>
                <Typography
                  style={{ color: "rgba(0, 0, 0, 0.54)" }}
                  variant="h6"
                >
                  {this.props.internship.company}
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={4}
                direction="row"
                justify="center"
                alignItems="center"
              >
                {this.props.internship.logo && (
                  <img
                    ref={(input) => {
                      // onLoad replacement for SSR
                      if (!input) {
                        return;
                      }
                      const img = input;
                      const updateFunc = () => {
                        // this.props.gridSet();
                      };
                      img.onload = updateFunc;
                      if (img.complete) {
                        updateFunc();
                      }
                    }}
                    className={classes.logo}
                    src={this.props.internship.logo}
                    alt={this.props.internship.company}
                  />
                )}
              </Grid>
              <Grid container item xs={12} direction="column">
                <Typography
                  style={{
                    color: "rgba(0, 0, 0, 0.54)",
                    fontWeight: "bold",
                  }}
                  variant="h6"
                >
                  Location: {this.props.internship.location}
                </Typography>
              </Grid>
            </Grid>
          </Link>
          <Link style={{ all: "initial" }} to={path + this.props.internship.id}>
            <Grid
              style={{
                paddingTop: "5px",
                paddingLeft: "15px",
                paddingRight: "15px",
              }}
              container
              spacing={0}
            >
              <Grid container item xs={7}>
                <Typography
                  variant="body2"
                  style={{ fontWeight: "600", fontSize: "12px" }}
                >
                  Start Date: {this.props.internship.startDate}
                </Typography>
              </Grid>
              <Grid container item xs={5}>
                <Typography
                  variant="body2"
                  style={{ fontWeight: "600", fontSize: "12px" }}
                >
                  Duration: {this.props.internship.duration}
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={this.props.internship.appliedBy ? 7 : 12}
                style={{ fontWeight: "600", fontSize: "12px" }}
              >
                <Typography
                  variant="body2"
                  style={{ fontWeight: "600", fontSize: "12px" }}
                >
                  Stipend: {this.props.internship.stipend}
                </Typography>
              </Grid>
              {this.props.internship.appliedBy && (
                <Grid container item xs={5}>
                  <Typography
                    variant="body2"
                    style={{ fontWeight: "600", fontSize: "12px" }}
                  >
                    Apply By: {this.props.internship.appliedBy}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Link>
          <Divider style={{ marginTop: "10px", marginBottom: "5px" }} light />
          <Grid
            style={{ paddingLeft: "5px", paddingRight: "5px" }}
            container
            spacing={0}
          >
            <Grid container item xs={6}>
              <Link
                style={{ all: "initial", width: "inherit" }}
                to={"/internship/" + this.props.internship.id}
              >
                <Button fullWidth={true} size="large">
                  <RemoveRedEyeIcon /> &nbsp; View
                </Button>
              </Link>
            </Grid>
            <Grid container item xs={6}>
              <Button
                fullWidth={true}
                size="large"
                onClick={this.handleShareMenu}
              >
                <ShareIcon />
                &nbsp; Share
              </Button>
            </Grid>
          </Grid>
          <Divider style={{ marginTop: 5 }} light />
        </Card>
      </div>
    );
  }
}

export default withStyles(styles)(InternshipCardView);
