import {
	SET_EVENT_DATA,
} from './ActionType'
import axios from 'axios'

import config from '../config'
var apiRoot = config.api.root

export const getEventData = (eventType,subEventType) =>{
	
	let queryParameter = ''
	if(eventType&&subEventType){
			queryParameter = '?eventType='+eventType+'&subEventType='+subEventType
	}else if(eventType){
			queryParameter = '?eventType='+eventType
	}
	return (dispatch) =>{
		
			return axios.get(apiRoot+'/api/event'+queryParameter).then(
				response => {
					response.data['subEventType']=subEventType
					dispatch(setEventData(response.data))
				},
				error => {
				console.log(error)
				}
			)
		
	}
}


export const setEventData = (data) =>{
	return {
		type: SET_EVENT_DATA,data
	}
}
