import React from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ContactUs from "../components/ContactUs";
import IIT_Bhubaneswar from "../images/IIT_Bhubaneswar.png";
import HT_India_Lab from "../images/HT_India_Lab.png";
import Makeintern from "../images/Makeintern.png";
import Robokart from "../images/Robokart.png";
import Nfly from "../images/Nfly.jpg";
import Enginex from "../images/enginex.jpg";
import TechBhubaneswar from "../images/techbhubaneswar.png";
import DSCKIIT from "../images/dsckiit.png";
import Utkal_Techies from "../images/UtkalTechiesForum.png";
import Emisha from "../images/emisha.jpg";
import Google_Developers from "../images/Google_Developers.png";
import Ravenshaw from "../images/ravenshaw.png";
import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx"
import config from "../config";
var rootURL = config.rootURL;

const styles = () => ({
  pageSlideAnimation: {
    visibility: "hidden",
    visibility: "visible9" /*For old IE browsers IE6-8 */,
    "&::after": {
      content: "",
      display: "table",
      clear: "both"
    }
  },
  pageSlide: {
    visibility: "visible",
    animation: "pageSlide 1s"
  },
  "@keyframes pageSlide": {
    "0%": {
      opacity: "0",
      transform: "translateY(150px)"
    },
    "100%": {
      opacity: "1",
      transform: "translateY(0px)"
    }
  }
})

class AssociateGallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      serviceVideos: [],
      showContactUsModel: false
    };
  }

  componentDidMount = () => {
    this.setState({
      serviceVideos: [
        { title: "", id: "1IMIz76fPCO-TFTZunWDjt347gTVGwGpa", img: "" },
        { title: "", id: "1IPk_Q4JJgeBKEc_QLCLwcD3L2igXZAVJ", img: "" },
        { title: "", id: "1J28oUczDheGSzz9QOggBwxE00tv2JkF4", img: "" },
        { title: "", id: "1Ht_gm1Cq6Zu7R3skc1TCxj1zUjoXzVyC", img: "" }
      ]
    }, () => {
      var vidDefer = document.getElementsByTagName('iframe');
      for (var i = 0; i < vidDefer.length; i++) {
        if (vidDefer[i].getAttribute('data-src')) {
          vidDefer[i].setAttribute('src', vidDefer[i].getAttribute('data-src'));
        }
      }
    })
  }

  showContactUsModel = () => {
    this.setState({ showContactUsModel: true });
  };

  closeModel = () => {
    this.setState({
      showLoginModel: false,
      showContactUsModel: false,
      showProfile: false
    });
  };

  render() {
    const { classes } = this.props;
    const { serviceVideos } = this.state;
    let associateImages = [
      { title: "HT India Lab", url: "https://www.htindialabs.com", img: HT_India_Lab },
      { title: "Makeintern", url: "http://www.makeintern.com", img: Makeintern },
      { title: "Robokart", url: "https://robokart.com", img: Robokart },
      { title: "IIT Bhubaneswar", url: "http://www.iitbbs.ac.in", img: IIT_Bhubaneswar },
      { title: "Nfly", url: "http://nfly.in/", img: Nfly },
      { title: "Enginex", url: "https://enginex.in", img: Enginex }
    ]
    let communityImages = [
      { title: "Tech Bhubaneswar", url: "https://www.facebook.com/techbhubaneswar", img: TechBhubaneswar },
      { title: "GDG Bhubaneswar", url: "https://www.facebook.com/GDGBBSR", img: Google_Developers },
      { title: "DSC KIIT", url: "https://www.facebook.com/dsckiit", img: DSCKIIT },
      { title: "Utkal Techies Forum", url: "https://www.facebook.com/UtkalTechiesForum", img: Utkal_Techies },
      { title: "Emisha Community", url: "https://www.facebook.com/emishacommunity", img: Emisha },
      { title: "Ravenshaw University", url: "http://www.ravenshawuniversity.ac.in", img: Ravenshaw }
    ]
    let serviceVideo = []
    for (let i in serviceVideos) {
      serviceVideo.push(
        <Grid key={'serviceVideo' + i} item xs={12} sm={6} md={3}>
          <Card style={{ margin: 10, padding: '10px 10px 5px 10px' }}>
            <iframe title={ 'Trybotics Service Promotional Video '+i } width="100%" height="180px" src="" data-src={`https://drive.google.com/file/d/${serviceVideos[i].id}/preview`} frameBorder="0" gesture="media" allow="encrypted-media" allowFullScreen></iframe>
          </Card>
        </Grid>
      );
    }
    let associateImage = []
    for (let i in associateImages) {
      associateImage.push(
        <Grid key={'associateImage' + i} item xs={6} sm={4} md={2}>
          <a
            rel="noopener noreferrer nofollow"
            style={{ all: "initial" }}
            target="_blank"
            href={associateImages[i].url}

          >
            <Card style={{ margin: '10px' }}>
              <img
                width="100%"
                src={associateImages[i].img}
                alt={associateImages[i].title}
              />
              <Divider />
              <Typography
                style={{ padding: '10px', fontSize: "15px", fontWeight: 'bold' }}
                align="center"
                variant="h6"
                noWrap
              >
                {associateImages[i].title}
              </Typography>
            </Card>
          </a>
        </Grid>
      );
    }
    let communityImage = [];
    for (let i in communityImages) {
      communityImage.push(
        <Grid key={'communityImage' + i} item xs={6} sm={4} md={2}>
          <a
            rel="noopener noreferrer nofollow"
            style={{ all: "initial" }}
            target="_blank"
            href={communityImages[i].url}
          >
            <Card style={{ margin: '10px' }}>
              <img
                width="100%"
                src={communityImages[i].img}
                alt={communityImages[i].title}
              />
              <Divider />
              <Typography
                style={{ padding: '10px', fontSize: '15px', fontWeight: 'bold' }}
                align="center"
                variant="h6"
                noWrap
              >
                {communityImages[i].title}
              </Typography>
            </Card>
          </a>
        </Grid>
      );
    }
    return (
      <div>
        <Typography align="center" color="inherit" variant="h5">
          SERVICE VIDEOS
        </Typography>
        <Grid
          className={clsx(classes.pageSlideAnimation, classes.pageSlide)}
          style={{ margin: 'auto', marginTop: '15px' }}
          justify="center"
          container
          spacing={0}
        >
          {serviceVideo}
          <center style={{ marginBottom: '10px', marginTop: '20px' }}>
            <a style={{ all: "initial" }} href={rootURL + "/service"}>
              <Button variant="contained" size="large" color="primary" style={{paddingTop: 11, paddingBottom: 11}} >
                Know More
            </Button>
            </a>
          </center>
        </Grid>
        <Divider style={{ marginBottom: '20px', marginTop: '20px' }} light />
        <Typography align="center" color="inherit" variant="h5">
          OUR ASSOCIATED COMPANIES
        </Typography>
        <Grid
          className={clsx(classes.pageSlideAnimation, classes.pageSlide)}
          style={{ margin: 'auto', marginTop: '15px' }}
          justify="center"
          container
          spacing={0}
        >
          {associateImage}
          <center style={{ marginBottom: '10px', marginTop: '20px' }}
            onClick={this.showContactUsModel}>
            <Button variant="contained" size="large" color="primary" style={{paddingTop: 11, paddingBottom: 11}} >
              Associate With Us
            </Button>
          </center>
        </Grid>
        <Divider style={{ marginBottom: '20px', marginTop: '20px' }} light />
        <Typography align="center" color="inherit" variant="h5">
          OUR ASSOCIATED COMMUNITIES
        </Typography>
        <Grid
          className={clsx(classes.pageSlideAnimation, classes.pageSlide)}
          style={{ margin: 'auto', marginTop: '15px' }}
          justify="center"
          container
          spacing={0}
        >
          {communityImage}
          <center style={{ marginBottom: '10px', marginTop: '20px' }} onClick={this.showContactUsModel}>
            <Button variant="contained" size="large" color="primary" style={{paddingTop: 11, paddingBottom: 11}} >
              Associate With Us
            </Button>
          </center>
        </Grid>
        <ContactUs
          open={this.state.showContactUsModel}
          onClose={this.closeModel}
        />
      </div>
    );
  }
}

export default (withStyles(styles)(AssociateGallery));
