import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Plugins } from '@capacitor/core';
import RemoveContent from "./RemoveContent";
import config from "../config";
var rootURL = config.rootURL;
const { Device } = Plugins;

const styles = () => ({
  footer: {
    textAlign: "center",
    width: "100%",
    height: "110px",
    color: "#a2a2a2"
  },
  fMenu: {
    display: "block",
    width: "100%",
    paddingLeft: "0",
    listStyle: "none",
    marginTop: "8px",
    "& li": {
      display: "inline-block",
      paddingLeft: "5px",
      paddingRight: "5px",
      "& a": {
        color: "#a2a2a2",
        textDecoration: "none",
        "&:hover": {
          color: "#777"
        }
      }
    }
  }
});

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      platform: 'web',
      showRemoveContent: false
    }
  }

  componentDidMount = () => {
    Device.getInfo().then(info => {
      console.log(info);
      this.setState({ platform: info.platform })
    }).catch(error => {
      console.log(error);
    })
  }

  closeModel = () => {
    this.setState({ showRemoveContent: false });
  };

  render() {
    const { classes } = this.props;
    return (
      <footer className={classes.footer} style={{ clear: "both", float: "left" }}>
        <div style={{ paddingTop: "35px", fontSize: "14px" }}>
          Copyright &copy; 2021 Trybotics
            <ul className={classes.fMenu}>
            <li>
              <a href={rootURL} target="_blank">Home</a>
            </li>
            {this.state.platform == "web" &&
              <li>
                <a href={rootURL + "/covid19-india/"} target="_blank">Covid19 India</a>
              </li>
            }
            {this.state.platform == "web" &&
              <li>
                <a href={rootURL + "/service"} target="_blank">Services</a>
              </li>
            }
            <li>
              <a href={"/project"} target="_blank">
                Tech Projects
                </a>
            </li>
            <li>
              <a href={"/internship"} target="_blank">
                Internships
                </a>
            </li>
            {this.state.platform == "web" &&
              <li>
                <a href={rootURL + "/resume-builder/"} target="_blank">Resume Builder</a>
              </li>
            }
            <li>
              <a href={"/blog"} target="_blank">
                Tech Blogs
                </a>
            </li>
            <li>
              <a href={"/ad"} target="_blank">
                Ads
                </a>
            </li>
            <li>
              <a href={"/buy"} target="_blank">
                Buy Electronic Parts
                </a>
            </li>
            <li>
              <a href={"/Youtube"} target="_blank">
                Tech Videos
                </a>
            </li>
            <li>
              <a href={"/mini-quadcopter"} target="_blank">
                Make Mini Quadcopter
                </a>
            </li>
            <li>
              <a href={"/about"} target="_blank">
                About Us
                </a>
            </li>
            <li>
              <a href={"/faq"} target="_blank">
                FAQ
                </a>
            </li>
            <li style={{ cursor: "pointer" }} onClick={() => {
              this.setState({ showRemoveContent: true });
            }}>
              Remove Content
            </li>
            <li>
              <a href={"/privacy"} target="_blank">
                Privacy
                </a>
            </li>
            <li>
              <a href={"/terms"} target="_blank">
                Terms
                </a>
            </li>
            <li>
              <a href={"/sitemap.xml"} target="_blank">
                Sitemap
                </a>
            </li>
          </ul>
        </div>
        <RemoveContent open={this.state.showRemoveContent} onClose={this.closeModel} />
      </footer>
    );
  }
}

export default withStyles(styles)(Footer);
