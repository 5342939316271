import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Divider from "@material-ui/core/Divider";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import AddIcon from "@material-ui/icons/Add";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import LinkIcon from "@material-ui/icons/Link";
import CloseIcon from "@material-ui/icons/Close";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { connect } from "react-redux";
import { addYoutube, editYoutube } from "../actions/Youtube";
import { addAffiliate, editAffiliate } from "../actions/Affiliate";
import withStyles from "@material-ui/core/styles/withStyles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
  formHeader: {
    textAlign: "center",
    fontSize: "20px",
    backgroundColor: "#2196f3",
    color: "#fff",
    padding: "15px 12px",
    textTransform: "uppercase"
  },
  closeIcon: {
    cursor: "pointer",
    color: "#fff",
    fontSize: "18px",
    fontWeight: "bold",
    float: "right"
  },
  buttonOutlineSelected: {
    borderRadius: "unset !important",
    border: "1px solid  #2196f3 !important"
  },
  buttonOutlined: {
    borderRadius: "unset !important",
    border: "1px solid #999 !important"
  }
});

const initialState = {
  Youtube: true,
  videoId: null,
  youtubeTitle: null,
  youtubeDescription: null,
  Affiliate: false,
  affiliateTitle: null,
  affiliateDescription: null,
  imageUrl: null,
  category: "Robotics",
  subCategory: "Quadcopter",
  affiliates: [
    {
      brandName: "Amazon",
      price: null,
      affiliateLink: null
    }
  ],
  showAffiliate: true,
  showYoutube: false,
  error: []
};

class Post extends React.Component {
  constructor(props) {
    super(props);

    if (this.props.data) {
      if (this.props.type == "youtube") {
        this.state = {
          showYoutube: true,
          youtubeTitle: this.props.data.title,
          youtubeDescription: this.props.data.description,
          videoId: this.props.data.videoId,
          category: "Robotics",
          subCategory: "Quadcopter",
          error: []
        };
      } else {
        this.state = {
          showAffiliate: true,
          affiliateTitle: this.props.data.title,
          affiliateDescription: this.props.data.description,
          imageUrl: this.props.data.imageUrl,
          category: "Robotics",
          subCategory: "Quadcopter",
          affiliates: this.props.data.affiliate,
          error: []
        };
      }
    } else {
      this.state = initialState;
    }
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handlePost = () => {
    this.state.error = [];

    if (this.state.showAffiliate) {
      if (!this.state.affiliateTitle) {
        this.state.error["affiliateTitle"] = "Required";
      }
      if (!this.state.affiliateDescription) {
        this.state.error["affiliateDescription"] = "Required";
      }
      if (!this.state.imageUrl) {
        this.state.error["imageUrl"] = "Required";
      }
      for (let i in this.state.affiliates) {
        if (!this.state.affiliates[i].price) {
          this.state.error["price" + i] = "Required";
        }
        if (!this.state.affiliates[i].affiliateLink) {
          this.state.error["affiliateLink" + i] = "Required";
        }
      }
      if (Object.keys(this.state.error).length == 0) {
        this.AddAffiliate();
      }
    }

    if (this.state.showYoutube) {
      if (!this.state.youtubeTitle) {
        this.state.error["youtubeTitle"] = "Required";
      }
      if (!this.state.youtubeDescription) {
        this.state.error["youtubeDescription"] = "Required";
      }
      if (!this.state.videoId) {
        this.state.error["videoId"] = "Required";
      }
      if (Object.keys(this.state.error).length == 0) {
        this.AddYoutube();
      }
    }

    this.forceUpdate();
  };

  AddAffiliate = () => {
    var data = {
      imageUrl: this.state.imageUrl,
      title: this.state.affiliateTitle,
      description: this.state.affiliateDescription,
      category: this.state.category,
      subCategory: this.state.subCategory,
      affiliate: this.state.affiliates
    };

    if (this.props.data) {
      var id = this.props.data.id;
      this.props.editAffiliate(data, id).then(() => {
        console.log("Affiliate Edited");
        this.setState({ error: [] });
        this.props.onClose();
      });
    } else {
      this.props.addAffiliate(data).then(() => {
        this.setState({
          imageUrl: "",
          affiliateTitle: "",
          affiliateDescription: "",
          affiliates: [{ brandName: "Amazon", price: "", affiliateLink: "" }]
        });
      });
    }
  };

  AddYoutube = () => {
    var data = {
      videoId: this.state.videoId,
      title: this.state.youtubeTitle,
      description: this.state.youtubeDescription
    };

    if (this.props.data) {
      var id = this.props.data.id;
      this.props.editYoutube(data, id).then(() => {
        console.log("Youtube Edited");
        this.setState({ error: [] });
        this.props.onClose();
      });
    } else {
      this.props.addYoutube(data).then(() => {
        console.log("Youtube added");
        this.setState({
          videoId: "",
          youtubeTitle: "",
          youtubeDescription: ""
        });
      });
    }
  };

  render() {
    const { fullScreen } = this.props;
    const { classes } = this.props;

    var affiliates = [],
      i;
    for (i in this.state.affiliates) {
      affiliates.push(
        <div key={'affiliate'+i}>
          <Select
            style={{ marginTop: "30px" }}
            value={this.state.affiliates[i].brandName}
            onChange={e => {
              this.state.affiliates[
                e.target.name.replace(/[^0-9]+/gi, "")
              ].brandName = e.target.value;
              this.forceUpdate();
            }}
            fullWidth
            inputProps={{
              name: `brandName${i}`,
              id: `brandName${i}`
            }}
          >
            <MenuItem value={"Amazon"}>Amazon</MenuItem>
            <MenuItem value={"Flipkart"}>Flipkart</MenuItem>
            <MenuItem value={"Ebay"}>Ebay</MenuItem>
          </Select>
          <TextField
            id={"price" + i}
            label="Enter Affiliate Amount"
            error={this.state.error["price" + i]}
            helperText={this.state.error["price" + i]}
            name={"price" + i}
            value={this.state.affiliates[i].price}
            onChange={e => {
              this.state.affiliates[
                e.target.name.replace(/[^0-9]+/gi, "")
              ].price = e.target.value;
              this.forceUpdate();
            }}
            type="text"
            margin="normal"
            fullWidth
          />
          <TextField
            id={"affiliateLink" + i}
            label="Enter Affiliate URL"
            error={this.state.error["affiliateLink" + i]}
            helperText={this.state.error["affiliateLink" + i]}
            name={"affiliateLink" + i}
            value={this.state.affiliates[i].affiliateLink}
            onChange={e => {
              this.state.affiliates[
                e.target.name.replace(/[^0-9]+/gi, "")
              ].affiliateLink = e.target.value;
              this.forceUpdate();
            }}
            type="text"
            margin="normal"
            fullWidth
          />
        </div>
      );
    }
    return (
      <div>
        <Dialog
          scroll="body"
          maxWidth="xs"
          open={this.props.open}
          onClose={this.props.onClose}
          TransitionComponent={Transition}
          fullScreen={fullScreen}
        >
          <div className={classes.formHeader}>
            {this.props.type == "youtube"
              ? "Edit Youtube"
              : this.props.type == "affiliate"
                ? " Edit Affiliate"
                : "Post Affiliate / Youtube"}
            {this.props.onClose &&
              (1440 <= 380 ? (
                <KeyboardBackspaceIcon
                  className={classes.closeIcon}
                  style={{ float: "left" }}
                  onClick={this.props.onClose}
                />
              ) : (
                  <CloseIcon
                    className={classes.closeIcon}
                    style={{ float: "right" }}
                    style={{ width: "25px", height: "25px" }}
                    onClick={this.props.onClose}
                  />
                ))}
          </div>
          <DialogContent>
            {!this.props.data && (
              <center style={{ marginTop: "20px" }}>
                <Button
                  className={
                    this.state.showAffiliate
                      ? `${classes.buttonOutlineSelected}`
                      : `${classes.buttonOutlined}`
                  }
                  onClick={() => {
                    this.setState({
                      showAffiliate: true,
                      showYoutube: false,
                      error: []
                    });
                  }}
                  variant="outlined"
                  color={this.state.showAffiliate ? "primary" : "default"}
                >
                  Affiliate &nbsp;&nbsp;
                  <LinkIcon />
                </Button>
                <Button
                  className={
                    this.state.showYoutube
                      ? `${classes.buttonOutlineSelected}`
                      : `${classes.buttonOutlined}`
                  }
                  onClick={() => {
                    this.setState({
                      showAffiliate: false,
                      showYoutube: true,
                      error: []
                    });
                  }}
                  variant="outlined"
                  color={this.state.showYoutube ? "primary" : "default"}
                >
                  Youtube &nbsp;&nbsp;
                  <PlayCircleFilledIcon />
                </Button>
              </center>
            )}

            {this.state.showAffiliate && (
              <div>
                <TextField
                  id="affiliateTitle"
                  label="Title Of Your Affiliate"
                  error={this.state.error["affiliateTitle"]}
                  helperText={this.state.error["affiliateTitle"]}
                  name="affiliateTitle"
                  value={this.state.affiliateTitle}
                  onChange={this.handleChange("affiliateTitle")}
                  type="text"
                  margin="normal"
                  fullWidth
                  autoFocus = { true }
                />
                <TextField
                  id="affiliateDescription"
                  label="Description Of Your Affiliate"
                  error={this.state.error["affiliateDescription"]}
                  helperText={this.state.error["affiliateDescription"]}
                  name="affiliateDescription"
                  value={this.state.affiliateDescription}
                  onChange={this.handleChange("affiliateDescription")}
                  type="text"
                  margin="normal"
                  fullWidth
                  multiline
                  rowsMax="10"
                  type="text"
                />
                <TextField
                  id="imageUrl"
                  label="Enter Image URL"
                  error={this.state.error["imageUrl"]}
                  helperText={this.state.error["imageUrl"]}
                  name="imageUrl"
                  value={this.state.imageUrl}
                  onChange={this.handleChange("imageUrl")}
                  type="text"
                  margin="normal"
                  fullWidth
                />
                <Select
                  style={{ marginTop: "20px" }}
                  value={this.state.category}
                  onChange={this.handleChange("category")}
                  fullWidth
                  inputProps={{
                    name: `category`,
                    id: `category`
                  }}
                >
                  <MenuItem value={"Robotics"}>Robotics</MenuItem>
                  <MenuItem value={"Mobile"}>Mobile</MenuItem>
                </Select>
                <Select
                  style={{ marginTop: "20px", marginBottom: "5px" }}
                  value={this.state.subCategory}
                  onChange={this.handleChange("subCategory")}
                  fullWidth
                  inputProps={{
                    name: `subCategory`,
                    id: `subCategory`
                  }}
                >
                  <MenuItem value={"Quadcopter"}>Quadcopter</MenuItem>
                  <MenuItem value={"Mini Quadcopter"}>Mini Quadcopter</MenuItem>
                </Select>
                <Divider style={{ marginTop: "30px" }} light />
                <Button
                  onClick={() => {
                    this.state.affiliates.push({
                      brandName: "Amazon",
                      price: null,
                      affiliateLink: null
                    });
                    this.forceUpdate();
                  }}
                  className={classes.buttonOutlineSelected}
                  style={{ marginTop: "30px" }}
                  variant="outlined"
                  color="primary"
                  fullWidth
                >
                  Add More &nbsp;
                  <AddIcon />
                </Button>
                {affiliates}
              </div>
            )}
            {this.state.showYoutube && (
              <div>
                <TextField
                  id="youtubeTitle"
                  label="Title Of Your Youtube"
                  error={this.state.error["youtubeTitle"]}
                  helperText={this.state.error["youtubeTitle"]}
                  name="youtubeTitle"
                  value={this.state.youtubeTitle}
                  onChange={this.handleChange("youtubeTitle")}
                  type="text"
                  margin="normal"
                  fullWidth
                  autoFocus = { true }
                />
                <TextField
                  id="youtubeDescription"
                  label="Description Of Your Youtube"
                  error={this.state.error["youtubeDescription"]}
                  helperText={this.state.error["youtubeDescription"]}
                  name="youtubeDescription"
                  value={this.state.youtubeDescription}
                  onChange={this.handleChange("youtubeDescription")}
                  type="text"
                  margin="normal"
                  fullWidth
                  multiline
                  rowsMax="10"
                  type="text"
                />
                <TextField
                  id="videoId"
                  label="Enter Youtube Video ID"
                  error={this.state.error["videoId"]}
                  helperText={this.state.error["videoId"]}
                  name="videoId"
                  value={this.state.videoId}
                  onChange={this.handleChange("videoId")}
                  type="text"
                  margin="normal"
                  fullWidth
                />
              </div>
            )}
          </DialogContent>
          <Divider light style={{ marginTop: "10px" }} />
          <div style={{ paddingBottom: "40px", paddingTop: "5px" }}>
            <div style={{ paddingRight: "15px"}}>
              <Button
                style={{ float: "right" }}
                onClick={this.handlePost}
                color="primary"
              >
                Post
            </Button>
              <Button style={{ float: "right" }} onClick={this.props.onClose}>
                Cancel
            </Button>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.Home.login.userDetails
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addYoutube: data => {
      return dispatch(addYoutube(data));
    },
    editYoutube: (data, id) => {
      return dispatch(editYoutube(data, id));
    },
    addAffiliate: data => {
      return dispatch(addAffiliate(data));
    },
    editAffiliate: (data, id) => {
      return dispatch(editAffiliate(data, id));
    }
  };
};

Post.propTypes = {
  fullScreen: PropTypes.bool.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withMobileDialog()(Post)));
