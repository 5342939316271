import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import Zoom from "@material-ui/core/Zoom";
import StackGrid from "react-stack-grid";
import { Redirect } from "react-router-dom"
import { connect } from "react-redux";
import { getYoutubeData, getYoutubeDetails } from "../actions/Youtube";
import InfiniteScroll from "../components/InfiniteScroll";
import BlogCardView from "../components/BlogCardView";

var pageNo = 1;

function Transition(props) {
  return <Zoom direction="up" {...props} />;
}

class Youtube extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      youtube: null,
      openModel: true
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.location !== this.props.location &&
      nextProps.location.search
    ) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      this.props.getYoutubeData(1, nextProps.location.search, true);
    }
  }

  componentDidMount = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    if (!this.props.match.params.id) {
      document.title = "Youtube | Trybotics";
    }
    if (this.props.youtubes.length == 0) {
      this.props.getYoutubeData(1, this.props.location.search, false);
    } else if (this.props.location.search) {
      this.props.getYoutubeData(1, this.props.location.search, true);
    }
  };

  gridSet = () => {
    if (this.grid) {
      this.grid.updateLayout();
    }
  };

  handleLoadMore = page => {
    pageNo = pageNo + 1;
    this.props.getYoutubeData(pageNo, this.props.location.search, false);
  };

  closeModel = () => {
    this.setState({ openModel: false, youtube: null });
  };

  getYoutube = id => {
    let youtube = this.props.youtubes.find(youtube => {
      return youtube.id == id;
    })
    if (youtube) {
      this.setState({ youtube: youtube })
    } else {
      this.props.getYoutubeDetails(id)
    }
  };

  render() {
    if (this.props.match.params.id && !this.state.openModel) {
      this.state.openModel = true
      return <Redirect to={'/youtube'} />;
    }
    let youtubes = [];
    let counter = 0;
    for (var i in this.props.youtubes) {
      counter = counter + 1;
      youtubes.push(
        <BlogCardView
          location={this.props.location}
          key={"youtube" + counter}
          gridSet={this.gridSet}
          data={this.props.youtubes[i]}
          type="youtube"
          noCardHeader
          link
        />
      );
    }
    let youtube = null;
    if (this.props.match.params.id) {
      let youtubeBody = null;
      youtube = this.props.youtubes.find(
        youtube => youtube.id == this.props.match.params.id
      );
      if (youtube) {
        // document.title = youtube.title + " | Trybotics";
        // setMetaContentByName("description", "content", youtube.description);
        youtubeBody = (
          <BlogCardView
            location={this.props.location}
            gridSet={this.gridSet}
            data={youtube}
            type="youtube"
          />
        );
      } else {
        if (this.state.youtube) {
          youtubeBody = (
            <BlogCardView
              location={this.props.location}
              gridSet={this.gridSet}
              data={this.state.youtube}
              type="youtube"
            />
          );
        } else {
          this.getYoutube(this.props.match.params.id);
          youtubeBody = (
            <center>
              <center className="p-10">
                <CircularProgress size={50} />
              </center>
            </center>
          );
        }
      }
      youtube = (
        <Dialog
          scroll="body"
          maxWidth="xs"
          open={this.state.openModel}
          onClose={this.closeModel}
          TransitionComponent={Transition}
        >
          {youtubeBody}
        </Dialog>
      );
    }
    return (
      <InfiniteScroll
        initialLoad={false}
        pageStart={1}
        loadMore={page => {
          this.handleLoadMore(page);
        }}
        hasMore={this.props.youtubeHasMore}
        loader={
          <center className="m-t-10">
            <CircularProgress size={50} />
          </center>
        }
      >
        {youtube}
        <StackGrid
          className="stackGrid"
          monitorImagesLoaded={false}
          gridRef={grid => (this.grid = grid)}
          columnWidth={350}
          gutterWidth={15}
          gutterHeight={15}
          enableSSR={true}
          duration={0}
        >
          {youtubes}
        </StackGrid>
      </InfiniteScroll>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.Home.login.userDetails,
    youtubes: state.youtubeState.youtubes,
    youtubeHasMore: state.youtubeState.youtubeHasMore
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getYoutubeData: (page, search, refresh) => {
      return dispatch(getYoutubeData(page, search, refresh));
    },
    getYoutubeDetails: (id) => {
      return dispatch(getYoutubeDetails(id));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Youtube);
