import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import EventComp from '../components/EventComp'
import Container from "@material-ui/core/Container";
import clsx from 'clsx';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
  pageSlideAnimation: {
    visibility: "hidden",
    visibility: "visible9" /*For old IE browsers IE6-8 */,
    "&::after": {
      content: "",
      display: "table",
      clear: "both"
    }
  },
  pageSlide: {
    visibility: "visible",
    animation: "pageSlide 1s"
  },
})


class Event extends React.Component {

  constructor(props) {
    super(props)
  }

  componentDidMount = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    document.title = "Training | Trybotics";
  };

  render() {
    const { classes } = this.props
    this.props.location.pathname = this.props.location.pathname.toLowerCase()
    return (
      <Container disableGutters={true} maxWidth="lg" className={clsx(classes.pageSlideAnimation, classes.pageSlide)}>
        <EventComp
          eventType={this.props.location.pathname.split('/')[1]}
          subEventType={this.props.location.pathname.split('/')[2]}
        />
      </Container>
    )
  }
}

export default withStyles(styles, { withTheme: true })(Event)