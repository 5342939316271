import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import StackGrid from "react-stack-grid";
// import Card from "@material-ui/core/Card";
// import Divider from "@material-ui/core/Divider";
// import Button from "@material-ui/core/Button";
// import TextField from "@material-ui/core/TextField";
// import ImageIcon from "@material-ui/icons/Image";
// import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
// import LinkIcon from "@material-ui/icons/Link";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { getBlogData } from "../actions/Blog";
import { getParameterByName } from "../utils/Helper"
import InfiniteScroll from "../components/InfiniteScroll";
import BlogCardView from "../components/BlogCardView";
import BlogPost from "../components/BlogPost";
import Login from "../components/Login";
var pageNo = 1;

const styles = theme => ({
  formHeader: {
    textAlign: "center",
    fontSize: "20px",
    backgroundColor: "#2196f3",
    color: "#fff",
    padding: "15px 12px",
    textTransform: "uppercase"
  },
  buttonOutlined: {
    borderRadius: "unset !important",
    border: "1px solid rgb(153, 153, 153) !important"
  }
});

class Blog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLogin: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.location !== this.props.location &&
      nextProps.location.search
    ) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      this.props.getBlogData(1, nextProps.location.search, true);
    }
  }

  componentDidMount = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    if (!this.props.match.params.id) {
      document.title = "Blog | Trybotics"
    }
    if (this.props.blogs.length == 0) {
      this.props.getBlogData(1, this.props.location.search, false);
    } else if (this.props.location.search && getParameterByName('search', this.props.location.search)) {
      this.props.getBlogData(1, this.props.location.search, true);
    }
  };

  gridSet = () => {
    if (this.grid) {
      this.grid.updateLayout();
    }
  };

  handleLoadMore = page => {
    pageNo = pageNo + 1;
    this.props.getBlogData(pageNo, this.props.location.search, false);
  };

  closeModel = () => {
    this.setState({ showLogin: false });
  };

  render() {
    const { classes } = this.props;
    let blogs = [];
    let counter = 0;
    // blogs.push(
    //   <Card
    //     key={"blog" + counter}
    //     onClick={() => {
    //       this.props.user.isLogin
    //         ? this.props.history.push('/add-blog')
    //         : this.setState({ showLogin: true });
    //     }}
    //   >
    //     <div className={classes.formHeader}>Share Your Blogs </div>
    //     <div
    //       style={{
    //         paddingLeft: "20px",
    //         paddingRight: "20px",
    //         paddingBottom: "20px"
    //       }}
    //     >
    //       <TextField
    //         id="blogTitle"
    //         label="Title of your blog"
    //         name="blogTitle"
    //         type="text"
    //         margin="normal"
    //         fullWidth
    //         disabled
    //       />
    //       <TextField
    //         id="blogDescription"
    //         label="Description of your blog"
    //         name="blogDescription"
    //         type="text"
    //         margin="normal"
    //         fullWidth
    //         disabled
    //       />
    //       <center style={{ marginTop: "20px" }}>
    //         <Button
    //           className={classes.buttonOutlined}
    //           variant="outlined"
    //           style={{ color: "#777", fontWeight: "600" }}
    //         >
    //           Image &nbsp;&nbsp;
    //           <ImageIcon />
    //         </Button>
    //         <Button
    //           className={classes.buttonOutlined}
    //           variant="outlined"
    //           style={{ color: "#777", fontWeight: "600" }}
    //         >
    //           Video &nbsp;&nbsp;
    //           <PlayCircleFilledIcon />
    //         </Button>
    //         <Button
    //           className={classes.buttonOutlined}
    //           variant="outlined"
    //           style={{ color: "#777", fontWeight: "600" }}
    //         >
    //           Link &nbsp;&nbsp;
    //           <LinkIcon />
    //         </Button>
    //       </center>
    //     </div>
    //     <Divider light />
    //     <div style={{ paddingBottom: "40px", paddingTop: "5px" }}>
    //       <div style={{ paddingRight: "15px" }}>
    //         <Button
    //           style={{ float: "right", fontWeight: "600" }}
    //           color="primary"
    //         >
    //           Post
    //         </Button>
    //         <Button style={{ float: "right", fontWeight: "600" }}>
    //           Cancel
    //         </Button>
    //       </div>
    //     </div>
    //   </Card>
    // );

    for (var i in this.props.blogs) {
      counter = counter + 1;
      let blog = { ...this.props.blogs[i] }
      if (blog.author && blog.author.postedBy && blog.author.postedBy.userId) {
        blog.author = blog.author.postedBy.userId
      } else {
        blog.author = null
      }
      blogs.push(
        <BlogCardView
          location={this.props.location}
          history={this.props.history}
          key={"blog" + counter}
          gridSet={this.gridSet}
          data={blog}
          type="blog"
          link
        />
      );
    }

    return (
      <InfiniteScroll
        initialLoad={false}
        pageStart={1}
        loadMore={page => {
          this.handleLoadMore(page);
        }}
        hasMore={this.props.blogHasMore}
        loader={
          <center style={{ marginTop: "10px" }}>
            <CircularProgress size={50} />
          </center>
        }
      >
        <Login closeModel={this.closeModel} open={this.state.showLogin} />
        <StackGrid
          className="stackGrid"
          monitorImagesLoaded={false}
          gridRef={grid => (this.grid = grid)}
          columnWidth={350}
          gutterWidth={15}
          gutterHeight={15}
          enableSSR={true}
          duration={0}
        >
          {blogs}
        </StackGrid>
      </InfiniteScroll>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.Home.login.userDetails,
    blogs: state.blogState.blogs,
    blogHasMore: state.blogState.blogHasMore
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getBlogData: (page, search, refresh) => {
      return dispatch(getBlogData(page, search, refresh));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Blog));
