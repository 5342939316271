import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Signup from "./Signup";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import { login, socialLogin, signup, showToast } from "../actions/Home";
import { getCanvasImageURL } from "../utils/Helper";
import CloseIcon from "@material-ui/icons/Close";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

// Social Logins
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import LinkedinLogin from "./SocialLogins/LinkedinLogin";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

import config from "../config";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
  formHeader: {
    textAlign: "center",
    fontSize: "20px",
    backgroundColor: "#2196f3",
    color: "#fff",
    padding: "15px 12px",
    textTransform: "uppercase"
  },
  closeIcon: {
    cursor: "pointer",
    color: "#fff",
    fontSize: "18px",
    fontWeight: "bold",
    float: "right"
  },
  clickHere: {
    cursor: "pointer",
    textDecorationLine: "underline",
    color: "#039be5",
    // textDecoration: "none",
    webkitTapHighlightColor: "transparent"
  },
  googleButton: {
    backgroundColor: "#db4437 !important",
    color: "#fff !important",
    marginRight: "5px !important",
    width: 173
  },
  facebookButton: {
    backgroundColor: "#4267b2 !important",
    color: "#fff !important",
    marginTop: 10,
    padding: 10,
    width: 173,
    border: 'unset',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px'
  },

  linkedInButton: {
    backgroundColor: "#0077b5 !important",
    color: "#fff !important",
    marginTop: "8px !important"
  }
});

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSignup: false,
      username: null,
      loginPassword: null,
      name: null,
      email: null,
      password: null,
      showSignup: false,
      error: [],
      showNativeLogin: false
    };
  }

  componentDidMount = () => {
    this.setState({ showNativeLogin: window.plugins ? true : false })
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleLogin = () => {
    this.state.error = [];
    if (!this.state.username) {
      this.state.error["username"] = "Required";
    }
    if (!this.state.loginPassword) {
      this.state.error["loginPassword"] = "Required";
    }
    this.forceUpdate();
    if (Object.keys(this.state.error).length == 0) {
      this.Login();
    }
  };

  Login = () => {
    var data = {
      email: this.state.username,
      password: this.state.loginPassword
    };
    this.props.login(data).then(() => {
      if (this.props.user.id) {
        localStorage.setItem("authToken", this.props.user.authToken);
        this.props.closeModel();
      }
    });
  };

  handleSignup = () => {
    this.state.error = [];
    if (!this.state.name) {
      this.state.error["name"] = "Required";
    }
    if (!this.state.phone) {
      this.state.error["phone"] = "Required";
    }
    if (!this.state.email) {
      this.state.error["email"] = "Required";
    }
    if (!this.state.password) {
      this.state.error["password"] = "Required";
    }
    this.forceUpdate();
    if (Object.keys(this.state.error).length == 0) {
      this.Signup();
    }
  };

  Signup = () => {
    var data = {
      name: this.state.name,
      phone: this.state.phone,
      email: this.state.email,
      password: this.state.password,
      imageUrl: getCanvasImageURL(this.state.name)
    };
    this.props.signup(data).then(() => {
      if (this.props.user.id) {
        localStorage.setItem("authToken", this.props.user.authToken);
        this.props.closeModel();
      }
    });
  };

  showSignup = () => {
    this.setState({ showSignup: true });
  };

  closeSignup = () => {
    this.setState({ showSignup: false });
  };

  googleLogin = response => {
    if (response.profileObj) {
      var data = {
        name: response.profileObj.name,
        email: response.profileObj.email,
        socialId: response.profileObj.googleId,
        imageUrl: response.profileObj.imageUrl,
        type: "googleId"
      };
      this.props.socialLogin(data).then(() => {
        if (this.props.user.id) {
          localStorage.setItem("authToken", this.props.user.authToken);
          this.props.closeModel();
        }
      });
    }
  };

  facebookLogin = response => {
    var data = {
      name: response.name,
      socialId: response.id,
      imageUrl: response.picture.data.url,
      type: "facebookId"
    }
    if (response.email) {
      data['email'] = response.email
    }
    this.props.socialLogin(data).then(() => {
      if (this.props.user.id) {
        localStorage.setItem("authToken", this.props.user.authToken);
        this.props.closeModel();
      }
    });
  };

  nativeLogin = () => {
    window.plugins.googleplus.login(
      {
        'scopes': '', // optional, space-separated list of scopes, If not included or empty, defaults to `profile` and `email`.
        'webClientId': config.googleClientId, // optional clientId of your Web application from Credentials settings of your project - On Android, this MUST be included to get an idToken. On iOS, it is not required.
        'offline': true // optional, but requires the webClientId - if set to true the plugin will also return a serverAuthCode, which can be used to grant offline access to a non-Google server
      }, (obj) => {
        console.log('login', JSON.stringify(obj)); // do something useful instead of alerting
        if (obj) {
          var data = {
            name: obj.displayName,
            email: obj.email,
            socialId: obj.userId,
            imageUrl: obj.imageUrl,
            type: "googleId"
          };
          this.props.socialLogin(data).then(() => {
            if (this.props.user.id) {
              localStorage.setItem("authToken", this.props.user.authToken);
              this.props.closeModel();
            }
          });
          window.plugins.googleplus.logout(
            (msg) => {
              console.log('logout', msg);
            }
          );
        } else {
          this.props.showToast('Something went wrong...')
        }
      }, (msg) => {
        this.props.showToast('Something went wrong...')
        console.log('error', msg);
      }
    )
  }

  render() {
    const { fullScreen } = this.props;
    const { classes } = this.props;

    return (
      <div>
        <Dialog
          scroll="body"
          maxWidth="xs"
          open={this.props.open}
          onClose={this.props.closeModel}
          TransitionComponent={Transition}
          // fullScreen={fullScreen}
          style={{ fontFamily: "Roboto, sans-serif" }}
        >
          <div className={classes.formHeader}>
            {this.state.showSignup ? "Sign Up" : "Log In"}{" "}
            {this.props.closeModel &&
              (1440 <= 380 && false ? (
                <KeyboardBackspaceIcon
                  className={classes.closeIcon}
                  style={{ float: "left" }}
                  onClick={this.props.closeModel}
                />
              ) : (
                  <CloseIcon
                    className={classes.closeIcon}
                    style={{ float: "right" }}
                    onClick={this.props.closeModel}
                    style={{ width: "25px", height: "25px" }}
                  />
                ))}
          </div>
          <DialogContent style={{ marginBottom: "15px" }}>
            {this.state.showSignup && (
              <Signup
                name={this.state.name}
                phone={this.state.phone}
                email={this.state.email}
                password={this.state.password}
                // className={this.state.showSignup ? null : "hidden"}
                error={this.state.error}
                handleChange={this.handleChange}
                closeSignup={this.closeSignup}
              />
            )}
            {!this.state.showSignup && (
              <div>
                <TextField
                  label="Email Id"
                  error={this.state.error["username"]}
                  helperText={this.state.error["username"]}
                  name="username"
                  value={this.state.username}
                  onChange={this.handleChange("username")}
                  type="email"
                  autoComplete="current-email"
                  margin="normal"
                  autoFocus={this.props.closeModel}
                  fullWidth
                />
                <TextField
                  label="Your password"
                  error={this.state.error["loginPassword"]}
                  helperText={this.state.error["loginPassword"]}
                  name="loginPassword"
                  value={this.state.loginPassword}
                  onChange={this.handleChange("loginPassword")}
                  autoComplete="current-password"
                  type="password"
                  margin="normal"
                  fullWidth
                />
              </div>
            )}
            <div>
              <Button
                style={{ marginTop: "25px" }}
                fullWidth
                variant="contained"
                color="primary"
                onClick={
                  this.state.showSignup ? this.handleSignup : this.handleLogin
                }
              >
                {this.state.showSignup ? "Sign Up" : "Log In"}
              </Button>

              <p style={{ marginTop: "30px" }}>
                <a className={classes.clickHere}>Forget Password?</a>
                <a
                  className={classes.clickHere}
                  style={{ float: "right" }}
                  onClick={
                    this.state.showSignup ? this.closeSignup : this.showSignup
                  }
                >
                  {this.state.showSignup ? "Log In" : "Sign Up"}
                </a>
              </p>
            </div>
            <center>
              <p style={{ color: "#666" }}>Or Login With Social Account</p>
            </center>
            {this.state.showNativeLogin ?
              <center>
                <Button style={{ backgroundColor: '#db4437', color: '#fff' }} variant="raised" onClick={this.nativeLogin}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" fill="#ffffff" viewBox="0 0 50 50"><g><path d="M 25.996094 48 C 13.3125 48 2.992188 37.683594 2.992188 25 C 2.992188 12.316406 13.3125 2 25.996094 2 C 31.742188 2 37.242188 4.128906 41.488281 7.996094 L 42.261719 8.703125 L 34.675781 16.289063 L 33.972656 15.6875 C 31.746094 13.78125 28.914063 12.730469 25.996094 12.730469 C 19.230469 12.730469 13.722656 18.234375 13.722656 25 C 13.722656 31.765625 19.230469 37.269531 25.996094 37.269531 C 30.875 37.269531 34.730469 34.777344 36.546875 30.53125 L 24.996094 30.53125 L 24.996094 20.175781 L 47.546875 20.207031 L 47.714844 21 C 48.890625 26.582031 47.949219 34.792969 43.183594 40.667969 C 39.238281 45.53125 33.457031 48 25.996094 48 Z "></path></g></svg> &nbsp;&nbsp;&nbsp;<span style={{ fontSize: 16 }}>Login With Google</span>
                </Button>
              </center>
              :
              <div>
                <center style={{marginBottom:10}}>
                  <GoogleLogin
                    clientId={config.googleClientId}
                    buttonText="Login with Google"
                    onSuccess={this.googleLogin}
                    onFailure={this.googleLogin}
                    cookiePolicy={'single_host_origin'}
                    className="MuiButton-contained"
                  // render={renderProps => (
                  //   <Button 
                  //     className={classes.googleButton} 
                  //     variant="raised"
                  //     onClick={renderProps.onClick} 
                  //     disabled={renderProps.disabled}
                  //   >
                  //     <i
                  //       className="fa fa-google-plus-official"
                  //       aria-hidden="true"
                  //       style={{ fontSize: "20px" }}
                  //     ></i>
                  //     &nbsp; &nbsp;Google
                  //   </Button>
                  // )}
                  />
                </center>
                <center style={{marginBottom:10}}>
                  <FacebookLogin
                    appId={config.facebookAppId}
                    // autoLoad={true}
                    fields="name,email,picture"
                    callback={this.facebookLogin}
                    cssClass={classes.facebookButton+' MuiButton-contained'}
                    icon={<FacebookIcon aria-hidden="true" style={{
                      fontSize: "20px", marginRight: 6,
                      marginBottom: -4
                    }} />}
                  // render={renderProps => (
                  //   <button onClick={renderProps.onClick}>This is my custom FB button</button>
                  // )}
                  />
                </center>
                {/* <center>
                  <LinkedinLogin
                    closeModel={this.props.closeModel}
                    button={
                      <Button className={classes.linkedInButton} variant="raised">
                        <LinkedInIcon
                          aria-hidden="true"
                          style={{ fontSize: "20px" }}
                        />
                        &nbsp; &nbsp;LinkedIn
                      </Button>
                    }
                  />
                </center> */}
              </div>
            }
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.Home.login.userDetails
  };
};

const mapDispatchToProps = dispatch => {
  return {
    login: data => {
      return dispatch(login(data));
    },
    socialLogin: data => {
      return dispatch(socialLogin(data));
    },
    signup: data => {
      return dispatch(signup(data));
    },
    showToast: data => {
      return dispatch(showToast(data));
    }
  };
};

Login.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withMobileDialog()(Login)));
