import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import ShareIcon from "@material-ui/icons/Share";
import CancelIcon from "@material-ui/icons/Cancel";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import CircularProgress from "@material-ui/core/CircularProgress";
import dynamic from "next/dynamic";
import Step from "../components/Step";
import { connect } from "react-redux";
import { addProject } from "../actions/Project";
import { getProjectDetails } from "../actions/ProjetDetails";
import axios from "axios"
import config from "../config"
var apiRoot = config.api.root

const JoditEditor = dynamic(
  () => import("../components/JoditEditor"),
  { ssr: false }
)
const ImageUploader = dynamic(
  () => import("react-images-upload"),
  { ssr: false }
)

const options = ["Arduino Uno", "Arduino Mega", "Arduino Nano", "Quadcopter", "Robotics"]

const styles = () => ({
  formHeader: {
    textAlign: "center",
    fontSize: 24,
    backgroundColor: "#2196f3",
    color: "#fff",
    padding: "15px 12px",
    textTransform: "uppercase"
  },
  buttonOutlineSelected: {
    borderRadius: "unset !important",
    border: "1px solid #2196f3 !important",
    fontSize: 12
  },
  buttonOutline: {
    borderRadius: "unset !important",
    border: "1px solid #999 !important",
    color: "#777 !important",
    fontSize: 12
  },
  close: {
    marginTop: "30px"
  },
  root: {
    "& .MuiButton-root": {
      padding: 0,
      minWidth: "0px"
    }
  },
  image: {
    "& .fileContainer": {
      background: "none",
      boxShadow: "none",
      padding: 0,
      margin: 0
    },
    "& .fileContainer .uploadPictureContainer": {
      padding: 0,
      margin: "10px",
      width: "50px",
      height: "50px",
      height: "inherit"
    },
    "& .fileContainer .deleteImage": {
      top: "-5px",
      right: "-6px",
      background: "#000",
      fontSize: "9px",
      lineHeight: "16px",
      width: "15px",
      height: "15px"
    }
  },
  button: {
    "& .MuiButton-containedPrimary": { backgroundColor: "#2196f3" },
    "& .MuiButton-root": {
      borderRadius: "0px"
    }
  }
})

let refs = []


class AddProject extends Component {

  constructor(props) {
    super(props);
    this.state = {
      title: "",
      description: "",
      descriptionInTag: "",
      imageUrls: [],
      videoUrls: [],
      tags: [],
      steps: [],
      loading: false
    }
  }

  componentDidMount = () => {
    let projectId = this.props.location.pathname.split("/")[2]
    if (projectId) {
      let project = this.props.projects.find(project => {
        return project.id == projectId
      })
      if (project && project.descriptionInTag) {
        document.title = project.title + " | Trybotics"
        this.setState({ ...project })
      } else {
        if (!this.props.fetchingProjectDetails) {
          this.props.getProjectDetails(projectId)
          // axios.get(apiRoot + "/api/project/" + projectId).then(
          //   response => {
          //     refs = response.data[0].steps.map((step, index) => { return { ref: React.createRef(), title: step.title, description: step.description, descriptionInTag: step.descriptionInTag, imageUrls: step.imageUrls, videoUrls: step.videoUrls } })
          //     this.setState({
          //       title: response.data[0].title, description: response.data[0].description, descriptionInTag: response.data[0].descriptionInTag, imageUrls: response.data[0].imageUrls, videoUrls: response.data[0].videoUrls, tags: response.data[0].tags
          //     })
          //   },
          //   error => {
          //     console.log(error.response);
          //   }
          // )
        }
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    console.log('nextProps', nextProps, this.props.projects[0])
    if (nextProps.projects[0] && nextProps.projects[0].steps) {
      refs = nextProps.projects[0].steps.map((step, index) => { return { ref: React.createRef(), title: step.title, description: step.description, descriptionInTag: step.descriptionInTag, imageUrls: step.imageUrls, videoUrls: step.videoUrls } })
      this.setState({
        title: nextProps.projects[0].title, description: nextProps.projects[0].description, descriptionInTag: nextProps.projects[0].descriptionInTag, imageUrls: nextProps.projects[0].imageUrls, videoUrls: nextProps.projects[0].videoUrls, tags: nextProps.projects[0].tags
      })
    }
    // let projectId = nextProps.location.pathname.split("/")[2]
    // if (projectId) {
    //   let project = this.props.projects.find(project => {
    //     return project.id == projectId
    //   })
    //   if (project && project.descriptionInTag) {
    //     document.title = project.title + " | Trybotics"
    //     this.setState({ ...project })
    //   } else {
    //     if (!this.props.fetchingProjectDetails) {
    //       axios.get(apiRoot + "/api/project/" + projectId).then(
    //         response => {
    //           refs = response.data[0].steps.map((step, index) => { return { ref: React.createRef(), title: step.title, description: step.description, descriptionInTag: step.descriptionInTag, imageUrls: step.imageUrls, videoUrls: step.videoUrls } })
    //           this.setState({
    //             title: response.data[0].title, description: response.data[0].description, descriptionInTag: response.data[0].descriptionInTag, imageUrls: response.data[0].imageUrls, videoUrls: response.data[0].videoUrls, tags: response.data[0].tags
    //           })
    //         },
    //         error => {
    //           console.log(error.response);
    //         }
    //       )
    //     }
    //   }
    // }
  }

  handleSubmit = () => {
    let data = { ...this.state, steps: refs.map(data => { return data.ref.current.state }), id: this.props.location.pathname.split("/")[2] }
    console.log('data', data)
    this.setState({ loading: true })
    data.history = this.props.history
    this.props.addProject(data).then(result => {
    }).then(result => {
      console.log('result', result)
      this.setState({ loading: false })
      // this.props.history.push("/project")
    })
      .catch((error) => {
        console.log(error)
        this.setState({ loading: false })
        // document.getElementById("outlined-button-file").value = ""
      })
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    })
  }

  onDrop = imageUrls => {
    this.setState({
      imageUrls: imageUrls
    })
  }

  handleAddVideoUrl = () => {
    this.setState({
      videoUrls: [...this.state.videoUrls, ""]
    })
  }

  handleRemoveVideo = index => () => {
    this.state.videoUrls.splice(index, 1)
    this.forceUpdate()
  }

  handleVideoChange = id => evt => {
    this.state.videoUrls[id] = evt.target.value
    this.setState({ videoUrls: this.state.videoUrls })
  }

  handleAddStep = () => {
    refs.push({ ref: React.createRef() })
    this.forceUpdate()
  }

  render() {
    const { title, description, descriptionInTag, imageUrls, videoUrls, steps, tags } = this.state;
    const { classes } = this.props;
    return (
      <Container disableGutters={true} maxWidth="lg">
        <Card elevation={1}>
          <div className={classes.formHeader}>Share Your Projects </div>
          <Grid justify='center' container spacing={0}>
            {this.props.location.pathname.split("/")[2] && !title ?
              <center style={{ margin: 20 }}>
                <CircularProgress />
              </center>
              :
              <Grid item xs={11}>
                <Paper style={{ marginTop: 30, paddingBottom: 20 }} variant="outlined">
                  <Grid justify='center' container spacing={0}>
                    <Grid item xs={11}>
                      <center>
                        <h4 style={{ margin: 20, color: '#333' }}>Project Details</h4>
                      </center>
                      <Divider />
                      <TextField
                        style={{ marginTop: 25 }}
                        value={title}
                        label="Title Of Your Project"
                        type="text"
                        margin="normal"
                        fullWidth
                        autoFocus = { true }
                        required
                        onChange={this.handleChange('title')}
                      />
                      <Typography style={{ marginTop: 20 }}>Project Description:</Typography>
                      <JoditEditor
                        value={descriptionInTag}
                        config={{ readonly: false }}
                        tabIndex={1} // tabIndex of textarea
                        onChange={(descriptionInTag) => { this.setState({ description: descriptionInTag.replace(/<\/?[^>]+>/ig, ""), descriptionInTag: descriptionInTag }) }}
                      />
                      <center style={{ marginTop: 15, marginBotton: 20 }}>
                        <div style={{ width: "100%" }} className={classes.image}>
                          <ImageUploader
                            // defaultImages={imageUrls}
                            label={false}
                            withIcon={false}
                            withPreview={true}
                            buttonText="Add Images"
                            onChange={this.onDrop}
                            imgExtension={[".jpg", ".gif", ".png"]}
                            maxFileSize={5242880}
                            required
                          />
                        </div>
                      </center>
                      {videoUrls.map((vid, index) => (
                        <div className={classes.root}>
                          <Grid container>
                            <Grid item xs={12}>
                              <TextField
                                label="Enter Video URL"
                                type="text"
                                margin="normal"
                                style={{ width: "100%" }}
                                value={vid}
                                onChange={this.handleVideoChange(index)}
                                required
                              />
                              <Button
                                type="button"
                                onClick={this.handleRemoveVideo(index)}
                                className={classes.close}
                                style={{
                                  float: "right",
                                  marginTop: "-40px"
                                }}
                                fontSize="small"
                              >
                                <CancelIcon />
                              </Button>
                            </Grid>
                          </Grid>
                        </div>
                      ))}
                      <center style={{ marginTop: 10, marginBotton: 20 }}>
                        <Button
                          className={classes.buttonOutlineSelected}
                          onClick={this.handleAddVideoUrl}
                          variant="outlined"
                          color="primary"
                        >
                          Add Videos &nbsp;&nbsp;<PlayCircleFilledIcon />
                        </Button>
                      </center>
                      <Autocomplete
                        value={tags}
                        multiple
                        id="tags-filled"
                        options={options}
                        freeSolo
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              variant="outlined"
                              label={option}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="standard"
                            label="Tags"
                            placeholder="Tags"
                            margin="normal"
                            required
                          />
                        )}
                        onChange={(event, tags) => { this.setState({ tags: tags }) }}
                      />
                    </Grid >
                  </Grid >
                </Paper>
                {refs.map((data, index) => {
                  return <Step {...data} index={index + 1} key={index + 1} />
                })}
                {this.state.steps}
                <center style={{ margin: 20 }}>
                  <Button
                    className={classes.buttonOutlineSelected}
                    onClick={this.handleAddStep}
                    variant="outlined"
                    color={"primary"}
                  >
                    Add Steps&nbsp;&nbsp;<ShareIcon />
                  </Button>
                </center>
                <div
                  style={{ paddingTop: "20px", paddingBottom: "50px" }}
                  className={classes.button}
                >
                  {this.state.loading ?
                    <Button
                      style={{ float: "right" }}
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth
                    >
                      <CircularProgress style={{ color: '#fff', width: 26, height: 26 }} />
                    </Button>
                    :
                    <Button
                      style={{ float: "right" }}
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth
                      onClick={this.handleSubmit}
                    >
                      Post
                  </Button>
                  }
                </div>
                <br />
              </Grid>
            }
          </Grid>
        </Card>
      </Container >
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addProject: data => {
      return dispatch(addProject(data));
    },
    getProjectDetails: id => {
      return dispatch(getProjectDetails(id));
    }
  };
};

const mapStateToProps = state => {
  return {
    user: state.Home.login.userDetails,
    projects: state.projectState.projects,
    fetchingProjectDetails: state.projectState.fetchingProjectDetails
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AddProject));



