import React from "react";
// import { setMetaContentByName } from '../utils/Helper'
import EventGallery from "../components/EventGallery";
import Container from "@material-ui/core/Container";

class PastEvents extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    document.title = "Past Events  | Trybotics";
  };

  render() {
    return (
      <Container disableGutters={true} maxWidth="lg">
        <EventGallery />
      </Container>
    );
  }
}

export default PastEvents;
