import {
  SET_NEW_BLOGS,
  SET_BLOG_DATA,
  SET_BLOG_HAS_MORE,
  BLOG_ADDED,
  BLOG_FAIL,
  BLOG_EDITED,
  BLOG_DELETED,
  BLOG_COMMENT_ADDED,
  BLOG_COMMENT_EDITED,
  BLOG_COMMENT_DELETED,
  BLOG_COMMENT_FAIL,
  BLOG_LIKE_ADDED,
  BLOG_LIKE_FAIL,
  BLOG_LIKE_REMOVED,
  SET_BLOG_DETAILS,
  SET_FETCHING_BLOG_DETAILS,
  CLOSE_SNACKBAR
} from "../actions/ActionType";

const initialState = {
  snackbar: {
    msg: null,
    show: false
  },
  blogs: [],
  blogHasMore: true,
  fetchingBlogDetails: false
};

const blogReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BLOG_DATA:
      let blogObjects = {}
      state.blogs.map(blog => { blogObjects[blog.id] = blog })
      action.data.map(blog => {
        blogObjects[blog.id] = blogObjects[blog.id] ? { ...blogObjects[blog.id], ...blog } : blog
      })
      return Object.assign({}, state, {
        blogs: action.refresh ? action.data : Object.values(blogObjects)
      });

    case SET_BLOG_HAS_MORE:
      return Object.assign({}, state, { blogHasMore: action.data });

    case SET_FETCHING_BLOG_DETAILS:
      return Object.assign({}, state, { fetchingInternshipDetails: action.data });

    case BLOG_ADDED:
      return Object.assign({}, state, {
        blogs: [action.data].concat(state.blogs),
        snackbar: { msg: "Your Blog is Added", show: true }
      });

    case BLOG_FAIL:
      return Object.assign({}, state, {
        snackbar: {
          msg: "Error while adding your Blog, please try again",
          show: true
        }
      });

    case BLOG_EDITED:
      for (var i = 0; i < state.blogs.length; i++) {
        if (state.blogs[i].id == action.data.id) {
          state.blogs[i] = action.data
        }
      }
      return Object.assign({}, state, {
        blogs: state.blogs,
        snackbar: { msg: "Your blog is Edited", show: true }
      });

    case BLOG_DELETED:
      return Object.assign({}, state, {
        blogs: state.blogs.filter(blog => blog.id != action.data),
        snackbar: { msg: "Your Blog is deleted", show: true }
      });

    case BLOG_COMMENT_ADDED:
      for (var i = 0; i < state.blogs.length; i++) {
        if (state.blogs[i].id == action.data.id) {
          state.blogs[i].comments.push(action.data.comments);
        }
      }
      return Object.assign({}, state, {
        blogs: state.blogs,
        snackbar: { msg: "Your Comment Added", show: true }
      });

    case BLOG_COMMENT_EDITED:
      for (var i = 0; i < state.blogs.length; i++) {
        if (state.blogs[i].id == action.data.id) {
          for (var j = 0; j < state.blogs[i].comments.length; j++) {
            if (state.blogs[i].comments[j]._id == action.data.commentId) {
              state.blogs[i].comments[j].comment = action.data.comment;
            }
          }
        }
      }
      return Object.assign({}, state, {
        blogs: state.blogs,
        snackbar: { msg: "Your Comment Edited", show: true }
      });

    case BLOG_COMMENT_DELETED:
      for (var i = 0; i < state.blogs.length; i++) {
        if (state.blogs[i].id == action.data.id) {
          for (var j = 0; j < state.blogs[i].comments.length; j++) {
            if (state.blogs[i].comments[j]._id == action.data.commentId) {
              state.blogs[i].comments.splice(j, 1);
            }
          }
        }
      }
      return Object.assign({}, state, {
        blogs: state.blogs,
        snackbar: { msg: "Your comment is deleted", show: true }
      });

    case BLOG_COMMENT_FAIL:
      return Object.assign({}, state, {
        snackbar: { msg: "Error, Please Login", show: true }
      });

    case BLOG_LIKE_ADDED:
      for (var i = 0; i < state.blogs.length; i++) {
        if (state.blogs[i].id == action.data.id) {
          state.blogs[i].likes = action.data.likes;
        }
      }
      return Object.assign({}, state, {
        blogs: state.blogs,
        snackbar: { msg: "Your Like Added", show: true }
      });

    case BLOG_LIKE_FAIL:
      return Object.assign({}, state, {
        snackbar: { msg: "Error, Please Login", show: true }
      });

    case BLOG_LIKE_REMOVED:
      for (var i = 0; i < state.blogs.length; i++) {
        if (state.blogs[i].id == action.data.id) {
          state.blogs[i].likes = action.data.likes;
        }
      }
      return Object.assign({}, state, {
        blogs: state.blogs,
        snackbar: { msg: "You have Disliked", show: true }
      });
    case SET_BLOG_DETAILS:
      let blogs = []
      blogs = Array.from(state.blogs)
      let index = blogs.findIndex(internship => { return internship.id == action.data.id })
      if (index > -1) {
        blogs[index] = action.data
      } else {
        blogs.push(action.data)
      }
      return Object.assign({}, state, { blogs: blogs });

    case CLOSE_SNACKBAR:
      return Object.assign({}, state, {
        snackbar: { msg: state.snackbar.msg, show: false }
      });

    default:
      return state;
  }
};

export default blogReducer;
