import {
  SET_INTERNSHIP_DATA,
  SET_INTERNSHIP_HAS_MORE,
  SET_INTERNSHIP_DETAILS,
  SET_FETCHING_INTERNSHIP_DETAILS
} from "../actions/ActionType";

const initialState = {
  snackbar: {
    msg: null,
    show: false
  },
  internships: [],
  internshipHasMore: true,
  fetchingInternshipDetails: false
};

const internshipReducer = (state = initialState, action) => {
  let internshipObjects = {};
  switch (action.type) {
    case SET_INTERNSHIP_DATA:
      internshipObjects = {};
      state.internships.map(internship => { internshipObjects[internship.id] = internship; });
      action.data.map(internship => {
        internshipObjects[internship.id] = internshipObjects[internship.id] ? { ...internshipObjects[internship.id], ...internship } : internship;
      });
      return Object.assign({}, state, {
        internships: action.refresh ? action.data : Object.values(internshipObjects)
      });

    case SET_INTERNSHIP_HAS_MORE:
      return Object.assign({}, state, { internshipHasMore: action.data });

    case SET_FETCHING_INTERNSHIP_DETAILS:
      return Object.assign({}, state, {
        fetchingInternshipDetails: action.data
      });

    case SET_INTERNSHIP_DETAILS:
      internshipObjects = {};
      state.internships.map(internship => { internshipObjects[internship.id] = internship; });
      internshipObjects[action.data.id] = internshipObjects[action.data.id] ? { ...internshipObjects[action.data.id], ...action.data } : action.data
      return Object.assign({}, state, { internships: Object.values(internshipObjects) });

    default:
      return state;
  }
};

export default internshipReducer;
