import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Chip from '@material-ui/core/Chip'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Avatar from '@material-ui/core/Avatar'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import { connect } from "react-redux";
import SwipeableViews from 'react-swipeable-views'
import DoneAllIcon from "@material-ui/icons/DoneAll";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { getEventData } from '../actions/Event'
import ContactUs from '../components/ContactUs'


function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ textAlign: 'justify', fontSize: '13px', padding: 8 * 3 }}>
      {children}
    </Typography>
  )
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
}

const styles = theme => ({
})


class EventComp extends React.Component {

  constructor(props) {

    super(props)
    this.state = {
      value: 0,
      expanded: 'panel0',
      responseData: null
    }
  }

  componentDidMount() {
    this.props.getEventData(this.props.eventType, this.props.subEventType)
  }

  handleChangeTab = (event, value) => {
    this.setState({ value })
  }

  handleChangeIndex = index => {
    this.setState({ value: index })
  }

  handleChangeExpand = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    })
  }

  showContactUsModel = () => {
    this.setState({ showContactUsModel: true })
  }

  closeModel = () => {
    this.setState({ showLoginModel: false, showContactUsModel: false, showProfile: false })
  }

  render() {
    let data = null
    if (this.props.subEventType && this.props.event) {
      data = this.props.subEventType == this.props.event.subEventType ? this.props.event : null
      if (!data) {
        this.props.getEventData(this.props.eventType, this.props.subEventType)
      }
    }

    const { theme } = this.props
    const { expanded } = this.state
    var banner = []
    var tab = []
    var tabContainer = []
    var expansionModel = []
    if (this.props.event && this.props.event.bannerUrls.length > 0) {
      let data = this.props.event
      console.log('data', data)

      if (data.bannerUrls.length == 3) {
        let images = []
        for (let i = 0; i < data.bannerUrls.length; i++) {
          images.push(
            <div style={{ padding: '10px', background: '#fff', boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.15), 0 0px 6px rgba(0, 0, 0, 0.25)', borderRadius: '2px' }}>
              <img width="100%" src={data.bannerUrls[i]} />
            </div>
          )
        }

      } else {
        banner.push(
          <div style={{ background: '#fff', boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.15), 0 0px 6px rgba(0, 0, 0, 0.25)', borderRadius: '2px' }}>
            <img width="100%" src={data.bannerUrls[0]} />
          </div>
        )
      }

      for (let i = 0; i < data.bannerBody.length; i++) {
        tab.push(
          <Tab label={data.bannerBody[i].tabName} />
        )
        if (i == 0) {
          let chipData = data.bannerBody[i].tabBody.split(',')
          let chips = []
          for (let i = 0; i < chipData.length; i++) {
            chips.push(
              <Chip
                avatar={
                  <Avatar>
                    <DoneAllIcon />
                  </Avatar>
                }
                style={{ margin: '10px' }}
                label={chipData[i]}
              />
            )
          }
          tabContainer.push(
            <TabContainer dir={theme.direction}>{chips}</TabContainer>
          )
        } else {
          tabContainer.push(
            <TabContainer dir={theme.direction}>{data.bannerBody[i].tabBody}</TabContainer>
          )
        }
      }

      for (let i = 0; i < data.modules.length; i++) {
        let topics = []
        for (let t = 0; t < data.modules[i].topics.length; t++) {
          topics.push(<li>{data.modules[i].topics[t]}</li>)
        }
        expansionModel.push(
          <ExpansionPanel expanded={expanded === 'panel' + i} onChange={this.handleChangeExpand('panel' + i)}>
            <ExpansionPanelSummary expandIcon={<KeyboardArrowDownIcon />}>
              <Typography variant="subheading">{data.modules[i].title}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography style={{ textAlign: 'justify' }}>{topics}</Typography>
            </ExpansionPanelDetails>
            <ExpansionPanelDetails>
              <Typography variant="subheading"><b>Description:</b></Typography>
            </ExpansionPanelDetails>
            <ExpansionPanelDetails>
              <Typography style={{ textAlign: 'justify' }}>{data.modules[i].description}</Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )
      }
    }
    return (
      <div>
        {(this.props.event && this.props.event.bannerUrls.length > 0) ?
          <div>
            <Typography style={{ marginTop: '5px', paddingBottom: '20px', clear: 'both' }} align="center" color='inherit' variant="h5" component="h1">
              {this.props.event.title}
            </Typography>
            <Card>
              <Grid style={{ clear: 'both', margin: 'auto' }} alignItems='center' container spacing={0}>
                <Grid style={{ padding: '35px', borderBottom: 'none' }} item xs={12} sm={6}>
                  {banner}
                </Grid>
                <Grid style={{ padding: '10px' }} item xs={12} sm={6}>
                  <div>
                    <div position="static" color="default">
                      <Tabs
                        value={this.state.value}
                        onChange={this.handleChangeTab}
                        indicatorColor="primary"
                        textColor="primary"
                        fullWidth
                      >
                        {tab}
                      </Tabs>
                      <Divider />
                    </div>
                    <div>
                      <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={this.state.value}
                        onChangeIndex={this.handleChangeIndex}
                      >
                        {tabContainer}
                      </SwipeableViews>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Card>
            <Divider style={{ marginTop: '20px', marginBottom: '20px' }} light />
            <Typography align="center" color='inherit' variant="h5" component="h3">
              {this.props.event.secondTitle}
            </Typography>
            <div style={{ marginTop: '10px' }}>
              {expansionModel}
            </div>
            <center style={{ marginTop: '20px', marginBottom: '10px' }} onClick={this.showContactUsModel}>
              <Button variant="contained" size="large" color="primary">
                Contact For More Details
              </Button>
            </center>
          </div>
          :
          <center style={{ marginTop: '10px' }}><CircularProgress size={50} /></center>
        }
        <ContactUs
          open={this.state.showContactUsModel}
          onClose={this.closeModel}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    event: state.Home.login.event,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getEventData: (eventType, subEventType) => {
      return dispatch(getEventData(eventType, subEventType))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(EventComp))