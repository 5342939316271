import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import SearchIcon from "@material-ui/icons/Search";
import ShareIcon from "@material-ui/icons/Share";
import InputBase from "@material-ui/core/InputBase";
import Snackbar from "@material-ui/core/Snackbar";
import Avatar from "@material-ui/core/Avatar";
import Hidden from "@material-ui/core/Hidden";
import withStyles from "@material-ui/core/styles/withStyles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { connect } from "react-redux";
import { logOut, closeSnackbar } from "../actions/Home";
import Post from "./Post";
import Login from "./Login";
import ContactUs from "./ContactUs";
import Profile from "./Profile";
import RemoveContent from "./RemoveContent";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import CloseIcon from "@material-ui/icons/Close";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import SettingsIcon from "@material-ui/icons/Settings";
import RestoreIcon from "@material-ui/icons/Restore";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { Scrollbars } from "react-custom-scrollbars";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import { Link } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import MenuIcon from "@material-ui/icons/Menu";
import ListSubheader from "@material-ui/core/ListSubheader";
import Drawer from "@material-ui/core/Drawer";
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import Home from "@material-ui/icons/Home";
import Build from "@material-ui/icons/Build";
import School from "@material-ui/icons/School";
import Work from "@material-ui/icons/Work";
import Event from "@material-ui/icons/Event";
import PostAdd from "@material-ui/icons/PostAdd";
import FontDownload from "@material-ui/icons/FontDownload";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import DescriptionIcon from '@material-ui/icons/Description';
import Videocam from "@material-ui/icons/Videocam";
import AirplanemodeActive from "@material-ui/icons/AirplanemodeActive";
import QuestionAnswer from "@material-ui/icons/QuestionAnswer";
import Send from "@material-ui/icons/Send";
import Mail from "@material-ui/icons/Mail";
import People from "@material-ui/icons/People";
import { List } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import Collapse from "@material-ui/core/Collapse";
import Fab from '@material-ui/core/Fab';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import TryboticsLogo from '../images/trybotics-logo2.jpg';
import { addTokenToTopic } from "../utils/push-notification";
import {
  WhatsappShareButton,
  WhatsappIcon,
  FacebookShareButton,
  FacebookIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
  WorkplaceShareButton,
  WorkplaceIcon,
} from "react-share";
import { Capacitor, Plugins } from '@capacitor/core';
import config from "../config";
var rootURL = config.rootURL;
const { Device, Share } = Plugins;
const isAvailable = Capacitor.isPluginAvailable('Share');

const styles = theme => ({
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginRight: 30,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(),
      width: "auto"
    }
  },
  searchIcon: {
    width: theme.spacing() * 9,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit",
    width: "100%"
  },
  inputInput: {
    paddingTop: theme.spacing(),
    paddingRight: theme.spacing(),
    paddingBottom: theme.spacing(),
    paddingLeft: theme.spacing() * 10,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 160,
      "&:focus": {
        width: 200
      }
    },
    grow: {
      flexGrow: 1
    }
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  hide: {
    display: "none"
  },
  LogoIcon: {
    fontSize: "30px",
    marginLeft: "-5px",
    marginRight: "-7px"
  },
  logoText: {
    position: "absolute",
    paddingTop: "10px",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif;',
    fontWeight: "500",
    fontSize: "1.3125rem",
    lineHeight: "1.66667rem"
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  list: {
    width: 280
  },
  headerMenuIcon: {
    top: "0px",
    zIndex: "1110 !important",
    position: "fixed !important",
    margin: "6px !important"
  },
  blinking: {
    color: '#2196F3',
    animation: `$blinkingText 1.2s infinite`
  },
  "@keyframes blinkingText": {
    "0%": { color: '#2196F3' },
    "49%": { color: '#2196F3' },
    "60%": { color: 'transparent' },
    "99%": { color: 'transparent' },
    "100%": { color: '#2196F3' },
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 1100
  }
});

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorElProfileMenu: null,
      anchorElCardMenu: null,
      anchorElShareMenu: null,
      open: false,
      showContactUs: false,
      showProfile: false,
      showPost: false,
      search: "",
      openWorkshop: false,
      left: false,
      autoHeightMax: 1000,
      openWebShare: false,
      showRemoveContent: false
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ search: "" });
    addTokenToTopic()
  }

  componentDidMount = () => {
    this.setState({ autoHeightMax: window.innerHeight })
    setTimeout(() => {
      if (!this.state.open && !this.props.user.isLogin) {
        this.setState({ open: true });
      }
    }, 30000)
    Device.getInfo().then(info => {
      console.log(info);
      this.setState({ platform: info.platform })
    }).catch(error => {
      console.log(error);
    })
    document.getElementById('google_translate_element').className = "google_translate_element"
  };

  removeElementById = element => {
    element = document.getElementById(element);
    if (element) {
      element.parentNode.removeChild(element);
    }
  };

  handleProfileMenu = event => {
    if (this.props.user.isLogin) {
      this.setState({
        anchorElProfileMenu: event.currentTarget
      });
    } else {
      this.setState({
        open: true
      });
    }
  };
  handleCardMenu = event => {
    this.setState({
      anchorElCardMenu: event.currentTarget
    });
  };

  handleShareMenu = event => {
    this.setState({
      anchorElShareMenu: event.currentTarget
    });
  };

  handleRequestClose = (e, url) => {
    this.setState({
      anchorElProfileMenu: null,
      anchorElCardMenu: null,
      anchorElShareMenu: null,
      openWebShare: false
    });
  };

  openModel = () => {
    this.setState({ open: true });
  };

  closeModel = () => {
    this.setState({ open: false, showContactUs: false, showProfile: false, showRemoveContent: false });
  };

  signOut = e => {
    localStorage.clear();
    this.props.logOut();
    this.handleRequestClose(e);
  };

  closePost = () => {
    this.setState({ showPost: false });
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleEnter = event => {
    if (event.keyCode == 13 && event.shiftKey == false) {
      if (
        this.props.location.pathname == "" ||
        this.props.location.pathname == "/" ||
        this.props.location.pathname == "/home"
      ) {
        this.props.history.push({
          pathname: "project",
          search: "?search=" + this.state.search
        });
      } else {
        console.log('this.props.location', this.props.location)
        if (this.props.location.pathname.split("/").length > 2) {
          this.props.location.pathname = "/" + this.props.location.pathname.split("/")[1];
        } else {
          this.props.history.push({
            pathname: this.props.location.pathname.split("/")[1],
            search: "?search=" + this.state.search
          });
        }
      }
    }
  };
  handleWorkshopClick = () => {
    this.setState({ openWorkshop: !this.state.openWorkshop });
  };
  toggleDrawer = (side, open) => event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    this.setState({ [side]: open });
  };

  render() {
    let shareLinkURL = rootURL + this.props.location.pathname
    let showRemoveContentOption = (this.props.location.pathname && this.props.location.pathname.split('/').length > 0 && ["project", "internship"].includes(this.props.location.pathname.split('/')[1])) ? true : false
    const { classes } = this.props;
    const sideList = side => (
      <div
        className={classes.list}
        style={{overflow: 'hidden'}}
        role="presentation"
        onKeyDown={this.toggleDrawer(side, false)}
      >
        {/* </Scrollbars> */}

        <Scrollbars
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
          autoHeight
          autoHeightMin={0}
          autoHeightMax={this.state.autoHeightMax}
          thumbMinSize={30}
          universal={true}
          {...this.props}
        >
          <List style={{ padding: 0 }}>
            <ListItem
              button
              onClick={e => {
                this.props.user.isLogin
                  ? this.setState({ open: false, showProfile: true })
                  : this.setState({ open: true, showProfile: false });
              }}
              style={{
                background:
                  'url("https://img.youtube.com/vi/DWHcA4PH5sc/mqdefault.jpg")',
                position: "relative",
                padding: "32px 32px 12px",
                marginBottom: "8px"
              }}
            >
              <ListItemAvatar>
                <Avatar
                  alt={this.props.user.name}
                  src={this.props.user.imageUrl}
                  style={{ height: "64px", width: "64px" }}
                />

                <ListItemText
                  primary={this.props.user.name}
                  style={{ color: "white", paddingTop: "10px" }}
                />
                <ListItemText
                  primary={this.props.user.email}
                  style={{ color: "white" }}
                />
              </ListItemAvatar>
            </ListItem>
            <Link style={{ all: "initial" }} to="/home">
              <ListItem button onClick={this.toggleDrawer(side, false)}>
                <ListItemIcon>
                  <Home />
                </ListItemIcon>
                <ListItemText primary="Home" />
              </ListItem>
            </Link>
            {this.state.platform == "web" &&
              <a style={{ all: "initial" }} href={rootURL + "/service/"}>
                <ListItem button onClick={this.toggleDrawer(side, false)}>
                  <ListItemIcon>
                    <LocalHospitalIcon />
                  </ListItemIcon>
                  <ListItemText primary="Services" />
                </ListItem>
              </a>
            }
            <Link style={{ all: "initial" }} to="/project">
              <ListItem button onClick={this.toggleDrawer(side, false)}>
                <ListItemIcon>
                  <Build />
                </ListItemIcon>
                <ListItemText primary="Tech Projects" />
              </ListItem>
            </Link>
            <Link style={{ all: "initial" }} to="/internship">
              <ListItem button onClick={this.toggleDrawer(side, false)}>
                <ListItemIcon>
                  <School />
                </ListItemIcon>
                <ListItemText primary="Internships" />
              </ListItem>
            </Link>
            <Link style={{ all: "initial" }} to="/blog">
              <ListItem button onClick={this.toggleDrawer(side, false)}>
                <ListItemIcon>
                  <PostAdd />
                </ListItemIcon>
                <ListItemText primary="Tech Blogs" />
              </ListItem>
            </Link>
            {this.state.platform == "web" &&
              <a style={{ all: "initial" }} href={rootURL + "/resume-builder/"}>
                <ListItem button onClick={this.toggleDrawer(side, false)}>
                  <ListItemIcon>
                    <DescriptionIcon />
                  </ListItemIcon>
                  <ListItemText primary="Resume Builder" />
                </ListItem>
              </a>
            }
            {this.state.platform == "web" &&
              <a style={{ all: "initial" }} href={rootURL + "/covid19-india/"}>
                <ListItem button onClick={this.toggleDrawer(side, false)}>
                  <ListItemIcon>
                    <LocalHospitalIcon />
                  </ListItemIcon>
                  <ListItemText primary="Covid19 India" />
                </ListItem>
              </a>
            }
            <Link style={{ all: "initial" }} to="/ad">
              <ListItem button onClick={this.toggleDrawer(side, false)}>
                <ListItemIcon>
                  <FontDownload />
                </ListItemIcon>
                <ListItemText primary={<span>Post Ad <span className={classes.blinking}> *Free*</span></span>} />
              </ListItem>
            </Link>
            <Link style={{ all: "initial" }} to="/buy">
              <ListItem button onClick={this.toggleDrawer(side, false)}>
                <ListItemIcon>
                  <ShoppingCart />
                </ListItemIcon>
                <ListItemText primary="Electronic Parts" />
              </ListItem>
            </Link>
            <Link style={{ all: "initial" }} to="/youtube">
              <ListItem button onClick={this.toggleDrawer(side, false)}>
                <ListItemIcon>
                  <Videocam />
                </ListItemIcon>
                <ListItemText primary="Tech Videos" />
              </ListItem>
            </Link>
            <ListItem button onClick={this.handleWorkshopClick}>
              <ListItemIcon>
                <Work />{" "}
              </ListItemIcon>
              <ListItemText primary="Tech Workshops" />
              {this.state.openWorkshop ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={this.state.openWorkshop} timeout="auto" unmountOnExit>
              <List
                style={{ marginLeft: "40px" }}
                component="div"
                disablePadding
              >
                <Link style={{ all: "initial" }} to="/workshop/ai_ml">
                  <ListItem
                    button
                    onClick={this.toggleDrawer(side, false)}
                    className={classes.nested}
                  >
                    <ListItemText primary="AI & ML"></ListItemText>
                  </ListItem>
                </Link>
                <Link style={{ all: "initial" }} to="/workshop/robotics">
                  <ListItem
                    button
                    onClick={this.toggleDrawer(side, false)}
                    className={classes.nested}
                  >
                    <ListItemText primary="Robotics"></ListItemText>
                  </ListItem>
                </Link>
                <Link
                  style={{ all: "initial" }}
                  to="/workshop/quardcopter_design"
                >
                  <ListItem
                    button
                    onClick={this.toggleDrawer(side, false)}
                    className={classes.nested}
                  >
                    <ListItemText primary="Quardcopter Design"></ListItemText>
                  </ListItem>
                </Link>
                <Link style={{ all: "initial" }} to="/workshop/web_development">
                  <ListItem
                    button
                    onClick={this.toggleDrawer(side, false)}
                    className={classes.nested}
                  >
                    <ListItemText primary="Web Development"></ListItemText>
                  </ListItem>
                </Link>
                <Link
                  style={{ all: "initial" }}
                  to="/workshop/android_app_development"
                >
                  <ListItem
                    button
                    onClick={this.toggleDrawer(side, false)}
                    className={classes.nested}
                  >
                    <ListItemText primary="Android Development"></ListItemText>
                  </ListItem>
                </Link>
                <Link
                  style={{ all: "initial" }}
                  to="/workshop/hybrid_app_development"
                >
                  <ListItem
                    button
                    onClick={this.toggleDrawer(side, false)}
                    className={classes.nested}
                  >
                    <ListItemText primary="Hybrid App Development"></ListItemText>
                  </ListItem>
                </Link>
                <Link
                  style={{ all: "initial" }}
                  to="/workshop/internet_of_things"
                >
                  <ListItem
                    button
                    onClick={this.toggleDrawer(side, false)}
                    className={classes.nested}
                  >
                    <ListItemText primary="Internet Of Things"></ListItemText>
                  </ListItem>
                </Link>
                <Link style={{ all: "initial" }} to="/workshop/bigdata_hadoop">
                  <ListItem
                    button
                    onClick={this.toggleDrawer(side, false)}
                    className={classes.nested}
                  >
                    <ListItemText primary="Big Data & Hadoop"></ListItemText>
                  </ListItem>
                </Link>
              </List>
            </Collapse>
            <Link style={{ all: "initial" }} to="/past-events">
              <ListItem button onClick={this.toggleDrawer(side, false)}>
                <ListItemIcon>
                  <Event />
                </ListItemIcon>
                <ListItemText primary="Past Event" />
              </ListItem>
            </Link>
            <Link style={{ all: "initial" }} to="/mini-quadcopter">
              <ListItem button onClick={this.toggleDrawer(side, false)}>
                <ListItemIcon>
                  <AirplanemodeActive />
                </ListItemIcon>
                <ListItemText primary="Mini Quadcopter" />
              </ListItem>
            </Link>
            <Link style={{ all: "initial" }} to="/faq">
              <ListItem button onClick={this.toggleDrawer(side, false)}>
                <ListItemIcon>
                  <QuestionAnswer />
                </ListItemIcon>
                <ListItemText primary="FAQ" />
              </ListItem>
            </Link>
            {this.props.user.isAdmin && (
              <ListItem
                button
                onClick={() => {
                  this.setState({ showPost: true });
                }}
              >
                <ListItemIcon>
                  <Send />
                </ListItemIcon>
                <ListItemText primary="Post Youtube / Affiliate" />
              </ListItem>
            )}
            {/* </List> */}
            <ListItem divider />
            {/* <List> */}
            <ListSubheader
              style={{ fontSize: "15px" }}
              component="div"
              id="nested-list-subheader"
            >
              Get In Touch{" "}
            </ListSubheader>
            <Link style={{ all: "initial" }} to="/about">
              <ListItem button onClick={this.toggleDrawer(side, false)}>
                <ListItemIcon>
                  <People />
                </ListItemIcon>
                <ListItemText primary="About Us" />
              </ListItem>
            </Link>
            <ListItem
              button
              onClick={() => {
                this.setState({ showContactUs: true });
              }}
            >
              <ListItemIcon onClick={this.toggleDrawer(side, false)}>
                <Mail />
              </ListItemIcon>
              <ListItemText
                onClick={this.toggleDrawer(side, false)}
                primary="Contact Us"
              />
            </ListItem>
            {showRemoveContentOption &&
              <ListItem
                button
                onClick={() => {
                  this.setState({ showRemoveContent: true });
                }}
              >
                <ListItemIcon onClick={this.toggleDrawer(side, false)}>
                  <RemoveCircle />
                </ListItemIcon>
                <ListItemText
                  onClick={this.toggleDrawer(side, false)}
                  primary="Remove Content"
                />
              </ListItem>
            }
          </List>
        </Scrollbars>
      </div>
    );

    let profileMenu = (
      <Menu
        style={{ display: "block" }}
        anchorEl={this.state.anchorElProfileMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        open={Boolean(this.state.anchorElProfileMenu)}
        onClose={this.handleRequestClose}
      >
        <MenuItem
          onClick={e => {
            this.handleRequestClose(e);
            setTimeout(() => {
              this.setState({ showProfile: true });
            });
          }}
        >
          <ListItemIcon>
            <AssignmentIndIcon />{" "}
          </ListItemIcon>
          <ListItemText
            primary={<div style={{ fontSize: "14px" }}>Profile</div>}
          />
        </MenuItem>
        <MenuItem onClick={this.handleRequestClose}>
          <ListItemIcon>
            <SettingsIcon />{" "}
          </ListItemIcon>
          <ListItemText
            primary={<div style={{ fontSize: "14px" }}>Settings</div>}
          />
        </MenuItem>
        <MenuItem
          onClick={e => {
            this.signOut(e);
          }}
        >
          <ListItemIcon>
            <RestoreIcon />{" "}
          </ListItemIcon>
          <ListItemText
            primary={<div style={{ fontSize: "14px" }}>Logout</div>}
          />
        </MenuItem>
      </Menu>
    );
    const mobileToolbar = <Toolbar
      style={{
        marginLeft: 12,
        minHeight: 60,
        paddingRight: 5,
        paddingLeft: 5
      }}
    >
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={this.toggleDrawer("left", true)}
        edge="start"
      >
        <MenuIcon />
      </IconButton>

      <div style={{ flex: 1 }}>
        <img height="55" style={{ marginLeft: '-15px', marginTop: 1 }} src={TryboticsLogo} alt="Trybotics Logo" />
      </div>

      <Hidden xsDown>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            name="search"
            placeholder="Search by Keyword…"
            value={this.state.search}
            onChange={this.handleChange}
            onKeyDown={this.handleEnter}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput
            }}
          />
        </div>
      </Hidden>
      <div id="google_translate_element"></div>
      <IconButton
        style={{
          height: 60,
          width: 60,
          marginTop: this.props.user.isLogin ? -5 : 0,
          marginLeft: 5
        }}
        aria-haspopup="true"
        color="inherit"
        aria-label="Profile"
        onClick={this.handleProfileMenu}
      >
        {this.props.user.isLogin ? (
          <Avatar
            style={{
              border: "3px solid #ffffff",
              borderRadius: "50%",
              width: "35px",
              height: "35px"
            }}
            src={this.props.user.imageUrl}
          />
        ) : (
            <AccountCircleIcon style={{ fontSize: "30px" }} />
          )}
      </IconButton>
    </Toolbar>
    let shareMenu = <Drawer
      anchor="bottom"
      open={this.state.openWebShare}
      onClose={() => this.handleRequestClose()}
    >
      <center style={{ display: "block", paddingTop: 10, paddingBottom: 10 }}>
        <WhatsappShareButton style={{ width: 50, height: 50 }} url={shareLinkURL}>
          <WhatsappIcon size={40} round={true} />
        </WhatsappShareButton>
        <FacebookShareButton style={{ width: 50, height: 50 }} url={shareLinkURL}>
          <FacebookIcon size={40} round={true} />
        </FacebookShareButton>
        <FacebookMessengerShareButton style={{ width: 50, height: 50 }} url={shareLinkURL}>
          <FacebookMessengerIcon size={40} round={true} />
        </FacebookMessengerShareButton>
        <LinkedinShareButton style={{ width: 50, height: 50 }} url={shareLinkURL}>
          <LinkedinIcon size={40} round={true} />
        </LinkedinShareButton>
        <TwitterShareButton style={{ width: 50, height: 50 }} url={shareLinkURL}>
          <TwitterIcon size={40} round={true} />
        </TwitterShareButton>
        <EmailShareButton style={{ width: 50, height: 50 }} url={shareLinkURL}>
          <EmailIcon size={40} round={true} />
        </EmailShareButton>
        <WorkplaceShareButton style={{ width: 50, height: 50 }} url={shareLinkURL}>
          <WorkplaceIcon size={40} round={true} />
        </WorkplaceShareButton>
      </center>
    </Drawer>
    return (
      <div>
        {profileMenu}
        {shareMenu}
        <Hidden smUp>
          <HideOnScroll {...this.props}>
            <AppBar style={{ boxShadow: '0px 0px 0px 0px' }}>
              {mobileToolbar}
            </AppBar>
          </HideOnScroll>
        </Hidden>
        <div className={classes.headerMenuIcon}>
          <SwipeableDrawer
            open={this.state.left}
            onClose={this.toggleDrawer("left", false)}
            onOpen={this.toggleDrawer("left", true)}
          >
            {sideList("left")}
          </SwipeableDrawer>
        </div>
        <Hidden smUp>
          <AppBar style={{ zIndex: 1050, boxShadow: '0px 0px 0px 0px' }}>
            <Toolbar style={{ minHeight: "60px" }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={this.toggleDrawer("left", true)}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
              <div
                className={classes.search}
                style={{ marginLeft: "5px", marginRight: "6px" }}
              >
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  name="search"
                  placeholder="Search by Keyword…"
                  value={this.state.search}
                  onChange={this.handleChange}
                  onKeyDown={this.handleEnter}
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput
                  }}
                />
              </div>
            </Toolbar>
          </AppBar>
        </Hidden>
        <AppBar style={{ zIndex: 1000 }}>
          {mobileToolbar}
        </AppBar>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={this.props.snackbar.show}
          autoHideDuration={3000}
          onClose={this.props.closeSnackbar}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">{this.props.snackbar.msg}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.props.closeSnackbar}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />

        <ContactUs open={this.state.showContactUs} onClose={this.closeModel} />
        <RemoveContent open={this.state.showRemoveContent} onClose={this.closeModel} />
        <Login closeModel={this.closeModel} open={this.state.open} />
        <Profile
          user={this.props.user}
          closeModel={this.closeModel}
          open={this.state.showProfile}
        />
        <Post open={this.state.showPost} onClose={this.closePost} />
        <Fab
          aria-label='Share'
          className={classes.fab}
          color="primary"
          onClick={async () => {
            if (isAvailable) {
              await Share.share({
                title: 'Trybotics',
                text: 'Try Something Innovative',
                url: shareLinkURL,
                dialogTitle: 'Share via'
              }).then(result => {
                if (result == "Web Share API not available") {
                  this.setState({
                    openWebShare: true
                  })
                }
              }).catch(error => {
                console.log('error', error)
                this.setState({
                  openWebShare: true
                })
              })
            } else {
              this.setState({
                openWebShare: true
              })
            }
          }}
        >
          <ShareIcon />
        </Fab>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.Home.login.userDetails,
    snackbar: state.projectState.snackbar.show ? state.projectState.snackbar : state.blogState.snackbar.show ? state.blogState.snackbar : state.adState.snackbar.show ? state.adState.snackbar : state.youtubeState.snackbar.show ? state.youtubeState.snackbar : state.affiliateState.snackbar.show ? state.affiliateState.snackbar : state.Home.login.snackbar
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logOut: () => {
      return dispatch(logOut());
    },
    closeSnackbar: () => {
      return dispatch(closeSnackbar());
    }
  };
};

Header.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Header));
