import React from "react"
import PropTypes from "prop-types"
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card"
import Grid from "@material-ui/core/Grid"
import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import { Link } from "react-router-dom"
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress"
import CardMedia from "@material-ui/core/CardMedia";
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Paper from "@material-ui/core/Paper";
import { getNewProjects, getNewUsers, getNewInternships, getNewBlogs } from "../actions/Home"
import Login from "../components/Login"
import ContactUs from "../components/ContactUs"
import EventGallery from "../components/EventGallery";
import AssociateGallery from "../components/AssociateGallery";
import facebook from "../images/facebook.png"
import googleplus from "../images/googleplus.png"
import youtubeLogo from "../images/youtube.png"
import twitter from "../images/twitter.png"
import linkedin from "../images/linkedin.png"
import blogger from "../images/blogger.png"
import bg from "../images/bg.jpg"
import profilePic from "../images/profile_pic.jpg"
import { Hidden } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import clsx from "clsx";
import Slider from "react-slick";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import RoomIcon from "@material-ui/icons/Room";

import kit1 from "../images/kit1.jpg"
import kit2 from "../images/kit2.jpg"
import kit3 from "../images/kit3.jpg"
import kit4 from "../images/kit4.jpg"
import kit5 from "../images/kit5.jpg"
import kit6 from "../images/kit6.jpg"
import kit7 from "../images/kit7.jpg"
import kit8 from "../images/kit8.jpg"
import kit9 from "../images/kit9.jpg"
import kit10 from "../images/kit10.jpg"
import kit11 from "../images/kit11.jpg"
import kit12 from "../images/kit12.jpg"
import { Plugins } from "@capacitor/core";
import config from "../config";
var rootURL = config.rootURL;
const { Device } = Plugins;


function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ textAlign: 'justify', fontSize: '14px', padding: 8 * 3 }}>
      {children}
    </Typography>
  )
}

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: 0,
    },
  },
  input: {
    borderRadius: 0,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    width: 'auto',
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 0,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    }
  },
}))(InputBase);

const styles = theme => ({
  buttonBase: {
    backgroundColor: '#FFF',
    color: '#555',
    boxShadow: '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12) !important;',
    '&:hover': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      backgroundColor: '#FFF',
      color: '#2196f3'
    }
  },
  pvMain: {
    borderRadius: "50%",
    width: "130px",
    position: "absolute",
    height: "130px",
    bottom: "-50px",
    left: "50%",
    marginLeft: "-65px",
    webkitTransition: "all",
    oTransition: "all",
    transition: "all",
    webkitTransitionDuration: "300ms",
    transitionDuration: "300ms"
  },
  pageSlideAnimation: {
    visibility: "hidden",
    visibility: "visible9" /*For old IE browsers IE6-8 */,
    "&::after": {
      content: "",
      display: "table",
      clear: "both"
    }
  },
  pageSlide: {
    visibility: "visible",
    animation: "pageSlide 1s"
  },
  "@keyframes pageSlide": {
    "0%": {
      opacity: "0",
      transform: "translateY(150px)"
    },
    "100%": {
      opacity: "1",
      transform: "translateY(0px)"
    }
  },
  formHeader: {
    textAlign: "center",
    fontSize: "20px",
    backgroundColor: "#2196f3",
    color: "#fff",
    padding: "15px 12px",
    textTransform: "uppercase"
  },
  nounderline: {
    "& a": {
      textDecoration: 'none'
    }
  },
  follow: {
    position: 'relative',
    minHeight: '1px',
    paddingLeft: '10px',
    paddingRight: '10px',
    float: 'left',
  },
  search: {
    [theme.breakpoints.down('xs')]: {
      top: '5%',
      padding: '5%'
    },
    [theme.breakpoints.up('sm')]: {
      width: '70%',
      top: '45%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    },
    [theme.breakpoints.up('md')]: {
      width: '60%'
    }
  }
})

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
}


class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: 0,
      category: 'Category',
      search: '',
      youtubes: [],
      showLoginModel: false,
      showContactUsModel: false
    }
  }

  componentDidMount = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    document.title = "Try Something Innovative | Trybotics";
    this.props.getNewProjects(0)
    this.props.getNewInternships(1)
    this.props.getNewBlogs(0)
    this.props.getNewUsers(0)
    this.setState({ youtubes: ['ImIeZ6tlq80', 'rqFM1tyDh9o', 'g6oGSxDG4aI', 'P855IRK3fQU', 'Uu6sj8FFUVI', 'U3dDYT2dO2g'] }, () => {
      var vidDefer = document.getElementsByTagName('iframe');
      for (var i = 0; i < vidDefer.length; i++) {
        if (vidDefer[i].getAttribute('data-src')) {
          vidDefer[i].setAttribute('src', vidDefer[i].getAttribute('data-src'));
        }
      }
    })
    Device.getInfo().then(info => {
      console.log(info);
      this.setState({ platform: info.platform })
    }).catch(error => {
      console.log(error);
    })
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleChangeIndex = index => {
    this.setState({ value: index })
  }

  showLoginModel = () => {
    this.setState({ showLoginModel: true })
  }

  showContactUsModel = () => {
    this.setState({ showContactUsModel: true })
  }

  closeModel = () => {
    this.setState({ showLoginModel: false, showContactUsModel: false })
  }

  render() {
    const { classes } = this.props;
    const { youtubes } = this.state;
    let youtube = []
    let quadcopterProducts = [{ title: 'Quadcopter Kit', img: kit1 },
    { title: 'RC Remote', img: kit3 },
    { title: 'Flight Controller', img: kit2 },
    { title: 'Brushless Motor', img: kit5 },
    { title: 'Motor ESC', img: kit4 },
    { title: 'Lipo Battery', img: kit6 }
    ]
    let quadcopterProduct = []
    let roboticsProducts = [{ title: 'Arduino Kit', img: kit7 },
    { title: 'Arduino Uno', img: kit8 },
    { title: 'Bluetooth HC-05', img: kit9 },
    { title: 'ESP 8266', img: kit10 },
    { title: 'RF Module', img: kit12 },
    { title: 'RFID Module', img: kit11 }
    ]
    let roboticsProduct = []
    let slides = [
      { title: 'SEARCH WHATEVER YOU WANT', description: 'We provide guidance and training session on Artificial Intelligence and Machine Learning.', url: '', img: bg },
      // {title:'PROJECT GUIDANCE',description:'We provide guidance and training session on Advanced Electronics & Communication and Mechanical Designs.',url:'/mini-quadcopter',img:'https://atc.appxone.com/img/medium_banner/course_robotics_mediumbanner.jpg'},
      // {title:'Learn Arduino Programing',description:'We provide guidance and training session on Arduino Programing.',url:'',img:'https://atc.appxone.com/img/medium_banner/course_arduino_mediumbanner.jpg'},
      // {title:'RC QUADCOPTER DESIGNING',description:'We provide guidance and training session on Quadcopter Designing.',url:'',img:'https://dronewarehouse.ca/images/product/banner2.jpg'}
    ]
    for (let i in youtubes) {
      youtube.push(
        <Grid key={'youtube' + i} item xs={6} sm={4} md={2}>
          <Card style={{ margin: 10, padding: '10px 10px 5px 10px' }}>
            <iframe title={'Trybotics Tech Video ' + i} width="100%" src="" data-src={'https://www.youtube.com/embed/' + youtubes[i]} frameBorder="0" gesture="media" allow="encrypted-media" allowFullScreen></iframe>
          </Card>
        </Grid>
      )
    }
    for (let i in quadcopterProducts) {
      quadcopterProduct.push(
        <Grid key={'quadcopterProduct' + i} item xs={6} sm={4} md={2}>
          <Link style={{ all: 'initial' }} to="/buy">
            <Card style={{ margin: '10px' }}>
              <img alt={quadcopterProducts[i].title} width="100%" src={quadcopterProducts[i].img} />
              <Divider />
              <Typography style={{ padding: "10px", fontSize: '15px', fontWeight: '600' }} align="center" variant="h6" noWrap>
                {quadcopterProducts[i].title}
              </Typography>
            </Card>
          </Link>
        </Grid>
      )
    }
    for (let i in roboticsProducts) {
      roboticsProduct.push(
        <Grid key={'roboticsProduct' + i} item xs={6} sm={4} md={2}>
          <Link style={{ all: 'initial' }} to="/buy">
            <Card style={{ margin: '10px' }}>
              <img alt={roboticsProducts[i].title} width="100%" src={roboticsProducts[i].img} />
              <Divider />
              <Typography style={{ padding: "10px", fontSize: '15px', fontWeight: '600' }} align="center" variant="h6" noWrap>
                {roboticsProducts[i].title}
              </Typography>
            </Card>
          </Link>
        </Grid>
      )
    }
    let projectCard = []
    for (let i in this.props.newProjects) {
      if (this.props.newProjects[i].imageUrl) {
        projectCard.push(
          <Link key={'projectCard' + i} style={{ all: 'initial' }} to={'project/' + this.props.newProjects[i].id}>
            <Card style={{ marginLeft: 10, marginRight: 10, marginTop: 20, marginBottom: 20 }}>
              <img width="100%" height="160px" src={this.props.newProjects[i].imageUrl} alt={this.props.newProjects[i].title} />
              <Divider />
              <Typography style={{ padding: "10px", fontSize: '15px', fontWeight: 'bold' }} align="center" variant="h6" noWrap>
                {this.props.newProjects[i].title}
              </Typography>
            </Card>
          </Link>
        )
      }
    }
    let internshipCard = []
    for (let i in this.props.newInternships) {
      if (this.props.newInternships[i].logo) {
        internshipCard.push(
          <Link key={'internshipCard' + i} style={{ all: 'initial' }} to={'internship/' + this.props.newInternships[i].id}>
            <Card style={{ marginLeft: 10, marginRight: 10, marginTop: 20, marginBottom: 20 }}>
              <center>
                <img width="80px" height="80px" src={this.props.newInternships[i].logo} alt={this.props.newInternships[i].company} />
              </center>
              <Divider />
              <Typography style={{ padding: "5px", fontSize: '14px', fontWeight: 'bold' }} align="center" variant="h6" noWrap>
                {this.props.newInternships[i].role}
              </Typography>
            </Card>
          </Link>
        )
      }
    }
    let userCard = []
    for (let i in this.props.newUsers) {
      if (this.props.newUsers[i].name) {
        userCard.push(
          <div key={'newUsers' + i} style={{ all: 'initial' }}>
            <Card style={{ marginLeft: 10, marginRight: 10, marginTop: 20, marginBottom: 20 }}>
              <img width='100%'
                alt={this.props.newUsers[i].name.split(' ')[0]}
                src={this.props.newUsers[i].imageUrl}
                onError={(e) => {
                  if (e.target.src !== profilePic) {
                    e.target.onerror = null;
                    e.target.src = profilePic;
                  }
                }}
              />
              <Divider />
              <Typography style={{ padding: "5px", fontSize: '14px', fontWeight: 'bold' }} align="center" variant="h6" noWrap>
                {this.props.newUsers[i].name.split(' ')[0]}
              </Typography>
            </Card>
          </div>
        )
      }
    }
    let blogCard = []
    for (let i in this.props.newBlogs) {
      if (this.props.newBlogs[i].imageUrl) {
        blogCard.push(
          <Link key={'blogCard' + i} style={{ all: 'initial' }} to={'blog/' + this.props.newBlogs[i].id}>
            <Card style={{ marginLeft: 10, marginRight: 10, marginTop: 20, marginBottom: 20 }}>
              <img width="100%" height="160px" src={this.props.newBlogs[i].imageUrl} alt={this.props.newBlogs[i].title} />
              <Divider />
              <Typography style={{ padding: "10px", fontSize: '15px', fontWeight: 'bold' }} align="center" variant="h6" noWrap>
                {this.props.newBlogs[i].title}
              </Typography>
            </Card>
          </Link>
        )
      }
    }
    var settings = {
      dots: false,
      autoplay: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

    return (
      <Container disableGutters={true} maxWidth="lg" className={clsx(classes.pageSlideAnimation, classes.pageSlide)}>
        <Grid style={{ margin: 'auto' }} justify='center' container spacing={0}>
          <Grid item xs={12} sm={12}>
            <Card>
              {slides.map((slide, index) => {
                return <Slider key={'slide' + index} {...settings}>
                  <div>
                    <CardMedia style={{ height: 400 }} image={slide.img} />
                    <div className={clsx(classes.search)} style={{ position: 'absolute', textAlign: 'center' }}>
                      <Paper className='homeSearchInput' style={{ display: 'flex', alignItems: 'center', margin: 'auto', marginTop: 70, boxShadow: '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)' }} elevation={1}>
                        <InputBase
                          name="search"
                          onChange={this.handleChange}
                          onKeyDown={event => {
                            if (event.keyCode == 13 && event.shiftKey == false) {
                              this.props.history.push({
                                pathname: (this.state.category == "Category" ? 'project' : this.state.category),
                                search: '?search=' + this.state.search
                              })
                            }
                          }}
                          style={{ flex: 1, marginLeft: 8, marginTop: 1, width: '100%', height: 48 }}
                          placeholder="Search by Keyword…"
                        />
                        <FormControl>
                          <Select
                            id="category"
                            name="category"
                            value={this.state.category}
                            onChange={event => { this.setState({ category: event.target.value }) }}
                            input={<BootstrapInput name="category" id="category" name="age" />}
                            SelectDisplayProps={{
                              style: { paddingTop: 12, paddingBottom: 16 }
                            }}
                          >
                            <MenuItem value="Category">
                              Category
                            </MenuItem>
                            <MenuItem value="project">Tech Projects</MenuItem>
                            <MenuItem value="internship">Internships</MenuItem>
                            <MenuItem value="blog">Tech Blogs</MenuItem>
                            <MenuItem value="buy">Electronic Parts</MenuItem>
                            <MenuItem value="youtube">Tech Videos</MenuItem>
                            <MenuItem value="ad">Ads</MenuItem>
                          </Select>
                        </FormControl>
                        <Hidden xsDown>
                          <Link style={{ all: 'initial' }} to={
                            {
                              pathname: (this.state.category == "Category" ? 'project' : this.state.category),
                              search: '?search=' + this.state.search
                            }
                          }>
                            <Button style={{ margin: 0, fontSize: 17, paddingTop: 10, paddingBottom: 10, borderRadius: 0 }} variant="contained" color="primary">
                              Search
                            </Button>
                          </Link>
                        </Hidden>
                      </Paper>
                      <Hidden smUp>
                        <Link style={{ all: 'initial' }} to={(this.state.category == "Category" ? 'project' : this.state.category) + '?search=' + this.state.search}>
                          <Button style={{ margin: 0, fontSize: 17, paddingTop: 10, paddingBottom: 10, borderRadius: 0, marginTop: 10 }} variant="contained" color="primary">
                            Search
                          </Button>
                        </Link>
                      </Hidden>
                      <div style={{ marginTop: '10px' }}>
                        {this.state.platform == "web" &&
                          <a style={{ all: "initial" }} href={rootURL + "/service/"}>
                            <Button className={classes.buttonBase} style={{ marginLeft: 5, marginRight: 5, padding: 11, paddingLeft: 10, paddingRight: 10, marginBottom: 10, fontSize: 15 }}>
                              SERVICE
                            </Button>
                          </a>
                        }
                        <Link style={{ all: 'initial' }} to="/project">
                          <Button className={classes.buttonBase} style={{ marginLeft: 5, marginRight: 5, padding: 11, paddingLeft: 10, paddingRight: 10, marginBottom: 10, fontSize: 15 }}>
                            PROJECT
                          </Button>
                        </Link>
                        <Link style={{ all: 'initial' }} to="/internship">
                          <Button className={classes.buttonBase} style={{ marginLeft: 5, marginRight: 5, padding: 11, paddingLeft: 10, paddingRight: 10, marginBottom: 10, fontSize: 15 }}>
                            INTERNSHIP
                          </Button>
                        </Link>
                        <Link style={{ all: 'initial' }} to="/blog">
                          <Button className={classes.buttonBase} style={{ marginLeft: 5, marginRight: 5, padding: 11, paddingLeft: 10, paddingRight: 10, marginBottom: 10, fontSize: 15 }}>
                            BLOG
                          </Button>
                        </Link>
                        <Link style={{ all: 'initial' }} to="/buy">
                          <Button className={classes.buttonBase} style={{ marginLeft: 5, marginRight: 5, padding: 11, paddingLeft: 10, paddingRight: 10, marginBottom: 10, fontSize: 15 }}>
                            ELECTRONIC
                          </Button>
                        </Link>
                        <Link style={{ all: 'initial' }} to="/youtube">
                          <Button className={classes.buttonBase} style={{ marginLeft: 5, marginRight: 5, padding: 11, paddingLeft: 10, paddingRight: 10, marginBottom: 10, fontSize: 15 }}>
                            VIDEO
                          </Button>
                        </Link>
                        {this.state.platform == "web" &&
                          <a style={{ all: "initial" }} href={rootURL + "/resume-builder"}>
                            <Button className={classes.buttonBase} style={{ marginLeft: 5, marginRight: 5, padding: 11, paddingLeft: 10, paddingRight: 10, marginBottom: 10, fontSize: 15 }}>
                              RESUME BUILDER
                            </Button>
                          </a>
                        }
                        {this.state.platform == "web" &&
                          <a style={{ all: "initial" }} href={rootURL + "/covid19-india/"}>
                            <Button className={classes.buttonBase} style={{ marginLeft: 5, marginRight: 5, padding: 11, paddingLeft: 10, paddingRight: 10, marginBottom: 10, fontSize: 15 }}>
                              COVID19 INDIA
                            </Button>
                          </a>
                        }
                      </div>
                    </div>
                  </div>
                </Slider>
              })
              }
            </Card>
          </Grid>
        </Grid>
        <Divider style={{ marginTop: '20px', marginBottom: '20px' }} light />
        <Typography align="center" color='inherit' variant="h5">
          RECENT TECH PROJECTS
        </Typography>
        <Grid className={clsx(classes.pageSlideAnimation, classes.pageSlide)} justify='center' container spacing={0}>
          <Grid item xs={12} sm={12}>
            <div className={classes.nounderline}>
              {
                (projectCard.length > 0) ?
                  <Slider
                    dots={false}
                    arrows={false}
                    autoplay={true}
                    lazyLoad={true}
                    infinite={true}
                    speed={1500}
                    slidesToShow={6}
                    slidesToScroll={1}
                    initialSlide={0}
                    responsive={[
                      {
                        breakpoint: 770,
                        settings: {
                          slidesToShow: 4,
                          slidesToScroll: 1
                        }
                      },
                      {
                        breakpoint: 430,
                        settings: {
                          slidesToShow: 2,
                          slidesToScroll: 1
                        }
                      }
                    ]}

                  >{projectCard}
                  </Slider>
                  : <center><CircularProgress size={50} /></center>
              }
            </div>
          </Grid>
          <center style={{ marginTop: '10px', marginBottom: '10px' }}>
            <Link style={{ all: 'initial' }} to="/project">
              <Button variant="contained" size="large" color="primary" style={{paddingTop: 11, paddingBottom: 11}} >
                View More
              </Button>
            </Link>
          </center>
        </Grid>
        <Divider style={{ marginTop: '20px', marginBottom: '20px' }} light />
        <Typography align="center" color='inherit' variant="h5">
          RECENT INTERNSHIPS
        </Typography>
        <Grid className={clsx(classes.pageSlideAnimation, classes.pageSlide)} justify='center' container spacing={0}>
          <Grid item xs={12} sm={12}>
            <div className={classes.nounderline}>
              {
                (internshipCard.length > 0) ?
                  <Slider
                    dots={false}
                    arrows={false}
                    autoplay={true}
                    infinite={true}
                    speed={1000}
                    slidesToShow={9}
                    slidesToScroll={1}
                    initialSlide={0}
                    responsive={[
                      {
                        breakpoint: 770,
                        settings: {
                          slidesToShow: 6,
                          slidesToScroll: 1
                        }
                      },
                      {
                        breakpoint: 430,
                        settings: {
                          slidesToShow: 3,
                          slidesToScroll: 1
                        }
                      }
                    ]}
                  >{internshipCard}
                  </Slider>
                  : <center><CircularProgress size={50} /></center>
              }
            </div>
          </Grid>
          <center style={{ marginTop: '10px', marginBottom: '10px' }}>
            <Link style={{ all: 'initial' }} to="/internship">
              <Button variant="contained" size="large" color="primary" style={{paddingTop: 11, paddingBottom: 11}} >
                View More
              </Button>
            </Link>
          </center>
        </Grid>
        <Divider style={{ marginTop: '20px', marginBottom: '20px' }} light />
        <Typography align="center" color='inherit' variant="h5">
          RECENT TECH BLOGS
        </Typography>
        <Grid className={clsx(classes.pageSlideAnimation, classes.pageSlide)} justify='center' container spacing={0}>
          <Grid item xs={12} sm={12}>
            <div className={classes.nounderline}>
              {
                (blogCard.length > 0) ?
                  <Slider
                    dots={false}
                    arrows={false}
                    autoplay={true}
                    lazyLoad={true}
                    infinite={true}
                    speed={1500}
                    slidesToShow={6}
                    slidesToScroll={1}
                    initialSlide={0}
                    responsive={[
                      {
                        breakpoint: 770,
                        settings: {
                          slidesToShow: 4,
                          slidesToScroll: 1
                        }
                      },
                      {
                        breakpoint: 430,
                        settings: {
                          slidesToShow: 2,
                          slidesToScroll: 1
                        }
                      }
                    ]}

                  >{blogCard}
                  </Slider>
                  : <center><CircularProgress size={50} /></center>
              }
            </div>
          </Grid>
          <center style={{ marginTop: '10px', marginBottom: '10px' }}>
            <Link style={{ all: 'initial' }} to="/blog">
              <Button variant="contained" size="large" color="primary" style={{paddingTop: 11, paddingBottom: 11}} >
                View More
              </Button>
            </Link>
          </center>
        </Grid>
        <Divider style={{ marginTop: '20px', marginBottom: '20px' }} light />
        <Typography align="center" color='inherit' variant="h5">
          QUADCOPTER PARTS
        </Typography>
        <Grid style={{ margin: 'auto', marginTop: '10px' }} className={clsx(classes.pageSlideAnimation, classes.pageSlide)} justify='center' container spacing={0}>
          {
            quadcopterProduct
          }
          <center style={{ marginTop: '20px', marginBottom: '10px' }}>
            <Link style={{ all: 'initial' }} to="/buy">
              <Button variant="contained" size="large" color="primary" style={{paddingTop: 11, paddingBottom: 11}} >
                View More
              </Button>
            </Link>
          </center>
        </Grid>
        <Divider style={{ marginTop: '20px', marginBottom: '20px' }} light />
        <Typography align="center" color='inherit' variant="h5">
          ELECTRONIC PARTS
        </Typography>
        <Grid style={{ margin: 'auto', marginTop: '10px' }} className={clsx(classes.pageSlideAnimation, classes.pageSlide)} justify='center' container spacing={0}>
          {
            roboticsProduct
          }
          <center style={{ marginTop: '20px', marginBottom: '10px' }}>
            <Link style={{ all: 'initial' }} to="/buy">
              <Button variant="contained" size="large" color="primary" style={{paddingTop: 11, paddingBottom: 11}} >
                View More
              </Button>
            </Link>
          </center>
        </Grid>
        <Divider style={{ marginTop: '20px', marginBottom: '20px' }} light />
        <Typography align="center" color='inherit' variant="h5">
          RECENT TECH VIDEOS
        </Typography>
        <Grid style={{ margin: 'auto', marginTop: '10px' }} className={clsx(classes.pageSlideAnimation, classes.pageSlide)} justify='center' container spacing={0}>
          {
            youtube
          }
          <center style={{ marginTop: '20px', marginBottom: '10px' }}>
            <Link style={{ all: 'initial' }} to="/youtube">
              <Button variant="contained" size="large" color="primary" style={{paddingTop: 11, paddingBottom: 11}} >
                Watch More
              </Button>
            </Link>
          </center>
        </Grid>
        <Divider style={{ marginTop: '20px', marginBottom: '20px' }} light />
        <AssociateGallery />
        <Divider style={{ marginTop: '20px', marginBottom: '20px' }} light />
        <Typography align="center" color='inherit' variant="h5">
          RECENT USERS
        </Typography>
        <Grid className={clsx(classes.pageSlideAnimation, classes.pageSlide)} justify='center' container spacing={0}>
          <Grid item xs={12} sm={12}>
            <div className={classes.nounderline}>
              {
                (userCard.length > 0) ?

                  <Slider
                    dots={false}
                    arrows={false}
                    autoplay={true}
                    infinite={true}
                    speed={600}
                    slidesToShow={12}
                    slidesToScroll={1}
                    initialSlide={0}
                    responsive={[
                      {
                        breakpoint: 770,
                        settings: {
                          slidesToShow: 8,
                          slidesToScroll: 1
                        }
                      },
                      {
                        breakpoint: 430,
                        settings: {
                          slidesToShow: 4,
                          slidesToScroll: 1
                        }
                      }
                    ]}
                  >{userCard}
                  </Slider>
                  : <center><CircularProgress size={50} /></center>
              }
            </div>
          </Grid>
          <center style={{ marginTop: '0px', marginBottom: '10px' }}>
            <Button onClick={this.showLoginModel} variant="contained" size="large" color="primary" style={{paddingTop: 11, paddingBottom: 11}} >
              Be Our User
            </Button>
          </center>
        </Grid>
        <Divider style={{ marginTop: '20px', marginBottom: '20px' }} light />
        <EventGallery />
        <Divider style={{ marginTop: '20px', marginBottom: '20px' }} light />

        <Grid style={{ margin: 'auto', marginTop: '10px' }} className={clsx(classes.pageSlideAnimation, classes.pageSlide)} justify='center' container spacing={0}>
          <Grid item sm={12} md={4}>
            <Card style={{ margin: '10px', paddingBottom: '20px' }}>
              <div className={classes.formHeader}>Office Details</div>
              <iframe title='Trybotics Map Location' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3683.5362719742693!2d88.3385813149254!3d22.596440985170524!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a0277e901d5881d%3A0x72d203109b859187!2sTrybotics!5e0!3m2!1sen!2sin!4v1539549920833" width="100%" height="300" frameBorder="0" style={{ border: 0 }} allowFullScreen></iframe>
              <ul style={{ margin: '20px', padding: '0', listStyle: 'none' }}>
                <li style={{ display: 'inline-block', margin: '0 5px', fontSize: '15px' }}><PhoneIcon style={{ width: '15px', height: '15px' }} />&nbsp; +91 8961677327</li>
                <li style={{ display: 'inline-block', margin: '0 5px', fontSize: '15px' }}><EmailIcon style={{ width: '15px', height: '15px' }} />&nbsp; admin@trybotics.com</li>
                <li style={{ display: 'inline-block', margin: '0 5px', fontSize: '15px' }}><RoomIcon style={{ width: '15px', height: '15px' }} />&nbsp; 83/1, Jelia Para Lane, Salkia, Howrah, West Bengal 711106</li>

              </ul>
            </Card>
          </Grid>
          <Grid item sm={6} md={4}>
            <Card style={{ margin: '10px' }}>
              <ContactUs open={false} />
            </Card>
          </Grid>
          <Grid item sm={6} md={4}>
            <Card style={{ margin: '10px' }}>
              <div className={classes.formHeader}>Follow Us</div>
              <Divider light />
              <div style={{ float: 'left', paddingLeft: '40px', marginTop: '10px', marginBottom: '10px' }}>
                <a rel="noopener" className={classes.follow} href="https://www.facebook.com/trybotics" target="_blank" title="Follow Us On Facebook">
                  <img height="80" width="80" src={facebook} className="img-responsive round" alt="Facebook" />
                </a>
                <a rel="noopener" className={classes.follow} href="https://plus.google.com/communities/100941537276217672781" target="_blank" title="Follow Us On GooglePlus">
                  <img height="80" width="80" src={googleplus} className="img-responsive round" alt="GooglePlus" />
                </a>
                <a rel="noopener" className={classes.follow} href="https://www.youtube.com/trybotics" target="_blank" title="Follow Us On Youtube">
                  <img height="80" width="80" src={youtubeLogo} className="img-responsive round" alt="Youtube" />
                </a>
                <a rel="noopener" className={classes.follow} href="" target="_blank" title="Follow Us On Twitter">
                  <img height="80" width="80" src={twitter} className="img-responsive round" alt="Twitter" />
                </a>
                <a rel="noopener" className={classes.follow} href="https://www.linkedin.com/company/trybotics" target="_blank" title="Follow Us On Linkedin">
                  <img height="80" width="80" src={linkedin} className="img-responsive round" alt="Linkedin" />
                </a>
                <a rel="noopener" className={classes.follow} href="https://trybotics.blogspot.com" target="_blank" title="Follow Us On Blogger">
                  <img height="80" width="80" src={blogger} className="img-responsive round" alt="Blogger" />
                </a>
              </div>
            </Card>
          </Grid>
        </Grid>

        <ContactUs
          open={this.state.showContactUsModel}
          onClose={this.closeModel}
        />
        <Login
          closeModel={this.closeModel}
          open={this.state.showLoginModel}
        />
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    newProjects: state.Home.login.newProjects,
    newInternships: state.Home.login.newInternships,
    newUsers: state.Home.login.newUsers,
    newBlogs: state.Home.login.newBlogs,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getNewProjects: (skip) => {
      return dispatch(getNewProjects(skip))
    },
    getNewInternships: (skip) => {
      return dispatch(getNewInternships(skip))
    },
    getNewUsers: (skip) => {
      return dispatch(getNewUsers(skip))
    },
    getNewBlogs: (skip) => {
      return dispatch(getNewBlogs(skip))
    },
  }
}

Home.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Home))