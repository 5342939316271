import {
  SET_INTERNSHIP_DATA,
  SET_INTERNSHIP_HAS_MORE,
  SET_INTERNSHIP_DETAILS,
  SET_FETCHING_INTERNSHIP_DETAILS
} from "./ActionType";
import axios from "axios";

import config from "../config";
var apiRoot = config.api.root, noDataFound = 0;

export const getInternshipData = (page, search, refresh) => {
  return dispatch => {
    let url = search
      ? apiRoot + "/api/internship" + search + "&page=" + page
      : apiRoot + "/api/internship?page=" + page;
    return axios.get(url).then(
      response => {
        if (response.data.length > 0) {
          dispatch(setInternshipData(response.data, refresh));
          noDataFound = 0;
        } else {
          if(noDataFound>=2){
            dispatch(setInternshipHasMore(false));
          } else {
            dispatch(setInternshipData([], refresh));
            noDataFound = noDataFound + 1;
          }
          console.log("No data");
        }
      },
      error => {
        console.log(error);
      }
    );
  };
};

export const getInternshipDetails = id => {
  return dispatch => {
    dispatch(setFetchingInternshipDetails(true));
    return axios.get(apiRoot + "/api/internship/" + id).then(
      response => {
        dispatch(setInternshipDetails(response.data));
        dispatch(setFetchingInternshipDetails(false));
      },
      error => {
        console.log(error);
        dispatch(setFetchingInternshipDetails(false));
      }
    );
  };
};

export const setInternshipData = (data, refresh) => {
  return {
    type: SET_INTERNSHIP_DATA,
    data,
    refresh
  };
};

export const setInternshipHasMore = data => {
  return {
    type: SET_INTERNSHIP_HAS_MORE,
    data
  };
};

export const setFetchingInternshipDetails = data => {
  return {
    type: SET_FETCHING_INTERNSHIP_DETAILS,
    data
  };
};

export const setInternshipDetails = data => {
  return {
    type: SET_INTERNSHIP_DETAILS,
    data
  };
};
