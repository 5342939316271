import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Avatar from "@material-ui/core/Avatar";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import SwipeableViews from "react-swipeable-views";
import Container from "@material-ui/core/Container";
import CardMedia from "@material-ui/core/CardMedia";
import clsx from "clsx";
import { Link } from "react-router-dom";
import img1 from "../images/mini_quadcopter1.jpg";
import img2 from "../images/mini_quadcopter2.jpg";
import img3 from "../images/mini_quadcopter3.jpg";
import img13 from "../images/kit13.jpg";
import img14 from "../images/kit14.jpg";
import img15 from "../images/kit15.jpg";
import img16 from "../images/kit16.jpg";
import Slider from "react-slick";

function TabContainer({ children, dir }) {
  return (
    <Typography
      component="div"
      dir={dir}
      style={{ padding: 8 * 3, fontSize: "13px", textAlign: "justify" }}
    >
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired
};

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500
  },
  pageSlideAnimation: {
    visibility: "hidden",
    visibility: "visible9" /*For old IE browsers IE6-8 */,
    "&::after": {
      content: "",
      display: "table",
      clear: "both"
    }
  },
  pageSlide: {
    visibility: "visible",
    animation: "pageSlide 1s"
  },
  "@keyframes pageSlide": {
    "0%": {
      opacity: "0",
      transform: "translateY(150px)"
    },
    "100%": {
      opacity: "1",
      transform: "translateY(0px)"
    }
  }
});

class MiniQuadcopter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      expanded: null
    };
  }

  componentDidMount = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    document.title = "Mini Quadcopter | Trybotics";
  };

  handleChangeTab = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  handleChangeExpand = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    });
  };

  render() {
    const { classes, theme } = this.props;
    const { expanded } = this.state;
    let products = [
      {
        title: "Coreless Motor",
        img: img13,
        url: "buy/19"
      },
      {
        title: "Flight Controller",
        img: img14,
        url: "buy/21"
      },
      {
        title: "RC Remote",
        img: img15,
        url: "buy/23"
      },
      {
        title: "Lipo Battery",
        img: img16,
        url: "buy/20"
      }
    ];
    let product = [];
    for (let i in products) {
      product.push(
        <Grid item xs={6} sm={6} md={3}>
          <Link style={{ all: "initial" }} to={products[i].url}>
            <Card
              style={{
                margin: "10px",
                paddingTop: "10px",
                paddingBotton: "10px"
              }}
            >
              <img width="100%" src={products[i].img} />
              <Divider />
              <Typography
                style={{
                  fontWeight: "bold",
                  marginBottom: "3px",
                  marginTop: "10px",
                  padding: "5px",
                  fontSize: "15px"
                }}
                align="center"
                color="inherit"
              >
                {products[i].title}
              </Typography>
            </Card>
          </Link>
        </Grid>
      );
    }
    var settings = {
      dots: false,
      autoplay: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

    return (
      <Container disableGutters={true} maxWidth="lg" className={clsx(classes.pageSlideAnimation, classes.pageSlide)}>
        <Typography
          style={{ marginTop: "5px", paddingBottom: "20px", clear: "both" }}
          align="center"
          color="inherit"
          variant="h5"
          component="h1"
        >
          LEARN TO MAKE MINI QUADCOPTER
        </Typography>
        <div className="row" style={{ clear: "both" }}>
          <Card>
            <Grid
              style={{ margin: "auto", clear: "both" }}
              alignItems="center"
              container
              spacing={0}
            >
              <Grid
                style={{ padding: "10px", borderBottom: "none" }}
                item
                xs={12}
                sm={6}
              >
                <Slider {...settings}>
                  {" "}
                  <div>
                    <img width="100%" height="440px" src={img1} />
                  </div>
                  <div>
                    <img width="100%" height="440px" src={img2} />
                  </div>
                  <div>
                    <img width="100%" height="440px" src={img3} />
                  </div>
                </Slider>
              </Grid>
              <Grid style={{ padding: 10 }} item xs={12} sm={6}>
                <div className="borderOnDesktop">
                  <div position="static" color="default">
                    <Tabs
                      value={this.state.value}
                      onChange={this.handleChangeTab}
                      indicatorColor="primary"
                      textColor="primary"
                      fullWidth
                    >
                      <Tab label="Quadcopter Kits" />
                      <Tab label="Description" />
                      <Tab label="Specifications" />
                    </Tabs>
                  </div>
                  <div>
                    <SwipeableViews
                      axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                      index={this.state.value}
                      onChangeIndex={this.handleChangeIndex}
                    >
                      <TabContainer dir={theme.direction}>
                        <Chip
                          avatar={
                            <Avatar>
                              <DoneAllIcon />{" "}
                            </Avatar>
                          }
                          style={{ margin: "10px" }}
                          label="4 X Core Less Motor"
                        />
                        <Chip
                          avatar={
                            <Avatar>
                              <DoneAllIcon />{" "}
                            </Avatar>
                          }
                          style={{ margin: "10px" }}
                          label="1 X RC Remote Transmitter"
                        />
                        <Chip
                          avatar={
                            <Avatar>
                              <DoneAllIcon />{" "}
                            </Avatar>
                          }
                          style={{ margin: "10px" }}
                          label="1 X Lipo Battery 3.7v"
                        />
                        <Chip
                          avatar={
                            <Avatar>
                              <DoneAllIcon />{" "}
                            </Avatar>
                          }
                          style={{ margin: "10px" }}
                          label="1 X Lipo Battery Charger 3.7v"
                        />
                        <Chip
                          avatar={
                            <Avatar>
                              <DoneAllIcon />{" "}
                            </Avatar>
                          }
                          style={{ margin: "10px" }}
                          label="1 X Flight Controller Board"
                        />
                        <Chip
                          avatar={
                            <Avatar>
                              <DoneAllIcon />{" "}
                            </Avatar>
                          }
                          style={{ margin: "10px" }}
                          label="Hot Glue Gun"
                        />
                        <br />
                        <Chip
                          avatar={
                            <Avatar>
                              <DoneAllIcon />{" "}
                            </Avatar>
                          }
                          style={{ margin: "10px" }}
                          label="Sholdering Iron"
                        />
                        <Chip
                          avatar={
                            <Avatar>
                              <DoneAllIcon />{" "}
                            </Avatar>
                          }
                          style={{ margin: "10px" }}
                          label="Pen Refill"
                        />
                      </TabContainer>
                      <TabContainer dir={theme.direction}>
                        Fpv (First Person View) is one of the best experiences
                        in the rc world. I love building 250 size quadcopters
                        because of their speed and fpv capabilities, but they're
                        too big! I can almost never fly them if there is anyone
                        nearby because of the bulky size and dangerous props. In
                        addition, a bad crash with a 100mm size quadcopter can
                        leave you thousand of dollars down the drain. My
                        solution was to build The Worlds Smallest Drone, however
                        it was too small! The tiny motors couldn't muscle enough
                        force to lift an fpv camera off the ground. To solve
                        this I went a little bigger in size, using the
                        impressive 8.5mm motors. The result was amazing! I can
                        fly fpv inside my own house and not have to worry about
                        nasty crashes! Overall I spent about Rs. 1500 on this
                        quad (Not counting the parts I had) and let me tell you,
                        its worth every penny!
                        <br />
                        <br />
                        Here is an opportunity to explore the new world of
                        Robotics and flying copter, avionics. Build your own
                        Quadcopters models with various features build in the
                        modules and opportunity for the enthusiasts to learn the
                        various technologies which includes control systems,
                        programming algorithms, navigation system, sensors etc.
                      </TabContainer>
                      <TabContainer dir={theme.direction}>
                        The F100 Frame based quadcopter is a versatile DIY kit
                        that can be used to create a Quadcopter for both fun and
                        creating professional aerial views. The multirotor kit
                        is actually designed as a frame which makes it extremely
                        light and user friendly given the fact that owners can
                        add or remove additional elements. Be aware that this
                        DIY model requires medium to advanced building
                        knowledge. The kit is delivered in pieces and requires
                        wiring, soldering and some basic tools like soldering
                        materials, glue gun, multimeter, screwdriver etc. This
                        kit is not a ready to fly kit and requires tuning,
                        practice and patience. Once you manage to put it all
                        together, its actually pretty fun to own as it does a
                        lot of cool stuff while holding enough space for
                        additional components. You can hover, roll (requires
                        practice), cruise and use it for a lot of aeromodelling
                        activities.
                      </TabContainer>
                    </SwipeableViews>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Card>
          <Divider style={{ marginTop: "20px", marginBottom: "20px" }} light />
          <Typography align="center" color="inherit" variant="h5" component="h3">
            STEPS HOW TO MAKE MINI QUADCOPTER
          </Typography>
          <div style={{ marginTop: "20px" }}>
            <ExpansionPanel
              expanded={expanded === "panel1"}
              onChange={this.handleChangeExpand("panel1")}
            >
              <ExpansionPanelSummary expandIcon={<KeyboardArrowDownIcon />}>
                <Typography variant="subheading">
                  Step 1 (Introduction)
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography style={{ textAlign: "justify", fontSize: "13px" }}>
                  Micro Scisky Flight Controller This is the standard flight
                  controller based of the Naze FC many people use for micro
                  quads, is light its powerful and fun to fly! 8.5mm Motors +
                  Prop Combo A very good deal, You get 4 Coreless motors 2x
                  Clockwise Motor(With Red Blue), 2x Anti-clockwise Motor(With
                  Black White) and 8 Propellers. 3.7v Battery You get 7x 750 Mah
                  Batteries! It even comes with a charger, You will not find a
                  better deal than that A DSM2 TX I use the MKron T - SIX i6S It
                  was a lot cheaper than buying a Tyranis dsm2 module FPV Gear{" "}
                  <br />
                  <br />
                  FPV Goggles A great deal and it comes with its own battery.
                  Its a little difficult to shange the channels, I actually
                  ended up snapping the DIP switch with my genius idea to use a
                  mini screwdriver to flip the switches. FPV TX and FPV Camera
                  Although this turned to be quite heavy, had I done it
                  differently I would have used the Spektrum FPV Micro Camera.
                  Ive heard its a great overall deal and had I built this quad
                  again I would use the Spektrum. And various tools im assuming
                  you already have (Soldering Iron, Hot glue gun, Heatshrink).
                  <br />
                  <br />I also used a 3d Printer for the frame, But if you
                  wanted to I've seen people make micro quad frames out of
                  Popsicle sticks! It doesn't have to be perfect, any rough
                  shape of a quadcopter will work if its light enough. Side note
                  you may have noticed a sudden jump in my video quality, I
                  attribute that to my new tripod . Until November 24 there is
                  a deal and you can get it 10% off! (use the Coupon: 7617f5
                  before the deadline:Nov 24th). Now lets get to the build!
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === "panel2"}
              onChange={this.handleChangeExpand("panel2")}
            >
              <ExpansionPanelSummary expandIcon={<KeyboardArrowDownIcon />}>
                <Typography variant="subheading">
                  Step 2 (Making Guidance)
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography style={{ textAlign: "justify", fontSize: "13px" }}>
                  Depending on where you buy your motors, most motors follow a
                  typical Blue/Red: Clockwise Black/White: Counter Clockwise
                  Format. If your unsure, I would check with you dealer or read
                  the items description. Normally Red is Positive and Blue is
                  Negative, White is Positive and Black is negative. But once
                  again if you buy your motors from somewhere else, you could be
                  on your own. Check and double check to make sure you don't
                  create a short on accident <br />
                  <br />
                  Wiring the Flight Controller is quite easy. The board is
                  clearly marked with a 3.7v positive and negative in the dead
                  center for the battery. Using a female JST connector, I
                  soldered the wires to the respective terminals The Motor
                  terminals were also clearly marked, with make wiring a breeze.
                  Simply solder motor 1 to the motor 1 mount, motor 2 to the
                  motor 2 mount and so on... And that was it! With the pack of
                  3.7v batteries that came with a charger, however they came
                  with a different connector. To solve this, I cut the leads of
                  the lipo (Making sure not to short it out) and soldered on a
                  male JST connector,since the FC got a male JST connector. To
                  add the FPV camera I plugged the camera into the fpv tx and
                  soldered the 3.7-5v input straight to the flight controllers
                  input so they "shared" the battery in parallel. The fpv Tx
                  operates on 3.7, that's the same voltage were using.
                  Perfect!!!
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === "panel3"}
              onChange={this.handleChangeExpand("panel3")}
            >
              <ExpansionPanelSummary expandIcon={<KeyboardArrowDownIcon />}>
                <Typography variant="subheading">Step 3 (Warnings)</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography style={{ textAlign: "justify", fontSize: "13px" }}>
                  This is the most difficult part of the build and requires
                  patience (You will get through it!). First I plugged the fc
                  into CleanFlight and began the programming Be sure not to plug
                  in the battery at the same time as the USB plug as it can fry
                  your board. Once I plugged in the board, I flashed the fc with
                  the latest NAZE software and connected to cleanflight. In
                  CleanFlight, first I choose Quad X and enabled motor stop. I
                  wandered on over to the Receiver page and checked RX_PPM and
                  the channel map TAER 1234, if the channels are out of order
                  you can rearrange the order by clicking and typing something
                  like AETR1234(I hope that saves you the annoyance that caused
                  me). I set minimum throttle 1000, mid throttle 1500, Maximum
                  throttle 2000, Minimum command 1000. Strangely I used a
                  failsafe drop on this quad. I decided the frame was sturdy
                  enough to take a drop and didn't want it to slowly hover down.{" "}
                  <br />
                  <br />
                  In the Modes tab, Click add range and then using the channels
                  on your TX add modes. Keep in mind you must add an Arming
                  Switch for the micro scisky. Next I chose a flight mode,
                  Normally I stick to acro (No flight modes enabled) However
                  this is your quad! Choose any mode you like to fly in. I
                  recommend Horizon to learn to fly. In the CLI tap Type: set
                  motor_pwm_rate=23200 (others use 32000) Then: save press enter
                  Then Tune PID to your likings and save! Dont forget to save on
                  every page or your work will be lost!
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === "panel4"}
              onChange={this.handleChangeExpand("panel4")}
            >
              <ExpansionPanelSummary expandIcon={<KeyboardArrowDownIcon />}>
                <Typography variant="subheading">
                  Step 4 (Demo Test Flight)
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography style={{ textAlign: "justify", fontSize: "13px" }}>
                  Next it was time to bind the fc and the TX. Plug in the Scisky
                  and wait 10 seconds for the red light to begin flashing. This
                  means your in Bind mode, so Power on the TX and hold down the
                  bind switch on the back. Once the light turns solid red, your
                  ready for Props! The Props I used were tri blade for better
                  thrust and came with the 8.5mm motors. An easy trick to know
                  with propeller goes to which motor is to envision them
                  spinning. Imagine the propellor is a spinning threaded bolt,
                  and the air is a nut. find the trailing edge and imagine which
                  way to "screw" the propellor into the air Clockwise or Counter
                  Clockwise. Then take those and place them onto the
                  corresponding motors! All four should screw the quad upwards
                  through the air on their respective motor. Plug the battery
                  in, connect the fpv camera and your goggles and let the
                  throttle rip! Remember your not flying around a massive quad,
                  take the micro in tight spaces and indoors!
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
          <Divider style={{ marginTop: "20px", marginBottom: "20px" }} light />
          <Typography align="center" color="inherit" variant="h5" component="h3">
            BUY MINI QUADCOPTER PARTS
          </Typography>
          <Grid
            style={{ marginTop: "10px", margin: "auto" }}
            className={clsx(classes.pageSlideAnimation, classes.pageSlide)}
            justify="center"
            container
            spacing={0}
          >
            {product}
            <center style={{ marginTop: "20px", marginBottom: "10px" }}>
              <Link style={{ all: "initial" }} to="/buy">
                <Button variant="contained" size="large" color="primary">
                  View More
                </Button>
              </Link>
            </center>
          </Grid>
        </div>
      </Container>
    );
  }
}

export default withStyles(styles, { withTheme: true })(MiniQuadcopter);
