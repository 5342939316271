import React from "react";
import { connect } from "react-redux";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button"
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import CardMedia from "@material-ui/core/CardMedia";
import Slider from "react-slick";
import dynamic from "next/dynamic";
const Login = dynamic(
  () => import("../components/Login"),
  { ssr: false }
)

class DetailView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      expanded: "panel0",
      showLogin: false
    };
  }

  handleChangeExpand = panel => (project, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    });
  };

  slidersVideo() {
    return this.props.project.videoUrls.map(videoUrl => {
      return (
        <div style={{ height: "440px", width: "100%" }}>
          <iframe
            title={this.props.project.title}
            style={{ height: "440px", width: "100%" }}
            src={videoUrl}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </div>
      );
    });
  }

  slidersImage() {
    return this.props.project.imageUrls.map(imageUrl => {
      return (
        <div style={{ height: "440px", width: "100%" }}>
          <CardMedia image={imageUrl} style={{ height: "440px" }} />
        </div>
      );
    });
  }

  closeModel = () => {
    this.setState({ showLogin: false });
  };

  render() {

    const { expanded } = this.state;
    let steps = [];
    if (this.props.project && this.props.project.steps) {
      steps = this.props.project.steps.map((step, index) => {
        // if (this.props.project.steps.length == index + 1) return null;
        return (
          <ExpansionPanel
            expanded={expanded === "panel" + index + 1}
            onChange={this.handleChangeExpand("panel" + index + 1)}
          >
            <ExpansionPanelSummary expandIcon={<KeyboardArrowDownIcon />}>
              <Typography variant="subheading">{step.title}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography style={{ textAlign: "justify" }}>
                {step.subTopics}
              </Typography>
            </ExpansionPanelDetails>
            <ExpansionPanelDetails>
              <Typography variant="subheading">
                <b>Description:</b>
              </Typography>
            </ExpansionPanelDetails>
            <ExpansionPanelDetails>
              <Typography style={{ textAlign: "justify" }}>
                <div className="descriptionInTag"
                  dangerouslySetInnerHTML={{ __html: step.descriptionInTag }}
                ></div>
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        );
      });
    }
    var settings = {
      dots: true,
      autoplay: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

    return (
      <div>
        <Typography
          style={{ paddingTop: "5px", marginBottom: "20px" }}
          align="center"
          color="inherit"
          variant="h5"
          component="h1"
        >
          {this.props.project.title}
        </Typography>
        {this.props.project.imageUrls.length > 0 && (
          <Card>
            <Slider {...settings} style={{ height: "440px" }}>
              {this.slidersImage()}
            </Slider>
          </Card>
        )}
        {this.props.project.videoUrls.length > 0 && (
          <Card style={{ marginTop: "10px" }}>
            <Slider {...settings} style={{ height: "440px" }}>
              {this.slidersVideo()}
            </Slider>
          </Card>
        )}
        {this.props.project.author.postedBy &&
          <a style={{ textDecoration: "none" }} onClick={e => {
            this.props.user.isLogin
              ? window.open(this.props.project.author.postedBy.profileLink, "_blank")
              : this.setState({ showLogin: true });
          }}
          >
            <Button style={{ marginTop: 10 }} fullWidth variant="contained" color="primary" size="large">
              {`Click here to follow author ${this.props.project.author.postedBy.name}`}
            </Button>
          </a>
        }
        <Card style={{ marginTop: "10px" }}>
          <Grid
            style={{ margin: "auto", clear: "both" }}
            alignItems="center"
            container
            spacing={0}
          >
            <Grid style={{ padding: "10px" }} item xs={12} sm={12}>
              <div style={{ padding: "10px" }}>
                <Typography
                  style={{ marginBottom: "10px", fontWeight: "bold" }}
                  align="center"
                  color="h1"
                  variant="h5"
                >
                  DESCRIPTION
                </Typography>
                <Divider />
                <div
                  className="descriptionInTag"
                  dangerouslySetInnerHTML={{
                    __html: this.props.project.descriptionInTag
                  }}
                  style={{
                    textAlign: "justify",
                    paddingTop: "10px",
                    padding: "5px"
                  }}
                ></div>
              </div>
            </Grid>
          </Grid>
        </Card>
        <div style={{ marginTop: 10, marginBottom: 10 }}>{steps}</div>
        {this.props.project.author.postedBy &&
          <a style={{ textDecoration: "none" }} onClick={e => {
            this.props.user.isLogin
              ? window.open(this.props.project.author.postedBy.profileLink, "_blank")
              : this.setState({ showLogin: true });
          }}
          >
            <Button fullWidth variant="contained" color="primary" size="large">
              {`Click here to follow author ${this.props.project.author.postedBy.name}`}
            </Button>
          </a>
        }
        <Login closeModel={this.closeModel} open={this.state.showLogin} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.Home.login.userDetails
  };
};

const mapDispatchToProps = dispatch => {
  return {
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailView);
