import {
  SET_AFFILIATE_DATA,
  SET_AFFILIATE_HAS_MORE,
  AFFILIATE_ADDED,
  AFFILIATE_FAIL,
  AFFILIATE_EDITED,
  AFFILIATE_DELETED,
  AFFILIATE_COMMENT_ADDED,
  AFFILIATE_COMMENT_EDITED,
  AFFILIATE_COMMENT_DELETED,
  AFFILIATE_COMMENT_FAIL,
  AFFILIATE_LIKE_ADDED,
  AFFILIATE_LIKE_FAIL,
  AFFILIATE_LIKE_REMOVED,
  SET_AFFILIATE_DETAILS,
  SET_FETCHING_AFFILIATE_DETAILS,
  CLOSE_SNACKBAR
} from "../actions/ActionType";

const initialState = {
  snackbar: {
    msg: null,
    show: false
  },
  affiliates: [],
  affiliateHasMore: true,
  fetchingAffiliateDetails: false
};

const affiliateReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AFFILIATE_DATA:
      let affiliateObjects = {};
      state.affiliates.map(affiliate => { affiliateObjects[affiliate.title + affiliate.id] = affiliate; });
      action.data.map(affiliate => {
        affiliateObjects[affiliate.title + affiliate.id] = affiliateObjects[affiliate.title + affiliate.id] ? { ...affiliateObjects[affiliate.title + affiliate.id], ...affiliate } : affiliate;
      });
      return Object.assign({}, state, {
        affiliates: action.refresh ? action.data : Object.values(affiliateObjects)
      });

    case SET_AFFILIATE_HAS_MORE:
      return Object.assign({}, state, { affiliateHasMore: action.data });

    case SET_FETCHING_AFFILIATE_DETAILS:
      return Object.assign({}, state, {
        fetchingAffiliateDetails: action.data
      });

    case SET_AFFILIATE_DETAILS:
      let affiliates = [];
      affiliates = Array.from(state.affiliates);
      let index = affiliates.findIndex(affiliate => {
        return affiliate.id == action.data.id;
      });
      if (index > -1) {
        affiliates[index] = action.data;
      } else {
        affiliates.push(action.data);
      }
      return Object.assign({}, state, { affiliates: affiliates });

    case AFFILIATE_ADDED:
      state.affiliates = [action.data].concat(state.affiliates);
      state.snackbar = { msg: "Your affiliate data is added", show: true };
      return Object.assign({}, state);

    case AFFILIATE_FAIL:
      return Object.assign({}, state, {
        snackbar: { msg: "Error, Please try again", show: true }
      });

    case AFFILIATE_EDITED:
      for (var i = 0; i < state.affiliates.length; i++) {
        if (state.affiliates[i].id == action.data.id) {
          state.affiliates[i].imageUrl = action.data.imageUrl;
          state.affiliates[i].title = action.data.title;
          state.affiliates[i].description = action.data.description;
          state.affiliates[i].category = action.data.category;
          state.affiliates[i].subCategory = action.data.subCategory;
          state.affiliates[i].affiliate = action.data.affiliate;
        }
      }
      return Object.assign({}, state, {
        snackbar: { msg: "Your affiliate is Edited", show: true }
      });

    case AFFILIATE_DELETED:
      return Object.assign({}, state, {
        affiliates: state.affiliates.filter(
          affiliate => affiliate.id != action.data
        ),
        snackbar: { msg: "Your affiliate is deleted", show: true }
      });

    case AFFILIATE_COMMENT_ADDED:
      for (var i = 0; i < state.affiliates.length; i++) {
        if (state.affiliates[i].id == action.data.id) {
          state.affiliates[i].comments.push(action.data.comments);
        }
      }
      return Object.assign({}, state, {
        affiliates: state.affiliates,
        snackbar: { msg: "Your Comment Added", show: true }
      });

    case AFFILIATE_COMMENT_EDITED:
      for (var i = 0; i < state.affiliates.length; i++) {
        if (state.affiliates[i].id == action.data.id) {
          for (var j = 0; j < state.affiliates[i].comments.length; j++) {
            if (state.affiliates[i].comments[j]._id == action.data.commentId) {
              state.affiliates[i].comments[j].comment = action.data.comment;
            }
          }
        }
      }
      return Object.assign({}, state, {
        affiliates: state.affiliates,
        snackbar: { msg: "Your Comment Edited", show: true }
      });

    case AFFILIATE_COMMENT_DELETED:
      for (var i = 0; i < state.affiliates.length; i++) {
        if (state.affiliates[i].id == action.data.id) {
          for (var j = 0; j < state.affiliates[i].comments.length; j++) {
            if (state.affiliates[i].comments[j]._id == action.data.commentId) {
              state.affiliates[i].comments.splice(j, 1);
            }
          }
        }
      }
      return Object.assign({}, state, {
        affiliates: state.affiliates,
        snackbar: { msg: "Your comment is deleted", show: true }
      });

    case AFFILIATE_COMMENT_FAIL:
      return Object.assign({}, state, {
        snackbar: { msg: "Error, Please Login", show: true }
      });

    case AFFILIATE_LIKE_ADDED:
      for (var i = 0; i < state.affiliates.length; i++) {
        if (state.affiliates[i].id == action.data.id) {
          state.affiliates[i].likes = action.data.likes;
        }
      }
      return Object.assign({}, state, {
        affiliates: state.affiliates,
        snackbar: { msg: "Your Like Added", show: true }
      });

    case AFFILIATE_LIKE_FAIL:
      return Object.assign({}, state, {
        snackbar: { msg: "Error, Please Login", show: true }
      });

    case AFFILIATE_LIKE_REMOVED:
      for (var i = 0; i < state.affiliates.length; i++) {
        if (state.affiliates[i].id == action.data.id) {
          state.affiliates[i].likes = action.data.likes;
        }
      }
      return Object.assign({}, state, {
        affiliates: state.affiliates,
        snackbar: { msg: "You have Disliked", show: true }
      });

    case CLOSE_SNACKBAR:
      return Object.assign({}, state, {
        snackbar: { msg: state.snackbar.msg, show: false }
      });

    default:
      return state;
  }
};

export default affiliateReducer;
