import React, { Component } from "react";
import { connect } from "react-redux";
import { socialLogin } from "../../actions/Home";
import config from "../../config";

class LinkedinLogin extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    var e = document.createElement("script");
    e.type = "text/javascript";
    e.async = true;
    e.src = "https://platform.linkedin.com/in.js?async=true";
    var t = document.getElementsByTagName("script")[0];
    t.parentNode.insertBefore(e, t);
  }

  //Trigger Login for LinkedIn
  linkedinLogin = () => {
    window.IN.init({
      api_key: config.linkedinApiKey
    });
    // window.IN.Event.on(IN, "auth", this.getUserDetails())
    setTimeout(() => {
      this.getUserDetails();
    }, 1000);
  };

  getUserDetails = () => {
    window.IN.User.authorize(() => {
      window.IN.API.Profile("me")
        .fields(["id", "firstName", "lastName", "pictureUrl", "email-address"])
        .result(profiles => {
          console.log(profiles.values[0]);
          var data = {
            name:
              profiles.values[0].firstName + " " + profiles.values[0].lastName,
            email: profiles.values[0].emailAddress,
            socialId: profiles.values[0].id,
            imageUrl: profiles.values[0].pictureUrl,
            type: "linkedinId"
          };
          this.props.socialLogin(data).then(() => {
            if (this.props.user.id) {
              localStorage.setItem("authToken", this.props.user.authToken);
              this.props.closeModel();
            }
          });
        })
        .error(err => {
          console.log("Import error - Error occured while importing data");
        });
    });
  };
  render() {
    return (
      <div style={{ display: "inline-block" }} onClick={this.linkedinLogin}>
        {this.props.button}
      </div>
    );
  }
}

const mapStateToProps = state => {
  // console.log(state)
  return {
    user: state.Home.login.userDetails
  };
};

const mapDispatchToProps = dispatch => {
  return {
    socialLogin: data => {
      return dispatch(socialLogin(data));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkedinLogin);
