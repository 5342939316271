import {
  SET_YOUTUBE_DATA,
  SET_YOUTUBE_HAS_MORE,
  YOUTUBE_ADDED,
  YOUTUBE_FAIL,
  YOUTUBE_EDITED,
  YOUTUBE_DELETED,
  YOUTUBE_COMMENT_ADDED,
  YOUTUBE_COMMENT_EDITED,
  YOUTUBE_COMMENT_DELETED,
  YOUTUBE_COMMENT_FAIL,
  YOUTUBE_LIKE_ADDED,
  YOUTUBE_LIKE_FAIL,
  YOUTUBE_LIKE_REMOVED,
  SET_YOUTUBE_DETAILS,
  SET_FETCHING_YOUTUBE_DETAILS
} from "./ActionType";
import axios from "axios";

import config from "../config";
var apiRoot = config.api.root;

export const getYoutubeData = (page, search, refresh) => {
  let skip = (page - 1) * 6;
  return dispatch => {
    let url = search
      ? apiRoot + "/api/youtube" + search + "&limit=6&skip=" + skip
      : apiRoot + "/api/youtube?limit=6&skip=" + skip;
    return axios.get(url).then(
      response => {
        if (response.data.length > 0) {
          dispatch(setYoutubeData(response.data, refresh));
        } else {
          dispatch(setYoutubeHasMore(false));
          console.log("No data");
        }
      },
      error => {
        console.log(error);
      }
    );
  };
};

export const getYoutubeDetails = id => {
  return dispatch => {
    dispatch(setFetchingYoutubeDetails(true));
    return axios.get(apiRoot + "/api/Youtube/" + id).then(
      response => {
        dispatch(setYoutubeDetails(response.data[0]));
        dispatch(setFetchingYoutubeDetails(false));
      },
      error => {
        console.log(error);
        dispatch(setFetchingYoutubeDetails(false));
      }
    );
  };
};

export const addYoutube = data => {
  return dispatch => {
    return axios
      .post(apiRoot + "/api/youtube", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + localStorage.getItem("authToken")
        }
      })
      .then(
        response => {
          if (response.data) {
            dispatch(youtubeAdded(response.data));
          } else {
            dispatch(youtubeFail());
          }
        },
        error => {
          dispatch(youtubeFail());
        }
      );
  };
};

export const editYoutube = (data, id) => {
  return dispatch => {
    return axios
      .put(apiRoot + "/api/youtube/" + id, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + localStorage.getItem("authToken")
        }
      })
      .then(
        response => {
          if (response.data) {
            dispatch(youtubeEdited(response.data));
          } else {
            dispatch(youtubeFail());
          }
        },
        error => {
          dispatch(youtubeFail());
        }
      );
  };
};

export const deleteYoutube = id => {
  return dispatch => {
    return axios
      .delete(apiRoot + "/api/youtube/" + id, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + localStorage.getItem("authToken")
        }
      })
      .then(
        response => {
          console.log("response", response);
          if (response.status == 202) {
            dispatch(YoutubeDeleted(id));
          } else {
            dispatch(youtubeFail());
          }
        },
        error => {
          dispatch(youtubeFail());
        }
      );
  };
};

export const addYoutubeComment = data => {
  return dispatch => {
    return axios
      .post(
        apiRoot + "/api/youtube/addComment/" + data.id,
        { comment: data.comment },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: data.authToken
          }
        }
      )
      .then(
        response => {
          if (response.data) {
            dispatch(commentAdded(response.data));
          } else {
            dispatch(commentFail());
          }
        },
        error => {
          dispatch(commentFail());
        }
      );
  };
};

export const editYoutubeComment = data => {
  return dispatch => {
    return axios
      .put(
        apiRoot + "/api/youtube/editComment/" + data.id + "/" + data.commentId,
        { comment: data.comment },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: data.authToken
          }
        }
      )
      .then(
        response => {
          if (response.data) {
            dispatch(commentEdited(response.data));
          } else {
            dispatch(commentFail());
          }
        },
        error => {
          dispatch(commentFail());
        }
      );
  };
};

export const deleteYoutubeComment = data => {
  return dispatch => {
    return axios
      .delete(
        apiRoot +
          "/api/youtube/deleteComment/" +
          data.id +
          "/" +
          data.commentId,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: data.authToken
          }
        }
      )
      .then(
        response => {
          if (response.data) {
            dispatch(commentDeleted(response.data));
          } else {
            dispatch(commentFail());
          }
        },
        error => {
          dispatch(commentFail());
        }
      );
  };
};

export const addYoutubeLike = data => {
  return dispatch => {
    return axios
      .post(
        apiRoot + "/api/youtube/addLike/" + data.id,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: data.authToken
          }
        }
      )
      .then(
        response => {
          if (response.data.like) {
            dispatch(likeAdded(response.data));
          } else {
            dispatch(likeRemoved(response.data));
          }
        },
        error => {
          dispatch(likeFail());
        }
      );
  };
};

export const setYoutubeData = (data, refresh) => {
  return {
    type: SET_YOUTUBE_DATA,
    data,
    refresh
  };
};

export const setYoutubeHasMore = data => {
  return {
    type: SET_YOUTUBE_HAS_MORE,
    data
  };
};

export const youtubeAdded = data => {
  return {
    type: YOUTUBE_ADDED,
    data
  };
};

export const youtubeFail = () => {
  return {
    type: YOUTUBE_FAIL
  };
};

export const youtubeEdited = data => {
  return {
    type: YOUTUBE_EDITED,
    data
  };
};

export const YoutubeDeleted = data => {
  return {
    type: YOUTUBE_DELETED,
    data
  };
};

export const commentAdded = data => {
  return {
    type: YOUTUBE_COMMENT_ADDED,
    data
  };
};

export const commentFail = () => {
  return {
    type: YOUTUBE_COMMENT_FAIL
  };
};

export const likeAdded = data => {
  return {
    type: YOUTUBE_LIKE_ADDED,
    data
  };
};

export const commentEdited = data => {
  return {
    type: YOUTUBE_COMMENT_EDITED,
    data
  };
};

export const commentDeleted = data => {
  return {
    type: YOUTUBE_COMMENT_DELETED,
    data
  };
};

export const likeRemoved = data => {
  return {
    type: YOUTUBE_LIKE_REMOVED,
    data
  };
};

export const likeFail = () => {
  return {
    type: YOUTUBE_LIKE_FAIL
  };
};

export const setFetchingYoutubeDetails = data => {
  return {
    type: SET_FETCHING_YOUTUBE_DETAILS,
    data
  };
};

export const setYoutubeDetails = data => {
  return {
    type: SET_YOUTUBE_DETAILS,
    data
  };
};
