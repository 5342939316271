import {
  SET_NEW_PROJECTS,
  SET_PROJECT_DATA,
  SET_PROJECT_HAS_MORE,
  PROJECT_ADDED,
  PROJECT_FAIL,
  PROJECT_EDITED,
  PROJECT_DELETED,
  PROJECT_COMMENT_ADDED,
  PROJECT_COMMENT_EDITED,
  PROJECT_COMMENT_DELETED,
  PROJECT_COMMENT_FAIL,
  PROJECT_LIKE_ADDED,
  PROJECT_LIKE_FAIL,
  PROJECT_LIKE_REMOVED,
  SET_PROJECT_DETAILS,
  SET_FETCHING_PROJECT_DETAILS,
  CLOSE_SNACKBAR
} from "../actions/ActionType";

const initialState = {
  snackbar: {
    msg: null,
    show: false
  },
  projects: [],
  projectHasMore: true,
  fetchingProjectDetails: false
};

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROJECT_DATA:
      let projectObjects = {}
      state.projects.map(project => { projectObjects[project.id] = project })
      action.data.map(project => {
        projectObjects[project.id] = projectObjects[project.id] ? { ...projectObjects[project.id], ...project } : project
      })
      return Object.assign({}, state, {
        projects: action.refresh ? action.data : Object.values(projectObjects)
      });

    case SET_PROJECT_HAS_MORE:
      return Object.assign({}, state, { projectHasMore: action.data });

    case SET_FETCHING_PROJECT_DETAILS:
      return Object.assign({}, state, { fetchingInternshipDetails: action.data });

    case PROJECT_ADDED:
      return Object.assign({}, state, {
        projects: [action.data].concat(state.projects),
        snackbar: { msg: "Your Project is Added", show: true }
      });

    case PROJECT_FAIL:
      return Object.assign({}, state, {
        snackbar: {
          msg: "Error while adding your Project, please try again",
          show: true
        }
      });

    case PROJECT_EDITED:
      for (var i = 0; i < state.projects.length; i++) {
        if (state.projects[i].id == action.data.id) {
          state.projects[i] = action.data
        }
      }
      return Object.assign({}, state, {
        projects: state.projects,
        snackbar: { msg: "Your project is Edited", show: true }
      });

    case PROJECT_DELETED:
      return Object.assign({}, state, {
        projects: state.projects.filter(project => project.id != action.data),
        snackbar: { msg: "Your Project is deleted", show: true }
      });

    case PROJECT_COMMENT_ADDED:
      for (var i = 0; i < state.projects.length; i++) {
        if (state.projects[i].id == action.data.id) {
          state.projects[i].comments.push(action.data.comments);
        }
      }
      return Object.assign({}, state, {
        projects: state.projects,
        snackbar: { msg: "Your Comment Added", show: true }
      });

    case PROJECT_COMMENT_EDITED:
      for (var i = 0; i < state.projects.length; i++) {
        if (state.projects[i].id == action.data.id) {
          for (var j = 0; j < state.projects[i].comments.length; j++) {
            if (state.projects[i].comments[j]._id == action.data.commentId) {
              state.projects[i].comments[j].comment = action.data.comment;
            }
          }
        }
      }
      return Object.assign({}, state, {
        projects: state.projects,
        snackbar: { msg: "Your Comment Edited", show: true }
      });

    case PROJECT_COMMENT_DELETED:
      for (var i = 0; i < state.projects.length; i++) {
        if (state.projects[i].id == action.data.id) {
          for (var j = 0; j < state.projects[i].comments.length; j++) {
            if (state.projects[i].comments[j]._id == action.data.commentId) {
              state.projects[i].comments.splice(j, 1);
            }
          }
        }
      }
      return Object.assign({}, state, {
        projects: state.projects,
        snackbar: { msg: "Your comment is deleted", show: true }
      });

    case PROJECT_COMMENT_FAIL:
      return Object.assign({}, state, {
        snackbar: { msg: "Error, Please Login", show: true }
      });

    case PROJECT_LIKE_ADDED:
      for (var i = 0; i < state.projects.length; i++) {
        if (state.projects[i].id == action.data.id) {
          state.projects[i].likes = action.data.likes;
        }
      }
      return Object.assign({}, state, {
        projects: state.projects,
        snackbar: { msg: "Your Like Added", show: true }
      });

    case PROJECT_LIKE_FAIL:
      return Object.assign({}, state, {
        snackbar: { msg: "Error, Please Login", show: true }
      });

    case PROJECT_LIKE_REMOVED:
      for (var i = 0; i < state.projects.length; i++) {
        if (state.projects[i].id == action.data.id) {
          state.projects[i].likes = action.data.likes;
        }
      }
      return Object.assign({}, state, {
        projects: state.projects,
        snackbar: { msg: "You have Disliked", show: true }
      });
    case SET_PROJECT_DETAILS:
      let projects = []
      projects = Array.from(state.projects)
      let index = projects.findIndex(internship => { return internship.id == action.data.id })
      if (index > -1) {
        projects[index] = action.data
      } else {
        projects.push(action.data)
      }
      return Object.assign({}, state, { projects: projects });

    case CLOSE_SNACKBAR:
      return Object.assign({}, state, {
        snackbar: { msg: state.snackbar.msg, show: false }
      });

    default:
      return state;
  }
};

export default projectReducer;
