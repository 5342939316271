import React from "react";
import ReactDOM from "react-dom";
import thunk from "redux-thunk";
import { BrowserRouter } from "react-router-dom";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import reducers from "./reducers";
import App from "./App";
import * as serviceWorker from "./utils/serviceWorker";
import * as pushNotification from './utils/push-notification';
import { Plugins, Capacitor } from '@capacitor/core';
import { getCurrentUser } from './actions/Home'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

Plugins.App.addListener('appStateChange', (state) => {
  // state.isActive contains the active state
  console.log('App state changed. Is active?', state.isActive);
});

// Listen for serious plugin errors
Plugins.App.addListener('pluginError', (info) => {
  console.error('There was a serious error with a plugin', info);
});

Plugins.App.addListener('appUrlOpen', (data) => {
  // Example url: https://beerswift.app/tabs/tab2
  // slug = /tabs/tab2
  const slug = data.url.split(".app").pop();
  if (slug) {
    console.log('slug', slug)
    window.location = slug.replace('https://trybotics.com', '');
  }
  // If no match, do nothing - let regular routing 
  // logic take over
});

Plugins.App.addListener('appRestoredResult', (data) => {
  console.log('Restored state:', data);
});

Plugins.App.addListener('backButton', (url) => {
  if (['/', '/home'].includes(window.location.pathname)) {
    Plugins.App.exitApp();
  } else {
    window.history.back();
  }
})

if (Capacitor.isPluginAvailable('StatusBar')) {
  Plugins.StatusBar.setBackgroundColor({ color: "#167ac6" })
};

const store = createStore(reducers, window.__INITIAL_STATE__, applyMiddleware(thunk));

let authToken = localStorage.getItem("authToken")
if (authToken) {
  store.dispatch(getCurrentUser(authToken))
}

delete window.__PRELOADED_STATE__;

// Use clientEnv to access environment variables from the server.

// Get the server URL from the base tag if you need it.
// const baseHref = document.getElementsByTagName("base")[0].href;

const jssStyles = document.querySelector("#jss-server-side");
if (jssStyles) {
  jssStyles.parentElement.removeChild(jssStyles);
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
pushNotification.initializeFirebase()
pushNotification.askForPermissioToReceiveNotifications()