import { combineReducers } from "redux";
import Home from "./Home";
import Blog from "./Blog";
import Ad from "./Ad";
import Youtube from "./Youtube";
import Affiliate from "./Affiliate";
import Internship from "./Internship";
import Project from "./Project";

const reducer = combineReducers({
  Home,
  blogState: Blog,
  adState: Ad,
  youtubeState: Youtube,
  affiliateState: Affiliate,
  internshipState: Internship,
  projectState: Project
});

export default reducer;
