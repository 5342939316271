import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import DetailView from "../components/DetailView";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getProjectDetails } from "../actions/ProjetDetails";
import Container from "@material-ui/core/Container";
import dynamic from "next/dynamic";
const Project = dynamic(
  () => import("../containers/Project"),
  { ssr: false }
)

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500
  },
  pageSlideAnimation: {
    visibility: "hidden",
    visibility: "visible9" /*For old IE browsers IE6-8 */,
    "&::after": {
      content: "",
      display: "table",
      clear: "both"
    }
  },
  pageSlide: {
    visibility: "visible",
    animation: "pageSlide 1s"
  },
  "@keyframes pageSlide": {
    "0%": {
      opacity: "0",
      transform: "translateY(150px)"
    },
    "100%": {
      opacity: "1",
      transform: "translateY(0px)"
    }
  }
});

class ProjectDetailView extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    let projectId = this.props.location.pathname.split("/")[2]
    let project = this.props.projects.find(project => {
      return project.id == projectId
    })
    if (project && project.descriptionInTag) {
      document.title = project.title + " | Trybotics"
    } else {
      if (!this.props.fetchingProjectDetails) {
        this.props.getProjectDetails(projectId)
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    let projectId = nextProps.location.pathname.split("/")[2]
    let project = this.props.projects.find(project => {
      return project.id == projectId
    })
    if (project && project.descriptionInTag) {
      document.title = project.title + " | Trybotics"
    } else {
      if (!this.props.fetchingProjectDetails) {
        this.props.getProjectDetails(projectId)
      }
    }
  }

  render() {
    const { classes } = this.props
    let project = this.props.projects.find(project => {
      return project.id == this.props.location.pathname.split("/")[2]
    });
    return (
      <Container disableGutters={true} maxWidth="lg">
        {project && project.descriptionInTag ? (
          <div className="row">
            <DetailView id={this.props.location.pathname.split("/")[2]} project={project} />
            <Divider style={{ marginTop: "30px", marginBottom: "25px" }} light />
            <Typography
              style={{
                marginBottom: "30px",
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
              }}
              align="center"
              color="inherit"
              variant="h5"
            >
              YOU MIGHT ALSO LIKE
        </Typography>
            <Project match={this.props.match} location={this.props.location} history={this.props.history} />
          </div>
        ) : (
            <center className="m-t-10">
              <CircularProgress size={50} />
            </center>
          )
        }
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    projects: state.projectState.projects,
    fetchingProjectDetails: state.projectState.fetchingProjectDetails
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getProjectDetails: id => {
      return dispatch(getProjectDetails(id));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(ProjectDetailView));
