import firebase from 'firebase'
import { Plugins, Capacitor } from "@capacitor/core"
import axios from 'axios'
import config from '../config'
var apiRoot = config.api.root
const { PushNotifications } = Plugins
var token = ""

export const initializeFirebase = () => {
	firebase.initializeApp({
		apiKey: "AIzaSyDlyYUNYTKfXPBp_JpITxZFUG06QAPAjbU",
		authDomain: "trybotics-firebase.firebaseapp.com",
		databaseURL: "https://trybotics-firebase.firebaseio.com",
		projectId: "trybotics-firebase",
		storageBucket: "trybotics-firebase.appspot.com",
		messagingSenderId: "586103886662",
		appId: "1:586103886662:web:5a4c97f6bae90ab33e8b47",
		measurementId: "G-9C70Q3RS9V"
	});
	firebase.analytics();
	// use other service worker
	// navigator.serviceWorker
	//   .register('/my-sw.js')
	//   .then((registration) => {
	//     firebase.messaging().useServiceWorker(registration);
	//   });
}

export const askForPermissioToReceiveNotifications = async () => {
	try {
		const messaging = firebase.messaging();
		messaging.usePublicVapidKey("BFYVAIK1BynF5rXjX59_ZZe7bJC_sywc0d9RfPlYiugeN5KcWPFYDM9tnxH9ncqA4xQIE3BOceXbRKMMFibo4AU");
		await messaging.requestPermission();
		token = await messaging.getToken();
		addTokenToTopic('general')
		addTokenToTopic()
	} catch (error) {
		console.error(error);
	}
	if (Capacitor.isPluginAvailable('PushNotifications')) {
		PushNotifications.register();
		PushNotifications.addListener('registration',
			(FCMToken) => {
				console.log("Token", FCMToken.value)
				token = FCMToken.value
				addTokenToTopic('general')
				addTokenToTopic()
			}
		)
		PushNotifications.addListener('registrationError',
			(error) => {
				console.log('Error on registration: ' + JSON.stringify(error));
			}
		)
		PushNotifications.addListener('pushNotificationReceived',
			(response) => {
				console.log('Push received: ' + JSON.stringify(response));
			}
		)
		PushNotifications.addListener('pushNotificationActionPerformed',
			(response) => {
				console.log('Push action performed: ' + JSON.stringify(response));
				window.location = response.notification.data.androidLink.replace('https://trybotics.com', '');
				// window.open("intent://trybotics.com#Intent;scheme=https;package=com.trybotics.app;end");
			}
		)
	};
}

export const addTokenToTopic = (topic) => {
	let urls = window.location.pathname.split('/')
	let topics = JSON.parse(localStorage.getItem('topics'))
	topics = topics ? topics : {}
	if (!topic) {
		if (urls.length > 1) {
			if (['project', 'internship', 'ad', 'blog', 'youtube', 'buy', 'service', 'covid19-india', 'resume-builder'].includes(urls[1]) && topics[urls[1]] != token) {
				topic = urls[1]
			} else {
				console.log(`Either topic ${urls[1]} already registered in firebase or not equal to project, internship, ad, blog, youtube, buy, service, covid19-india, resume-builder`)
			}
		}
	} else {
		if (topics[topic] == token || !['general'].includes(topic)) {
			console.log(`Either topic ${topic} already registered in firebase or not equal to general`)
			topic = null
		}
	}
	if (topic && token) {
		axios.post(apiRoot + '/api/notification/add-token-to-topic/' + topic, { token: token }).then((response) => {
			console.log('Firebase response', JSON.stringify(response))
			topics[topic] = token
			localStorage.setItem('topics', JSON.stringify(topics))
		}).catch((error) => {
			console.log('error', error)
		});
	}
}

export const registerUserFCMToken = (firebaseTokens, userId) => {
	firebaseTokens.push(token)
	var data = {
		firebaseTokens: [...new Set(firebaseTokens)]
	};
	axios.put(apiRoot + '/api/user/' + userId, data).then((response) => {
	}).catch((error) => {
		console.log('error', error)
	});
}