import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import dynamic from 'next/dynamic';
import Grid from "@material-ui/core/Grid";
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import CancelIcon from '@material-ui/icons/Cancel';

const JoditEditor = dynamic(
  () => import('../components/JoditEditor'),
  { ssr: false }
)
const ImageUploader = dynamic(
  () => import('react-images-upload'),
  { ssr: false }
)

const styles = () => ({
  formHeader: {
    textAlign: "center",
    fontSize: 24,
    backgroundColor: "#2196f3",
    color: "#fff",
    padding: "15px 12px",
    textTransform: "uppercase"
  },
  buttonOutlineSelected: {
    borderRadius: "unset !important",
    border: "1px solid #2196f3 !important",
    fontSize: 12
  },
  buttonOutline: {
    borderRadius: "unset !important",
    border: "1px solid #999 !important",
    color: "#777 !important",
    fontSize: 12
  },
  close: {
    marginTop: "30px"
  },
  root: {
    "& .MuiButton-root": {
      padding: 0,
      minWidth: "0px"
    }
  },
  image: {
    "& .fileContainer": {
      background: "none",
      boxShadow: "none",
      padding: 0,
      margin: 0
    },
    "& .fileContainer .uploadPictureContainer": {
      padding: 0,
      margin: "10px",
      width: "50px",
      height: "50px",
      height: "inherit"
    },
    "& .fileContainer .deleteImage": {
      top: "-5px",
      right: "-6px",
      background: "#000",
      fontSize: "9px",
      lineHeight: "16px",
      width: "15px",
      height: "15px"
    }
  },
  button: {
    "& .MuiButton-containedPrimary": { backgroundColor: "#2196f3" },
    "& .MuiButton-root": {
      borderRadius: "0px"
    }
  }
})

class Step extends Component {

  constructor(props) {
    super(props);
    this.state = {
      title: this.props.title?this.props.title:"",
      description: this.props.description?this.props.description:"",
      descriptionInTag: this.props.descriptionInTag?this.props.descriptionInTag:"",
      imageUrls: this.props.imageUrls?this.props.imageUrls:[],
      videoUrls: this.props.videoUrls?this.props.videoUrls:[],
    }
  }

  componentWillReceiveProps(nextProps) {
    // this.setState({ title: nextProps.title, description: nextProps.description, descriptionInTag: nextProps.descriptionInTag, imageUrls: nextProps.imageUrls, videoUrls: nextProps.videoUrls })
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    })
  }

  onDrop = imageUrls => {
    this.setState({
      imageUrls: imageUrls
    })
  }

  handleAddVideoUrl = () => {
    this.setState({
      videoUrls: [...this.state.videoUrls, ""]
    })
  }

  handleRemoveVideo = index => () => {
    console.log('index',index)
    this.state.videoUrls.splice(index,1)
    this.forceUpdate()
  }

  handleVideoChange = id => evt => {
    this.state.videoUrls[id] = evt.target.value
    this.setState({ videoUrls: this.state.videoUrls })
  }

  render() {
    const { title, description, descriptionInTag, imageUrls, videoUrls } = this.state;
    const { classes } = this.props;
    return (
      <div>
        <Paper style={{ marginTop: 20, paddingBottom: 20 }} variant="outlined">
          <Grid justify='center' container spacing={0}>
            <Grid item xs={11}>
              <center>
                <h4 style={{ margin: 20, color: '#333' }}>Step - {this.props.index}</h4>
              </center>
              <Divider />
              <TextField
                style={{ marginTop: 25 }}
                value={title}
                label="Step Tile"
                type="text"
                margin="normal"
                fullWidth
                autoFocus = { true }
                required
                onChange={this.handleChange('title')}
              />
              <Typography style={{ marginTop: 20 }}>Project Description:</Typography>
              <JoditEditor
                value={descriptionInTag}
                config={{ readonly: false }}
                tabIndex={1} // tabIndex of textarea
                onChange={(descriptionInTag) => { this.setState({ description: descriptionInTag.replace(/<\/?[^>]+>/ig, ""), descriptionInTag: descriptionInTag }) }}
              />
              <center style={{ marginTop: 15, marginBotton: 20 }}>
                <div style={{ width: "100%" }} className={classes.image}>
                  <ImageUploader
                    // defaultImages={imageUrls}
                    label={false}
                    withIcon={false}
                    withPreview={true}
                    buttonText="Upload Images"
                    onChange={this.onDrop}
                    imgExtension={[".jpg", ".gif", ".png"]}
                    maxFileSize={5242880}
                    required
                  />
                </div>
              </center>
              {videoUrls.map((vid, idx) => {
                return <div className={classes.root}>
                  <Grid container>
                    <Grid item xs={12}>
                      <TextField
                        label="Enter Video URL"
                        type="text"
                        margin="normal"
                        style={{ width: "100%" }}
                        value={vid}
                        onChange={this.handleVideoChange(idx)}
                        required
                      />
                      <Button
                        type="button"
                        onClick={this.handleRemoveVideo(idx)}
                        className={classes.close}
                        style={{
                          float: "right",
                          marginTop: "-40px"
                        }}
                        fontSize="small"
                      >
                        <CancelIcon />
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              })}
              <center style={{ marginTop: 10, paddingBotton: 20 }}>
                <Button
                  className={classes.buttonOutlineSelected}
                  onClick={this.handleAddVideoUrl}
                  variant="outlined"
                  color="primary"
                >
                  Add Videos &nbsp;&nbsp;<PlayCircleFilledIcon />
                </Button>
              </center>
            </Grid >
          </Grid >
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(Step);