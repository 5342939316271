import {
  AUTHORIZATION_FAIL,
  SET_AD_DATA,
  SET_AD_HAS_MORE,
  AD_ADDED,
  AD_FAIL,
  AD_EDITED,
  AD_DELETED,
  AD_COMMENT_ADDED,
  AD_COMMENT_EDITED,
  AD_COMMENT_DELETED,
  AD_COMMENT_FAIL,
  AD_LIKE_ADDED,
  AD_LIKE_FAIL,
  AD_LIKE_REMOVED,
  SET_AD_DETAILS,
  SET_FETCHING_AD_DETAILS,
  CLOSE_SNACKBAR
} from "../actions/ActionType";

const initialState = {
  snackbar: {
    msg: null,
    show: false
  },
  ads: [],
  adHasMore: true,
  fetchingAdDetails: false
};

const adsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AD_DATA:
      let adsObjects = {};
      state.ads.map(ad => { adsObjects[ad.title + ad.id] = ad; });
      action.data.map(ad => {
        adsObjects[ad.title + ad.id] = adsObjects[ad.title + ad.id] ? { ...adsObjects[ad.title + ad.id], ...ad } : ad;
      });
      return Object.assign({}, state, {
        ads: action.refresh ? action.data : Object.values(adsObjects)
      });

    case SET_AD_HAS_MORE:
      return Object.assign({}, state, { adHasMore: action.data });

    case SET_FETCHING_AD_DETAILS:
      return Object.assign({}, state, {
        fetchingAdDetails: action.data
      });

    case SET_AD_DETAILS:
      let ads = [];
      ads = Array.from(state.ads);
      let index = ads.findIndex(ad => {
        return ad.id == action.data.id;
      });
      if (index > -1) {
        ads[index] = action.data;
      } else {
        ads.push(action.data);
      }
      return Object.assign({}, state, { ads: ads });

    case AD_ADDED:
      return Object.assign({}, state, {
        ads: [action.data].concat(state.ads),
        snackbar: { msg: "Your Advertisement is Added", show: true }
      });

    case AD_FAIL:
      return Object.assign({}, state, {
        snackbar: {
          msg: "Error while adding your advertisement, please try again",
          show: true
        }
      });

    case AD_EDITED:
      for (var i = 0; i < state.ads.length; i++) {
        if (state.ads[i].id == action.data.id) {
          state.ads[i].title = action.data.title;
          state.ads[i].description = action.data.description;
          state.ads[i].imageUrl = action.data.imageUrl;
          state.ads[i].videoId = action.data.videoId;
          state.ads[i].siteLink = action.data.siteLink;
        }
      }
      return Object.assign({}, state, {
        ads: state.ads,
        snackbar: { msg: "Your Advertisement is Edited", show: true }
      });

    case AD_DELETED:
      return Object.assign({}, state, {
        ads: state.ads.filter(ad => ad.id != action.data),
        snackbar: { msg: "Your Advertisement is Deleted", show: true }
      });

    case AD_COMMENT_ADDED:
      for (var i = 0; i < state.ads.length; i++) {
        if (state.ads[i].id == action.data.id) {
          state.ads[i].comments.push(action.data.comments);
        }
      }
      return Object.assign({}, state, {
        ads: state.ads,
        snackbar: { msg: "Your Comment Added", show: true }
      });

    case AD_COMMENT_EDITED:
      for (var i = 0; i < state.ads.length; i++) {
        if (state.ads[i].id == action.data.id) {
          for (var j = 0; j < state.ads[i].comments.length; j++) {
            if (state.ads[i].comments[j]._id == action.data.commentId) {
              state.ads[i].comments[j].comment = action.data.comment;
            }
          }
        }
      }
      return Object.assign({}, state, {
        ads: state.ads,
        snackbar: { msg: "Your Comment Edited", show: true }
      });

    case AD_COMMENT_DELETED:
      for (var i = 0; i < state.ads.length; i++) {
        if (state.ads[i].id == action.data.id) {
          for (var j = 0; j < state.ads[i].comments.length; j++) {
            if (state.ads[i].comments[j]._id == action.data.commentId) {
              state.ads[i].comments.splice(j, 1);
            }
          }
        }
      }
      return Object.assign({}, state, {
        ads: state.ads,
        snackbar: { msg: "Your comment is deleted", show: true }
      });

    case AD_COMMENT_FAIL:
      return Object.assign({}, state, {
        snackbar: { msg: "Error, Please Login", show: true }
      });

    case AD_LIKE_ADDED:
      for (var i = 0; i < state.ads.length; i++) {
        if (state.ads[i].id == action.data.id) {
          state.ads[i].likes = action.data.likes;
        }
      }
      return Object.assign({}, state, {
        ads: state.ads,
        snackbar: { msg: "Your Like Added", show: true }
      });

    case AD_LIKE_FAIL:
      return Object.assign({}, state, {
        snackbar: { msg: "Error, Please Login", show: true }
      });

    case AD_LIKE_REMOVED:
      for (var i = 0; i < state.ads.length; i++) {
        if (state.ads[i].id == action.data.id) {
          state.ads[i].likes = action.data.likes;
        }
      }
      return Object.assign({}, state, {
        ads: state.ads,
        snackbar: { msg: "You have Disliked", show: true }
      });

    case AUTHORIZATION_FAIL:
      return Object.assign({}, state, {
        snackbar: { msg: "Un-Authorised, Please Login", show: true }
      });

    case CLOSE_SNACKBAR:
      return Object.assign({}, state, {
        snackbar: { msg: state.snackbar.msg, show: false }
      });

    default:
      return state;
  }
};

export default adsReducer;
