import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import DetailView from "../components/DetailView";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getBlogDetails } from "../actions/BlogDetails";
import Container from "@material-ui/core/Container";
import dynamic from "next/dynamic";
const Blog = dynamic(
  () => import("../containers/Blog"),
  { ssr: false }
)

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500
  },
  pageSlideAnimation: {
    visibility: "hidden",
    visibility: "visible9" /*For old IE browsers IE6-8 */,
    "&::after": {
      content: "",
      display: "table",
      clear: "both"
    }
  },
  pageSlide: {
    visibility: "visible",
    animation: "pageSlide 1s"
  },
  "@keyframes pageSlide": {
    "0%": {
      opacity: "0",
      transform: "translateY(150px)"
    },
    "100%": {
      opacity: "1",
      transform: "translateY(0px)"
    }
  }
});

class BlogDetailView extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    let blogId = this.props.location.pathname.split("/")[2]
    let blog = this.props.blogs.find(blog => {
      return blog.id == blogId
    })
    if (blog && blog.descriptionInTag) {
      document.title = blog.title + " | Trybotics"
    } else {
      if (!this.props.fetchingBlogDetails) {
        this.props.getBlogDetails(blogId)
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    let blogId = nextProps.location.pathname.split("/")[2]
    let blog = this.props.blogs.find(blog => {
      return blog.id == blogId
    })
    if (blog && blog.descriptionInTag) {
      document.title = blog.title + " | Trybotics"
    } else {
      if (!this.props.fetchingBlogDetails) {
        this.props.getBlogDetails(blogId)
      }
    }
  }

  render() {
    const { classes } = this.props
    let blog = this.props.blogs.find(blog => {
      return blog.id == this.props.location.pathname.split("/")[2]
    });
    return (
      <Container disableGutters={true} maxWidth="lg">
        {blog && blog.descriptionInTag ? (
          <div className="row">
            <DetailView id={this.props.location.pathname.split("/")[2]} project={blog} />
            <Divider style={{ marginTop: "30px", marginBottom: "25px" }} light />
            <Typography
              style={{
                marginBottom: "30px",
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
              }}
              align="center"
              color="inherit"
              variant="h5"
            >
              YOU MIGHT ALSO LIKE
        </Typography>
            <Blog match={this.props.match} location={this.props.location} history={this.props.history} />
          </div>
        ) : (
            <center className="m-t-10">
              <CircularProgress size={50} />
            </center>
          )
        }
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    blogs: state.blogState.blogs,
    fetchingBlogDetails: state.blogState.fetchingBlogDetails
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getBlogDetails: id => {
      return dispatch(getBlogDetails(id));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(BlogDetailView));
