// Login
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const LOGOUT = "LOGOUT";
export const AUTHORIZATION_FAIL = "AUTHORIZATION_FAIL";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const SIGNUP_FAIL = "SIGNUP_FAIL";
export const SET_USER = "SET_USER";
export const EXISTING_USER = "EXISTING_USER";
export const SET_NEW_USERS = "SET_NEW_USERS";
export const SET_TOAST = "SET_TOAST";

// Project
export const SET_NEW_PROJECTS = "SET_NEW_PROJECTS";
export const PROJECT_ADDED = "PROJECT_ADDED";
export const PROJECT_FAIL = "PROJECT_FAIL";
export const PROJECT_EDITED = "PROJECT_EDITED";
export const PROJECT_DELETED = "PROJECT_DELETED";
export const SET_PROJECT_DATA = "SET_PROJECT_DATA";
export const SET_PROJECT_HAS_MORE = "SET_PROJECT_HAS_MORE";
export const PROJECT_COMMENT_ADDED = "PROJECT_COMMENT_ADDED";
export const PROJECT_COMMENT_EDITED = "PROJECT_COMMENT_EDITED";
export const PROJECT_COMMENT_DELETED = "PROJECT_COMMENT_DELETED";
export const PROJECT_COMMENT_FAIL = "PROJECT_COMMENT_FAIL";
export const PROJECT_LIKE_ADDED = "PROJECT_LIKE_ADDED";
export const PROJECT_LIKE_FAIL = "PROJECT_LIKE_FAIL";
export const PROJECT_LIKE_REMOVED = "PROJECT_LIKE_REMOVED";
export const SET_FETCHING_PROJECT_DETAILS = "SET_FETCHING_PROJECT_DETAILS";
export const SET_EVENT_DATA = "SET_EVENT_DATA";
export const SET_PROJECT_DETAILS = "SET_PROJECT_DETAILS";

// Internship
export const SET_NEW_INTERNSHIPS = "SET_NEW_INTERNSHIPS";
export const SET_INTERNSHIP_DATA = "SET_INTERNSHIP_DATA";
export const SET_INTERNSHIP_HAS_MORE = "SET_INTERNSHIP_HAS_MORE";
export const SET_FETCHING_INTERNSHIP_DETAILS = "SET_FETCHING_INTERNSHIP_DETAILS";
export const SET_INTERNSHIP_DETAILS = "SET_INTERNSHIP_DETAILS";

// Youtube
export const SET_YOUTUBE_DATA = "SET_YOUTUBE_DATA";
export const SET_YOUTUBE_HAS_MORE = "SET_YOUTUBE_HAS_MORE";
export const YOUTUBE_ADDED = "YOUTUBE_ADDED";
export const YOUTUBE_FAIL = "YOUTUBE_FAIL";
export const YOUTUBE_EDITED = "YOUTUBE_EDITED";
export const YOUTUBE_DELETED = "YOUTUBE_DELETED";
export const YOUTUBE_COMMENT_ADDED = "YOUTUBE_COMMENT_ADDED";
export const YOUTUBE_COMMENT_EDITED = "YOUTUBE_COMMENT_EDITED";
export const YOUTUBE_COMMENT_DELETED = "YOUTUBE_COMMENT_DELETED";
export const YOUTUBE_COMMENT_FAIL = "YOUTUBE_COMMENT_FAIL";
export const YOUTUBE_LIKE_ADDED = "YOUTUBE_LIKE_ADDED";
export const YOUTUBE_LIKE_FAIL = "YOUTUBE_LIKE_FAIL";
export const YOUTUBE_LIKE_REMOVED = "YOUTUBE_LIKE_REMOVED";
export const SET_YOUTUBE_DETAILS = "SET_YOUTUBE_DETAILS";
export const SET_FETCHING_YOUTUBE_DETAILS = "SET_FETCHING_YOUTUBE_DETAILS";

// Affiliate / Buy
export const SET_AFFILIATE_DATA = "SET_AFFILIATE_DATA";
export const SET_AFFILIATE_HAS_MORE = "SET_AFFILIATE_HAS_MORE";
export const AFFILIATE_ADDED = "AFFILIATE_ADDED";
export const AFFILIATE_FAIL = "AFFILIATE_FAIL";
export const AFFILIATE_EDITED = "AFFILIATE_EDITED";
export const AFFILIATE_DELETED = "AFFILIATE_DELETED";
export const AFFILIATE_COMMENT_ADDED = "AFFILIATE_COMMENT_ADDED";
export const AFFILIATE_COMMENT_EDITED = "AFFILIATE_COMMENT_EDITED";
export const AFFILIATE_COMMENT_DELETED = "AFFILIATE_COMMENT_DELETED";
export const AFFILIATE_COMMENT_FAIL = "AFFILIATE_COMMENT_FAIL";
export const AFFILIATE_LIKE_ADDED = "AFFILIATE_LIKE_ADDED";
export const AFFILIATE_LIKE_FAIL = "AFFILIATE_LIKE_FAIL";
export const AFFILIATE_LIKE_REMOVED = "AFFILIATE_LIKE_REMOVED";
export const SET_AFFILIATE_DETAILS = "SET_AFFILIATE_DETAILS";
export const SET_FETCHING_AFFILIATE_DETAILS = "SET_FETCHING_AFFILIATE_DETAILS";

// Blog
export const SET_NEW_BLOGS = "SET_NEW_BLOGS";
export const BLOG_ADDED = "BLOG_ADDED";
export const BLOG_FAIL = "BLOG_FAIL";
export const BLOG_EDITED = "BLOG_EDITED";
export const BLOG_DELETED = "BLOG_DELETED";
export const SET_BLOG_DATA = "SET_BLOG_DATA";
export const SET_BLOG_HAS_MORE = "SET_BLOG_HAS_MORE";
export const BLOG_COMMENT_ADDED = "BLOG_COMMENT_ADDED";
export const BLOG_COMMENT_EDITED = "BLOG_COMMENT_EDITED";
export const BLOG_COMMENT_DELETED = "BLOG_COMMENT_DELETED";
export const BLOG_COMMENT_FAIL = "BLOG_COMMENT_FAIL";
export const BLOG_LIKE_ADDED = "BLOG_LIKE_ADDED";
export const BLOG_LIKE_FAIL = "BLOG_LIKE_FAIL";
export const BLOG_LIKE_REMOVED = "BLOG_LIKE_REMOVED";
export const SET_BLOG_DETAILS = "SET_BLOG_DETAILS";
export const SET_FETCHING_BLOG_DETAILS = "SET_FETCHING_BLOG_DETAILS";

// Ad
export const AD_ADDED = "AD_ADDED";
export const AD_FAIL = "AD_FAIL";
export const AD_EDITED = "AD_EDITED";
export const AD_DELETED = "AD_DELETED";
export const SET_AD_DATA = "SET_AD_DATA";
export const SET_AD_HAS_MORE = "SET_AD_HAS_MORE";
export const AD_COMMENT_ADDED = "AD_COMMENT_ADDED";
export const AD_COMMENT_EDITED = "AD_COMMENT_EDITED";
export const AD_COMMENT_DELETED = "AD_COMMENT_DELETED";
export const AD_COMMENT_FAIL = "AD_COMMENT_FAIL";
export const AD_LIKE_ADDED = "AD_LIKE_ADDED";
export const AD_LIKE_FAIL = "AD_LIKE_FAIL";
export const AD_LIKE_REMOVED = "AD_LIKE_REMOVED";
export const SET_AD_DETAILS = "SET_AD_DETAILS";
export const SET_FETCHING_AD_DETAILS = "SET_FETCHING_AD_DETAILS";
